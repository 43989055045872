<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">{{ popTitle }}</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>
        <div class="tab-group ty01">
          <v-tabs
            v-model="tab"
          >
            <v-tab value="tabCont1">라이브러리(1)</v-tab>
            <v-tab value="tabCont2" >모니터링(2)</v-tab>
          </v-tabs>
        </div>
        <div class="content-box bg-none">
          <v-tabs-window v-model="tab">
            <v-tabs-window-item value="tabCont1">
              <c-Input :placeholder="'행동 혹은 태그를 입력하세요.'"/>
              <div class="dropdown-group ty01">
                <v-list v-model:opened="open">
                  <!--depth1 -->
                  <v-list-group v-for="(itemD1, seqD1) in listAll"
                                :key="itemD1.id"
                                :title="itemD1.title"
                                :value="itemD1.title"
                  >
                    <template v-slot:activator="{ props }">
                      <v-list-item
                        v-bind="props"
                        :title="itemD1.title+itemD1.selected"
                        :class="itemD1.selected?'on':''"
                      >
                        <div class="check-group">
                          <v-checkbox
                            :model-value="false"
                            @click.stop="itemD1.selected = !itemD1.selected"
                          ></v-checkbox>
                        </div>
                      </v-list-item>
                    </template>
                    <!--depth2 -->
                    <tamplate v-if="itemD1.itemsD2" v-for="(itemD2, seqD2) in itemD1.itemsD2">
                      <v-list-group :value="itemD2.title">
                        <template v-slot:activator="{ props }">
                          <v-list-item
                            v-bind="props"
                            :title="itemD2.title"
                            :class="itemD2.selected?'on':''"
                          >
                            <div class="check-group">
                              <v-checkbox
                                :model-value="false"
                                @click.stop="itemD2.selected = !itemD2.selected"
                              ></v-checkbox>
                            </div>
                          </v-list-item>
                        </template>
                        <!--depth3 -->
                        <tamplate v-if="itemD2.itemsD3" v-for="(itemD3, seqD3) in itemD2.itemsD3">
                          <v-list-group :value="itemD3.title">
                            <template v-slot:activator="{ props }">
                              <v-list-item
                                v-bind="props"
                                :title="itemD3.title"
                                :class="itemD3.selected?'on':''"
                              >
                                <div class="check-group">
                                  <v-checkbox
                                    :model-value="false"
                                    @click.stop="itemD3.selected = !itemD3.selected"
                                  ></v-checkbox>
                                </div>
                              </v-list-item>
                            </template>
                            <!--depth4 필요하면 추가 -->
                            <!--depth4 -->
                          </v-list-group>
                        </tamplate>
                        <!--depth3 -->
                      </v-list-group>
                    </tamplate>
                    <!--depth2 -->
                  </v-list-group>
                  <!--depth1 -->
                </v-list>
              </div>
            </v-tabs-window-item>
            <v-tabs-window-item value="tabCont2">
              <c-Input :placeholder="'행동 혹은 태그를 입력하세요.'"/>
              <div class="dropdown-group ty01">
                <v-list v-model:opened="open">
                  <!--depth1 -->
                  <v-list-group v-for="(itemD1, seqD1) in listAll2"
                                :key="itemD1.id"
                                :title="itemD1.title"
                                :value="itemD1.title"
                  >
                    <template v-slot:activator="{ props }">
                      <v-list-item
                        v-bind="props"
                        :title="itemD1.title+itemD1.selected"
                        :class="itemD1.selected?'on':''"
                      >
                        <div class="check-group">
                          <v-checkbox
                            :model-value="false"
                            @click.stop="itemD1.selected = !itemD1.selected"
                          ></v-checkbox>
                        </div>
                      </v-list-item>
                    </template>
                    <!--depth2 -->
                    <tamplate v-if="itemD1.itemsD2" v-for="(itemD2, seqD2) in itemD1.itemsD2">
                      <v-list-group :value="itemD2.title">
                        <template v-slot:activator="{ props }">
                          <v-list-item
                            v-bind="props"
                            :title="itemD2.title"
                            :class="itemD2.selected?'on':''"
                          >
                            <div class="check-group">
                              <v-checkbox
                                :model-value="false"
                                @click.stop="itemD2.selected = !itemD2.selected"
                              ></v-checkbox>
                            </div>
                          </v-list-item>
                        </template>
                        <!--depth3 -->
                        <tamplate v-if="itemD2.itemsD3" v-for="(itemD3, seqD3) in itemD2.itemsD3">
                          <v-list-group :value="itemD3.title">
                            <template v-slot:activator="{ props }">
                              <v-list-item
                                v-bind="props"
                                :title="itemD3.title"
                                :class="itemD3.selected?'on':''"
                              >
                                <div class="check-group">
                                  <v-checkbox
                                    :model-value="false"
                                    @click.stop="itemD3.selected = !itemD3.selected"
                                  ></v-checkbox>
                                </div>
                              </v-list-item>
                            </template>
                            <!--depth4 필요하면 추가 -->
                            <!--depth4 -->
                          </v-list-group>
                        </tamplate>
                        <!--depth3 -->
                      </v-list-group>
                    </tamplate>
                    <!--depth2 -->
                  </v-list-group>
                  <!--depth1 -->
                </v-list>
              </div>
            </v-tabs-window-item>
          </v-tabs-window>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end pop-bottom-bg"><!--pop-bottom-bg-->
        <c-btn01 v-bind="btnSave"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
const tab = ref(null)
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value', 'popTitle'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
const btnSave = {
  btnText: '저장',
}
const open = ref(['Users'])
const listAll = ref()
listAll.value = [{
  id: 'item'+1,
  title: '행동',
  level : 1,
  selected : false,
  itemsD2 : [{
    id: 'item'+2,
    title: '관찰',
    level : 2,
    selected : false,
    itemsD3 : [{
      id: 'item'+3,
      title: 'A-1-1정보화전략1팀',
      level : 3,
      selected : false,
      itemsD4 : []
    }]
  }]
}]
const listAll2 = ref()
listAll2.value = [{
  id: 'item'+1,
  title: '모니터링',
  level : 1,
  selected : false,
  itemsD2 : [{
    id: 'item'+2,
    title: '행동A',
    level : 2,
    selected : false,
    itemsD3 : [{
      id: 'item'+3,
      title: '행동',
      level : 3,
      selected : false,
      itemsD4 : []
    }]
  }]
}]
</script>


<style lang="scss">

</style>
