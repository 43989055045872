<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="60rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">상세보기</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>
        <!--content-->
        <v-card-text class="ty01">
          <div class="tab-group ty01 ty01-01">
            <v-tabs
              v-model="tab"
            >
              <v-tab value="tabCont1">모니터링</v-tab>
              <v-tab value="tabCont2" >안전대화</v-tab>
            </v-tabs>
          </div>
          <div class="content-box bg-none">
            <div class="content-inner-box">
            <v-tabs-window v-model="tab">
              <v-tabs-window-item value="tabCont1">
                <div class="list-group2 ty01 ty01-01">
                  <!--list-->
                  <div class="list">
                    <div class="row1">
                      <div class="num">1.</div>
                      <div class="txt">동행 점검 시 동일한 포인트를 확인하여 위험예지 시각을 통일한다.</div>
                      <div class="right-area">
                        <div class="check-group ty01">
                          <v-checkbox label="해당없음"></v-checkbox>
                        </div>
                        <c-btnText01 :btnText="'안전대화로 이동'" class="ty03"/>
                      </div>
                    </div>
                    <div class="row2">
                      <div class="radio-group2">
                        <v-radio-group v-model="radios">
                          <v-radio label="안전" value="안전" ></v-radio>
                          <v-radio label="불안전" value="불안전"></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </div>
                  <!--list-->
                  <div class="list">
                    <div class="row1">
                      <div class="num">2.</div>
                      <div class="txt"> 일일미팅(모닝미팅/TBM)을 통해 특별히 안전에 신경써야 할 사항을 공유하고 주시시킨다. 동행 점검 시 동일한 포인트를 확인하여 위험예지 시각을 통일한다.</div>
                      <div class="right-area">
                        <div class="check-group ty01">
                          <v-checkbox label="해당없음"></v-checkbox>
                        </div>
                        <c-btnText01 :btnText="'안전대화로 이동'" class="ty03"/>
                      </div>
                    </div>
                    <div class="row2">
                      <div class="radio-group2">
                        <v-radio-group v-model="radios2">
                          <v-radio label="안전" value="안전" ></v-radio>
                          <v-radio label="불안전" value="불안전"></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </div>
                  <!--list-->
                  <div class="list">
                    <div class="row1">
                      <div class="num">3.</div>
                      <div class="txt">동행 점검 시 동일한 포인트를 확인하여 위험예지 시각을 통일한다.</div>
                      <div class="right-area">
                        <div class="check-group ty01">
                          <v-checkbox label="해당없음"></v-checkbox>
                        </div>
                        <c-btnText01 :btnText="'안전대화로 이동'" class="ty03"/>
                      </div>
                    </div>
                    <div class="row2">
                      <div class="radio-group2">
                        <v-radio-group v-model="radios3">
                          <v-radio label="안전" value="안전" ></v-radio>
                          <v-radio label="불안전" value="불안전"></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="inner">
                        <c-input :placeholder="'set-up'"></c-input>
                        <c-input :placeholder="'chiler 점검'" disabled="disabled"></c-input>
                        <c-input :placeholder="'chiler 교체'"></c-input>
                      </div>
                    </div>
                  </div>
                </div>
              </v-tabs-window-item>
              <v-tabs-window-item value="tabCont2">
                <div class="dropdown-group2 ty01 mo-ty01">
                  <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                      <v-expansion-panel-title>안전대화1</v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <div class="form-group col-ty">
                          <div class="tit-group">
                            <div class="tit02">기본정보</div>
                          </div>
                          <!--form-row-->
                          <div class="form-row">
                            <div class="txt-tit">타입</div>
                            <div class="row">
                              <c-Input v-model="typeVal" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row">
                            <div class="txt-tit">시정조치여부</div>
                            <div class="row">
                              <c-Input v-model="correctiveActionVal" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row">
                            <div class="txt-tit">대상자</div>
                            <div class="row">
                              <c-Input v-model="subjectVal" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row">
                            <div class="txt-tit">조치기한</div>
                            <div class="row">
                              <c-Input v-model="ActionDeadlineVal" :readonly="readonly"/>
                            </div>
                          </div>

                        </div>
                        <div class="form-group col-ty line-ty">
                          <div class="tit-group">
                            <div class="tit02">상세정보</div>
                          </div>
                          <!--form-row-->
                          <div class="form-row">
                            <div class="txt-tit">모니터링행동</div>
                            <div class="row">
                              <c-Input v-model="monitoringBehaviorVal" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row">
                            <div class="txt-tit">장소</div>
                            <div class="row">
                              <c-Input v-model="placeVal" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row ty07">
                            <div class="txt-tit">구체적상황</div>
                            <div class="row">
                              <Textarea v-model="detailedSituationVal" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row ty07">
                            <div class="txt-tit">원인</div>
                            <div class="row">
                              <Textarea v-model="causeVal" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row ty07">
                            <div class="txt-tit">시정조치내용</div>
                            <div class="row">
                              <Textarea v-model="correctiveActionContVal" :readonly="readonly"/>
                            </div>
                          </div>

                          <div class="img-slider02">
                            <v-carousel
                            >
                              <div class="img-wrap">
                              <v-carousel-item
                                v-for="(item,i) in items"
                                :key="i"
                                :src="item.src"
                                cover
                                @click="isShowDialog2=true"
                              ></v-carousel-item>
                              </div>
                            </v-carousel>
                          </div>
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-title>안전대화_칭찬타입</v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <div class="form-group col-ty">
                          <div class="tit-group">
                            <div class="tit02">기본정보</div>
                          </div>
                          <!--form-row-->
                          <div class="form-row">
                            <div class="txt-tit">타입</div>
                            <div class="row">
                              <c-Input v-model="typeVal" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row">
                            <div class="txt-tit">대상자</div>
                            <div class="row">
                              <c-Input v-model="subjectVal" :readonly="readonly"/>
                            </div>
                          </div>
                          <!--form-row-->
                          <div class="form-row">
                            <div class="txt-tit">조치기한</div>
                            <div class="row">
                              <c-Input v-model="ActionDeadlineVal" :readonly="readonly"/>
                            </div>
                          </div>

                        </div>
                        <div class="form-group col-ty line-ty">
                          <div class="tit-group">
                            <div class="tit02">상세정보</div>
                          </div>
                          <!--form-row-->
                          <div class="form-row ty07">
                            <div class="txt-tit">시정조치내용</div>
                            <div class="row">
                              <Textarea v-model="correctiveActionContVal" :readonly="readonly"/>
                            </div>
                          </div>
                          <div class="img-slider02">
                            <v-carousel
                            >
                              <div class="img-wrap">
                                <v-carousel-item
                                  v-for="(item,i) in items"
                                  :key="i"
                                  :src="item.src"
                                  cover
                                  @click="isShowDialog2=true"
                                ></v-carousel-item>
                              </div>
                            </v-carousel>
                          </div>
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-tabs-window-item>
            </v-tabs-window>
            </div>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn01 v-bind="btnConfirm" />
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
const tab = ref(null)
const select01 = {
  select:  '공장',
  items: ['공장', '미선택']
}
const select02 = {
  select:  '동행 점검 시 동일한 포인트를 확인하여 위험예지',
  items: ['동행 점검 시 동일한 포인트를 확인하여 위험예지', '일일미팅(모닝미팅/TBM)을 통해 특별히 안전에 신경써야 할 사항을 공유하고 주시시킨다',]
}
const select03 = {
  select:  '공장',
  items: ['공장', '미선택']
}
const radios = ref('안전')
const radios2 = ref('안전')
const radios3 = ref('불안전')

const typeVal = '안전대화'
const correctiveActionVal = '필요'
const subjectVal = '염소정제팀'
const ActionDeadlineVal = '2024.05.03'
const monitoringBehaviorVal = '동행 점검 시 동일한 포인트를 확인하여 위험예지'
const placeVal = '공장'
const detailedSituationVal = '구체적상황'
const causeVal = '원인'
const correctiveActionContVal = '시정조치내용'


const isShowDialog2 = ref(false)

const items = [
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/planet-.jpg',
  },
]

const panel = ref([0])

const btnConfirm = {
  btnText: '확인',
}

</script>


<style lang="scss">
</style>
