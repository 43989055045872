<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="tit-group ty01">
        <h4 class="tit">리더 _ 안전리더십 A</h4>
        <div class="btn-group">
          <c-btn02 :btnText="'다운로드'" class="sm"/>
        </div>
      </div>
      <div class="form-group row-ty02">
        <!--form-row-->
        <div class="form-row">
          <div class="row">
            <InputSearch03 :placeholder="'열람 권한이 있는 자에 한하여 검색됩니다.'"></InputSearch03>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">기본정보</div>
          <div class="row">
            <div class="txt">김갑두 / 프로 / 생산1팀 </div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">간단리포트</div>
          <div class="row">
            <div class="card-group02 ty01">
              <div class="card-col ty02">
                <div class="icon ico8" aria-hidden="true"></div>
                <div class="txt-group">
                  <div class="txt1">90<span>점</span></div>
                  <div class="txt2">안전리더십 점수</div>
                </div>
              </div>
              <div class="card-col ty04">
                <div class="icon ico9" aria-hidden="true"></div>
                <div class="txt-group">
                  <div class="inner">
                    <div class="txt1">
                      <span>전체</span>
                      <div>20<span>개</span></div>
                    </div>
                    <div class="inner-line"></div>
                    <div class="txt1">
                      <span>완료</span>
                      <div>13<span>개</span></div>
                    </div>
                    <div class="inner-line"></div>
                    <div class="txt1">
                      <span>완료율</span>
                      <div>50<span>%</span></div>
                    </div>
                  </div>
                  <div class="txt2">안전 리더십 과제현황</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row page2-inner1">
          <div class="txt-tit sb">
            과제별 세부 진행현황
            <div class="txt-info02">
              <i class="ico-point"></i>
              성과반영항목
            </div>
          </div>
          <div class="mo-ty">
            <c-btn01 :btnText="'세부 진행현황 다운로드'" @click="DialogSLRResultModel=true"/>
          </div>
          <div class="row">
            <div class="tbl-group line-ty size-custom11">
              <v-data-table
                :headers="headers"
                :items="items"
                hide-default-footer
                return-object
              >
                <template v-slot:item="{ item }" >
                  <tr class="v-data-table__tr">
                    <td :rowspan="item.rowspan" v-if="item.rowspan>0">
                      <div class="txt-group" v-html="item.title"></div>
                    </td>
                    <td>
                      <div class="txt-group" v-html="item.titleDetail"></div>
                    </td>
                    <td>
                      <div class="txt-group flex-c">{{item.period}}</div>
                    </td>
                    <td>
                      <div class="txt-group flex-c">{{item.importance}}</div>
                    </td>
                    <td>
                      <div class="txt-group flex-c">
                        <span v-if="isNaN(Number(item.result))">{{item.result}}</span>
                        <div v-if="!isNaN(Number(item.result))">
                          <c-tooltip02 :btnText="item.result"  :TooltipText="item.resultTooltip"/>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="txt-group flex-c">
                        <BtnIcon class="btn-ico-result" @click="DialogSLRResultModel=true"/>
                      </div>
                    </td>
                  </tr>
                </template>
                <template
                  v-slot:tfoot
                >
                  <tfoot class="ty01">
                    <tr>
                      <td>총점</td>
                      <td colspan="3"></td>
                      <td>총 <span>28</span></td>
                      <td></td>
                    </tr>
                  </tfoot>

                </template>
              </v-data-table>
            </div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">
            면담내용
            <span class="txt-info">
              * 면담 작성 권한이 있는 자에 한하여 노출됩니다. 내용 등록 후에는 대상자에게 바로 노출됩니다.
            </span>
          </div>
          <div class="row">
            <Textarea></Textarea>
          </div>
        </div>
      </div>
      <div class="bottom-btn-group ty01 page2-inner2">
        <div class="wl"></div>
        <div class="wr">
          <c-btn01 :btnText="'면담내용 등록'"/>
        </div>
      </div>
    </div>
  </div>
  <DialogSLRResult v-model="DialogSLRResultModel"
            @close="DialogSLRResultModel=false"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
const DialogSLRResultModel = ref(false)
const headers = ref([
  { title: '과제명', key: 'title' },
  { title: '세부 과제명', key: 'titleDetail' },
  { title: '주기', key: 'period'},
  { title: '가중치 ', key: 'importance' },
  { title: '결과 ', key: 'result' },
  { title: '결과물보기 ', key: 'resultView' },
])
const items = ref([
  {
    rowspan: 1,
    title: '안전보건관리계획 수립',
    titleDetail: '계획 수립 파일 제출',
    period: '연1회',
    importance: '-',
    result: '완료',
  },
  {
    rowspan: 2,
    title: '현장점검<i class="ico-point"></i>',
    titleDetail: '운전 및 작업 절차 준수 강화 활동',
    period: '월1회',
    importance: '-',
    result: '선정 대상 아님',
  },
  {
    rowspan:0,
    title: '',
    titleDetail: '모니터링<i class="ico-point"></i>',
    period: '주1회',
    importance: '30%',
    result: 28,
    resultTooltip:'self모니터링:14 <br/> 타인모니터링:14'
  },
  {
    rowspan:1,
    title: '현장점검2',
    titleDetail: '모니터링',
    period: '주1회',
    importance: '30%',
    result: 10,
    resultTooltip:'self모니터링:4 <br/> 타인모니터링:6'
  },
])
</script>


<style lang="scss" >
</style>
