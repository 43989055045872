<template>
  <div class="input-txt-group">
    <v-text-field
      clearable
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :name="name"
      v-model="model"
    >
    </v-text-field>
    <ErrorMessage :name="name" class="error-feedback" />
  </div>
</template>
<style lang="scss">
.error-feedback{
  color: var(--txt-red);
  font-size:1.4rem;
}
</style>
<script lang="ts" setup>
import { toRef } from "vue";
import { useField, Field, ErrorMessage } from "vee-validate";

const props = defineProps(['placeholder', 'type' ,'readonly', 'disabled', 'modelValue', 'name' ]);
const model = defineModel()
const { value, handleBlur, errors } = useField(toRef(props, "name"), undefined,{ syncVModel: true });
//const { value, errorMessage } = useField('name', inputValue => !!inputValue);

</script>
