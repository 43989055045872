<template>
  <div class="btn-ico">
    <!--사이즈 클래스 투가 ss sm sl-->
    <v-btn>
    </v-btn>
  </div>
</template>

<script setup lang="ts">
</script>


<style lang="scss">
.btn-ico {
  .v-btn{
    padding:0;
    width:2.4rem;
    height:2.4rem;
    line-height:2.4rem;
    text-align:center;
    border-radius:.5rem;
    font-size:1.6rem;
    font-weight:500;
    box-shadow:none;
    letter-spacing:0;
    min-width:auto;
  }
  &.btn-ico-search .v-btn{
    background:url("/src/assets/img/ico-search-md.svg") center no-repeat;
  }
  &.btn-ico-delete .v-btn{
    background:url("/src/assets/img/ico-delete.svg") center no-repeat;
  }
  &.btn-ico-delete02 .v-btn{
    background:url("/src/assets/img/ico-close-circle.svg") center no-repeat;
  }
  &.btn-ico-set .v-btn{
    background:url("/src/assets/img/ico-set02.svg") center no-repeat;
  }
  &.btn-ico-result .v-btn{
    background:url("/src/assets/img/ico-file-document.svg") center no-repeat;
  }
}

</style>
