<template>
  <div class="sort-group ty01">
    <div class="sort-row">
      <div class="wl">
        <div class="date-sort">
          <c-InputSearch :placeholder="'검색어를 입력하세요.'"/>
          <c-InputDate :enableTimePicker="false" readonly="" />
          <div class="txt02">~</div>
          <c-InputDate :enableTimePicker="false" readonly=""/>
        </div>
        <div class="date-sort">
          <c-btn02 :btnText="'일주'" class="ty01"/>
          <c-btn02 :btnText="'한달'" class="ty01"/>
        </div>
      </div>
    </div>
    <div class="sort-row">
      <div class="wl">
        <Select v-bind="select01"/>
        <Select v-bind="select02"/>
        <Select v-bind="select03"/>
        <Select v-bind="select04"/>
        <Select v-bind="select05"/>
      </div>
    </div>
  </div>
  <div class="scrollbar">
    <div class="tbl-group size-custom18">
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        return-object
      >
        <template v-slot:item.exclusionStatistics="{ item }" >
          <div class="check-group ty03">
            <v-checkbox label=""></v-checkbox>
          </div>
        </template>
        <template v-slot:item.safetyTalk="{ item }" >
          <div class="tal">
            {{item.safetyTalk}}
          </div>
        </template>
        <template v-slot:item.author="{ item }" >
          {{item.author}}
          <div class="txt-tag">
            생산1팀/과장
          </div>
        </template>
        <template v-slot:item.correctiveAction="{ item }" >
          {{item.correctiveAction}}
          <div class="txt-tag">
            생산1팀/과장
          </div>
        </template>
        <template v-slot:item.detailView="{ item }">
          <div class="flex-c">
            <BtnIcon class="btn-ico-search" @click="isShowDialog=true"/>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-divider class="mb-4"></v-divider>
  </div>
  <DialogMonitoringDetail
    v-model="isShowDialog"
    @close="isShowDialog=false"/>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
const isShowDialog = ref(false)
const select01 = {
  select:  '부서',
  items: ['부서', '부서1']
}
const select02 = {
  select:  '직책',
  items: ['직책', '직책1']
}
const select03 = {
  select:  '교대조',
  items: ['교대조', '교대조1']
}
const select04 = {
  select:  '모니터링(전체)',
  items: ['모니터링(전체)', '모니터링1']
}
const select05 = {
  select:  '통계제외',
  items: ['통계제외', '통계']
}

const headers = ref([
  { title: '통계제외', key: 'exclusionStatistics' },
  { title: '안전대화/칭찬', key: 'safetyTalk' },
  { title: '작성자', key: 'author'},
  { title: '시정조치 대상', key: 'correctiveAction' },
  { title: '작성일시', key: 'date' },
  { title: '상세보기', key: 'detailView' },
])
const items = ref([
  {
    safetyTalk:'안전대화 제목이 들어갑니다1 안전대화 제목이 들어갑니다1 안전대화 제목이 들어갑니다1',
    author: '김동임',
    correctiveAction: '김동임',
    score: '23',
    date: '2024.03.01 06:32:33',
  },
  {
    safetyTalk:'안전대화 제목이 들어갑니다1',
    author: '김동임',
    correctiveAction: '김동임',
    score: '23',
    date: '2024.03.01 06:32:33',
  },
  {
    safetyTalk:'안전대화 제목이 들어갑니다1',
    author: '김동임',
    correctiveAction: '김동임',
    score: '23',
    date: '2024.03.01 06:32:33',
  },
  {
    safetyTalk:'안전대화 제목이 들어갑니다1',
    author: '김동임',
    correctiveAction: '김동임',
    score: '23',
    date: '2024.03.01 06:32:33',
  },
])
</script>


<style lang="scss">
</style>
