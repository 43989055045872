<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="55rem"
      class="layer-popup"
      scrollable
    >
      <Form @submit="handleForm" :validation-schema="schema">
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">고객사정보</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <v-divider class="mb-4"></v-divider>
        <!--content-->
        <v-card-text>
          <div class="form-group row-ty">
            <div class="form-row">
              <div class="txt-tit">
                고객사명<span class="imp">*</span>
              </div>
              <div class="row">
                <c-Input v-vind="client" v-model="clientName" :name="'clientName'"/>
              </div>
            </div>
            <div class="form-row">
              <div class="txt-tit">
                최대 그룹 수
              </div>
              <div class="row">
                <c-Input v-bind="groupNum" v-model="maxGroup" :name="'maxGroup'"/>
              </div>
            </div>
            <div class="form-row">
              <div class="txt-tit">
                최대 사용자 수
                <c-tooltip v-bind="groupNumTooltip"/>
              </div>
              <div class="row">
                <c-Input v-bind="userNum" v-model="maxUser" :name="'maxUser'"/>
              </div>
            </div>
            <div class="form-row">
              <div class="txt-tit">
                그룹 관리자ID<span class="imp">*</span>
                <c-tooltip v-bind="managerTooltip"/>
              </div>
              <div class="row">
                <c-InputIdAdd/>
              </div>

            </div>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn02 v-bind="btnDel" />
          <c-btn01 v-bind="btnSave" />
        </v-card-actions>
      </v-card>
      </Form>
    </v-dialog>

</template>

<script setup lang="ts">
import {ref, computed} from 'vue'
import OrganizationService from "@/services/organization.service";
import utils from "@/common/Utils";
import { useOrganizationalManagementStore } from "@/store/organizationalManagement";
import {storeToRefs} from "pinia";
import {Form} from "vee-validate";
import * as yup from "yup";

//popup close logic
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    storeRef.popupIdx.value = null
    emit('close', value)
  }
})

//store
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)

//validation schema
const schema = yup.object().shape({
  clientName: yup.string().required("필수 항목입니다."),
  maxGroup: yup.number().required("필수 항목입니다."),
  maxUser: yup.number().required("필수 항목입니다."),
});

//bind
const form = ref(null)
const clientName = ref('')
const client = {
  type: 'text',
  placeholder: '고객사명을 입력하세요',
}
let maxGroup = ref('')
const groupNum = {
  type: 'text',
  placeholder: '최대 그룹 수를 입력하세요',
}
let maxUser = ref('')
const userNum = {
  type: 'text',
  placeholder: '최대 사용자 수를 입력하세요',
}
const groupNumTooltip = {
  TooltipText: '최대 사용자수는 당사 라이선스 규정에 의거하여 설정해야 합니다.'
}
const managerTooltip = {
  TooltipText: '그룹 관리자는 고객사 내 생성된 그룹 전체를 관리하는 권한을 가집니다.'
}
const btnDel = {
  btnText: '삭제',
  btnType: 'reset'
}
const btnSave = {
  btnText: '저장',
  btnType: 'submit'
}
/*
const props = defineProps(['isShowDialog'])
console.log(props.isShowDialog)
const isShow = ref(props.isShowDialog)
 */

//hook
onBeforeUpdate (()=>{
  clientName.value = ''
  maxGroup.value = ''
  maxUser.value = ''
  storeRef.adminList.value = new Set([])
})

onUpdated (() => {
  if(!storeRef.popupIdx.value)
    return;

  let params = {
    idx: storeRef.popupIdx.value,
  }

  if(params.idx){
    OrganizationService.getClient(params).then(
      (response) => {
        clientName.value = response.result.clientName
        maxGroup.value = response.result.maxGroup
        maxUser.value = response.result.maxUser
        storeRef.adminList.value = new Set(response.result.adminList)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if(error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
})

//function
function handleForm(){
  if(import.meta.env.MODE == 'development') {
    emit('close', '')
  }
  else{
    let params = {
      idx: storeRef.popupIdx.value,
      clientName: clientName.value,
      maxGroup: maxGroup.value,
      maxUser: maxUser.value,
      adminList: Array.from(storeRef.adminList.value)
    }

    OrganizationService.insertUpdateClient(params).then(
      (response) => {
        storeRef.popupIdx.value = null
        emit('close', 'refresh')
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}
</script>


<style lang="scss">
</style>
