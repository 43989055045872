<template>
  <div class="content-wrap">
    <NaviBox02/>
    <div class="tit-group02">
      <h2 class="tit">안전리더십 행동관리 상세</h2>
    </div>
    <div class="content-box">
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">행동명<span class="imp">*</span></div>
          <div class="row">
            <c-Input :placeholder="'행동명을 입력하세요'" class="size04"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">구분<span class="imp">*</span></div>
          <div class="row">
            <Select v-bind="select01"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">카테고리<span class="imp">*</span></div>
          <div class="row">
            <Select v-bind="select02"/>
            <div class="col-dv">
              <c-btn01 :btnText="'추가/변경'" @click="DialogCategoryStatus=true"/>
            </div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">관찰항목<span class="imp">*</span></div>
          <div class="row">
            <c-Input :placeholder="'관찰항목을 입력하세요'" class="size04"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">태그</div>
          <div class="row">
            <c-Input :placeholder="'#태그1, #태그2'" class="size04"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">
            필수여부
          </div>
          <div class="row">
            <v-switch :model-value="true" inset hide-details/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">
            타입
          </div>
          <div class="row">
            <div class="radio-group">
              <v-radio-group v-model="radios">
                <v-radio color="indigo" label="카운트" value="카운트"></v-radio>
                <v-radio color="indigo" label="선택형" value="선택형"></v-radio>
                <v-radio color="indigo" label="리커트" value="리커트"></v-radio>
                <v-radio color="indigo" label="BARS" value="BARS"></v-radio>
                <v-radio color="indigo" label="다중선택" value="다중선택"></v-radio>
                <v-radio color="indigo" label="기타" value="기타"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit ty01">
            보기갯수
          </div>
          <div class="row">
            <Select v-bind="select03"/>
            <div class="col-dv">
              <div class="check-group">
                <v-checkbox v-model="checkbox1" label="다중 선택 가능" ></v-checkbox>
              </div>
            </div>
            <div class="row-dv">
              <c-Input :placeholder="'제목을 입력하세요'" class="size05"/>
              <c-Input :placeholder="'내용을 입력하세요'" class="size01"/>
            </div>
            <div class="row-dv">
              <c-Input :placeholder="'제목을 입력하세요'" class="size05"/>
              <c-Input :placeholder="'내용을 입력하세요'" class="size01"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-group">
      <div class="wl">
        <c-btnLine01 :btnText="'목록'"/>
      </div>
      <div class="wr">
        <c-btn02 :btnText="'삭제'"/>
        <c-btn01 :btnText="'등록/수정'"/>
      </div>
    </div>
  </div>
  <DialogCategory
    v-model="DialogCategoryStatus"
    :popTitle="'카테고리'"
    @close="DialogCategoryStatus=false"/>
</template>

<script setup lang="ts">
import { ref } from 'vue'
const checkbox1 = ref(true)
const radios = ref('카운트')
const DialogCategoryStatus = ref(false)
const select01 = {
  select:  '행동',
  items: ['행동', 'text1', 'text2']
}
const select02 = {
  select:  '카테고리1',
  items: ['카테고리1', '카테고리2', '카테고리3']
}
const select03 = {
  select:  '2',
  items: ['2', '3', '4']
}
</script>


<style lang="scss">
</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
