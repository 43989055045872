import api from "./api";
import Utils from "@/common/Utils";

class CompanyService {
  getCompany(params) {
    return api
      .post("/company/getCompany", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getCompanyList(params) {
    return api
      .post("/company/getCompanyList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertCompany(params) {
    let apiUrl = '/company/insertCompany'
    if(params['idx'])
      apiUrl = '/company/updateCompany'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getCompanyShiftList(params) {
    return api
      .post("/company/getCompanyShiftList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertCompanyShift(params) {
    return api
      .post("/company/insertCompanyShift", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getCompanyTree(params) {
    let apiUrl = '/company/getCompanyTreeList'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertUpdateCompanyTree(params) {
    let apiUrl = '/company/insertUpdateCompanyTree'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertCompanyTree(params) {
    let apiUrl = '/company/insertCompanyTree'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  deleteCompanyTree(params) {
    let apiUrl = '/company/deleteCompanyTree'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new CompanyService();
