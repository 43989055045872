<template>
  <div class="content-wrap sl-page-wrap" :class="slPage">
    <div class="navi-area">
      <div class="m-navi-top">
        <c-btn01 :btnText="'안전리더십 대시보드'" @click="$router.push('/safetyLeadership')"/>
      </div>
      <NaviBox02/>
    </div>
    <div class="page2">
      <div class="tab-group">
        <v-tabs
          v-model="tab"
        >
          <v-tab value="tabCont1">안전리더십</v-tab>
          <v-tab value="tabCont2" >보고서</v-tab>
          <v-tab value="tabCont3">종합 점수</v-tab>
        </v-tabs>
      </div>
      <div class="content-box bg-none tab-cont-wrap">
        <div class="tit-group02 mo-ty01">
          <div class="navi-btn">
            <v-btn @click="slListOpen"/>
          </div>
        </div>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="tabCont1">
            <safetyLeadershipRunTabCont1/>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont2">
            <safetyLeadershipRunTabCont2/>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont3">
            <safetyLeadershipRunTabCont3/>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useOrganizationalManagementStore } from "@/store/organizationalManagement";
import { storeToRefs } from 'pinia'
const route = useRoute()
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)
store.moveTab('tabCont1')
const tab = storeRef.tabName
//모니터링 메뉴 열고 닫기
const slPage = ref('naviOff')
function slListOpen(){
  if(slPage.value == 'naviOff')
    slPage.value = 'naviOn'
  else
    slPage.value = 'naviOff'
}

</script>


<style lang="scss">
</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
