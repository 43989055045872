<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="60rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">
            상세보기
            <div class="sub-txt02">
              <div class="col">
                안전대화 <span>3</span>
              </div>
              <div class="line"></div>
              <div class="col">
                칭찬대화 <span>2</span>
              </div>
            </div>
          </div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <v-divider class="mb-4"></v-divider>
        <!--content-->
        <v-card-text>
          <div class="slider-group">
            <v-slide-group
              v-model="model"
              center-active
              show-arrows
              delimiters
            >
              <v-slide-group-item
                v-for="n in 4"
                :key="n"
                v-slot="{ isSelected, toggle }"
              >
                <v-card
                  @click="toggle"
                >
                  <div class="slider-item">
                    <div class="img-slider">
                      <v-carousel
                      >
                        <v-carousel-item
                          v-for="(item,i) in items"
                          :key="i"
                          :src="item.src"
                          cover
                          @click="isShowDialog2=true"
                        ></v-carousel-item>
                      </v-carousel>
                    </div>
                    <div @click="isShowDialog=true">
                      <div class="txt-group">
                        <div class="txt ell01">김상두 / 염소정제팀 / 관리감독자</div>
                        <div class="txt2">2024.02.12</div>
                      </div>
                      <ul class="txt-group2">
                        <li>
                          <div class="tit">행동</div>
                          <div class="txt ell01">동행 점검 시 동일한 포인트 확인</div>
                        </li>
                        <li>
                          <div class="tit">장소</div>
                          <div class="txt ell01">공장</div>
                        </li>
                        <li>
                          <div class="tit">구체적인상황</div>
                          <div class="txt ell01">구체적인 상황</div>
                        </li>
                        <li>
                          <div class="tit">원인</div>
                          <div class="txt ell01">원인</div>
                        </li>
                        <li>
                          <div class="tit">시정조치내용</div>
                          <div class="txt ell01">시멘트 안전지역 위치필요 시멘트 안전지역 위치필요</div>
                        </li>
                        <li>
                          <div class="tit">시정조치대상자</div>
                          <div class="txt ell01">홍길동</div>
                        </li>
                        <li>
                          <div class="tit">조치기한</div>
                          <div class="txt ell01">2024.05.01</div>
                        </li>
                        <li>
                          <div class="tit">진행상태</div>
                          <div class="txt2 c01">완료</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </v-card>
              </v-slide-group-item>
            </v-slide-group>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 :btnText="'확인'"/>
        </v-card-actions>
      </v-card>
      <AmDialog01 v-model="isShowDialog" @close="isShowDialog=false"/>
      <DialogImgView v-model="isShowDialog2" @close="isShowDialog2=false"/>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
const isShowDialog = ref(false)
const isShowDialog2 = ref(false)
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const model = ref(null)

const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
const items = [
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/planet-.jpg',
  },
]
</script>


<style lang="scss">
</style>
