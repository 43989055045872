<template>
  <!--
  <div class="button-ty01" v-if="btnShow">
    <button value="">
      {{ btnText }}
    </button>
  </div>
  -->
  <button :type="btn_type" class="btn1" v-if="btnShow">
    {{ btnText }}
  </button>
</template>

<script setup lang="ts">
import { ref } from 'vue'
const props = defineProps(['btnText', 'btnShow', 'btnType'])
const btn_type: Ref<"button" | "submit" | "reset" | undefined> = ref('button')
if(props.btnType)
  btn_type.value = props.btnType;
</script>


<style lang="scss">
.btn1{
  display:block;
  width:100%;
  height:4rem;
  line-height:4rem;
  text-align:center;
  color:var(--color-white);
  background: var(--color-black);
  border-radius:.6rem;
  font-size:1.3rem;
  font-weight:600;
}
</style>
