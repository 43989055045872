<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">
          {{ popTitle }}
          <span class="pop-title02">{{ popSubTitle }}</span>
        </div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>
        <div class="dropdown-group ty01">
          <div class="form-group col-ty">
            <div class="form-row02">
              <c-Input :placeholder="'장소입력'" class="size03"/>
              <c-btn02 :btnText="'삭제'" class=""/>
              <c-btnLine01 :btnText="'추가'" class=""/>
            </div>
            <div class="form-row02">
              <c-Input :placeholder="'장소입력'" class="size03"/>
              <c-btn02 :btnText="'삭제'" class=""/>
            </div>
            <div class="form-row02">
              <c-Input :placeholder="'장소입력'" class="size03"/>
              <c-btn02 :btnText="'삭제'" class=""/>
            </div>
          </div>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end pop-bottom-bg"><!--pop-bottom-bg-->
        <c-btn01 v-bind="btnSave"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value', 'popTitle', 'popSubTitle'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
const btnSave = {
  btnText: '확인',
}
</script>


<style lang="scss">

</style>
