<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="55rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">개인정보 변경</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <v-divider class="mb-4"></v-divider>
        <!--content-->
        <v-card-text>
          <div class="form-group row-ty">
            <div class="form-row">
              <div class="txt-tit">
                아이디 / 부서 / 직책
              </div>
              <div class="row">
                <c-Input v-bind="info" v-model="infoVal"  disabled=""/>
              </div>
            </div>
            <div class="form-row">
              <div class="txt-tit">
                기존 비밀번호
              </div>
              <div class="row">
                <c-Input v-bind="oldPw" />
              </div>
            </div>
            <div class="form-row">
              <div class="txt-tit">
                신규 비밀번호
              </div>
              <div class="row">
                <c-Input v-bind="newPw" />
              </div>
            </div>
            <div class="form-row">
              <div class="txt-tit">
                신규 비밀번호 재입력
              </div>
              <div class="row">
                <c-Input v-bind="newPwReenter"/>
              </div>
            </div>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn02 v-bind="btnDel"/>
          <c-btn01 v-bind="btnSave" />
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
const infoVal = 'fidelity / 경영지원부 / 과장'
const info = {
  type: 'text',
  value:'fidelity / 경영지원부 / 과장'
}
const oldPw = {
  type: 'password',
  placeholder: '기존 비밀번호를 입력하세요',
}
const newPw = {
  type: 'password',
  placeholder: '신규 비밀번호를 입력하세요',
}
const newPwReenter = {
  type: 'password',
  placeholder: '신규 비밀번호를 재입력하세요',
}
const btnDel = {
  btnText: '삭제',
}
const btnSave = {
  btnText: '저장',
}
</script>


<style lang="scss">
</style>
