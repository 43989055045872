<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="94rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">자유형식 설정</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>

      <!--content-->
      <v-card-text class="ty01">
        <div class="form-group col-ty">
          <!--form-row-->
          <div class="form-row ty05">
            <div class="txt-tit ty04">타입</div>
            <div class="row">
              <div class="txt">자유형식</div>
            </div>
          </div>
          <!--form-row-->
          <div class="form-row ty05">
            <div class="txt-tit ty04">안내란노출</div>
            <div class="row">
              <v-switch :model-value="true" inset hide-details/>
            </div>
          </div>
          <!--form-row-->
          <div class="form-row ty05">
            <div class="txt-tit ty04">사용자파일 첨부기능</div>
            <div class="row">
              <v-switch :model-value="true" inset hide-details/>
            </div>
          </div>
        </div>
        <div class="sort-group ty02">
          <div class="sort-row">
            <div class="wl">
              <div class="txt05">안내작성</div>
            </div>
          </div>
        </div>
        <div class="editor-ty">
          <!--아래 div 추후삭제 -->
          <div style="height:36.7rem; background:#ddd;display:flex;align-items:center;justify-content: center;">텍스트에디터</div>
        </div>
        <div class="form-group col-ty">
          <!--form-row-->
          <div class="form-row ty05">
            <div class="txt-tit ty04">교육자료 추가</div>
            <div class="row">
              <FileUpload/>
            </div>
          </div>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class=" d-flex justify-end pop-bottom-bg">
        <c-btn02 btnText="취소"/>
        <c-btn01 btnText="저장"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Sortable } from 'sortablejs-vue3';
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
</script>


<style lang="scss" scoped>
.sortable-group02{
  .sortable-header ,.sortable-cont .draggable{
    .col:nth-child(1){flex-grow:0;width:5%;}
    .col:nth-child(2){justify-content:flex-start;text-align:left}
    .col:nth-child(3){flex-grow:0;width:6%;}
    .col:nth-child(4){flex-grow:0;width:6%;}
    .col:nth-child(5){flex-grow:0;width:12%;}
    .col:nth-child(6){flex-grow:0;width:12%;}
    .col:nth-child(7){flex-grow:0;width:8%;}
    .col:nth-child(8){flex-grow:0;width:6%;}
    .col:nth-child(9){flex-grow:0;width:6%;}
    .col:nth-child(10){flex-grow:0;width:6%;}
    .col:nth-child(11){flex-grow:0;width:5%;}
    .col:nth-child(12){flex-grow:0;width:5%;}
  }
  .sortable-footer{
    .col:nth-child(1){flex-grow:0;width:16%;}
    .col:nth-child(2){flex-grow:0;width:38%;}
  }
}
</style>
