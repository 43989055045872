import api from "./api";
import Utils from "@/common/Utils";

class LogService {
  getLogList(params) {
    return api
      .post("/log/getLogList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

}

export default new LogService();
