<template>
  <div class="content-wrap">
    <NaviBox/>
    <div class="tit-group02">
      <h2 class="tit">모니터링 관리</h2>
    </div>
    <div class="content-box">
      <div class="sort-group">
        <div class="sort-row">
          <div class="wl">
            <div class="txt-total">Total: {{ totalCnt }}</div>
            <c-InputSearch :placeholder="'콤마로 다중 검색 가능'" v-model="inputSearch" @search-word="searchWord"/>
          </div>
          <div class="wr">
            <c-btn01 :btnText="'신규등록'" @click="onClickViewPage"/>
          </div>
        </div>
      </div>
      <!-- v-data-table 가져옴-->
      <div class="tbl-group size-custom8">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          :items-per-page-options="footerOptions"
          return-object
          show-select
          @click:row="onClickViewPage"
        >
          <template v-slot:item.monitoringName="{ item }" >
            <div class="flex-l">
              <div class="chk-favorites-ty">
                <v-checkbox
                  :model-value="true"
                ></v-checkbox>
              </div>
              <v-btn variant="text">
                <div class="ell01">
                  {{item.monitoringName}}
                </div>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.btnCopy="{ item }">
            <div class="flex-c">
              <c-btnText01 :btnText="'복사'" @click.prevent.stop="onClickCopy(item.idx)"/>
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="bottom-btn-group">
        <div class="wl"></div>
        <div class="wr">
          <c-btnLine01 :btnText="'일괄 대상자 변경'"  @click="dialogChangeModel=true"/>
          <c-btnLine01 :btnText="'일괄 상태 변경'"  @click="dialogStateChangeModel=true"/>
          <c-btn02 :btnText="'일괄 숨기기'"/>
          <c-btn02 :btnText="'일괄 삭제'"  @click="dialogAllDeleteModel=true"/>
        </div>
      </div>
    </div>
  </div>
 <!--
  <c-dialog v-model="dialogSubjectChangeModel"
            v-bind="dialogSubjectChangeBind"
            @close="dialogSubjectChangeModel=false"
  />
  -->
  <!--일괄 대상자 변경-->
  <DialogTargetAdd2
    v-model="dialogChangeModel"
    @close="dialogChangeModel=false"
    @confirm="changeMember"
  />
  <c-dialog v-model="dialogCopyModel"
            v-bind="dialogCopyBind"
            @close="dialogCopyModel=false"
            @confirm="copyItem()"
  />
  <c-dialog v-model="dialogStateChangeModel"
            v-bind="dialogStateChangeBind"
            @close="dialogStateChangeModel=false"
            @confirm="changeStatus"
  />
  <c-dialog v-model="dialogAllDeleteModel"
            v-bind="dialogAllDeleteBind"
            @close="dialogAllDeleteModel=false"
            @confirm="deleteItems()"
  />
  <v-snackbar v-model="snackbar" :timeout="1000"> {{ snackText }} </v-snackbar>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useBbsStore } from "@/store/bbs";
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";

//store
const store = useBbsStore()
const storeRef = storeToRefs(store)

//bind
const snackbar = ref(false)
const snackText = ref('')
const totalCnt = ref(0)
const inputSearch = ref(null)
const dialogChangeModel = ref(false)
const dialogCopyModel = ref(false)
const dialogStateChangeModel = ref(false)
const dialogAllDeleteModel = ref(false)
const selected = ref([])
const items = ref([])

//ui
const dialogCopyBind = {
  dialogTit: '행동 복사',
  dialogText: '선택된 모니터링을 복사합니다.<br>진행중인 대상을 신규모니터링에도 추가할까요?<br>단, 대상자 복사 시 즉시 노출을 방지하기 위해 모니터링은 종료된 채로 복사 됩니다.',
  btn1Text: '대상 포함 복사',
  btn1Show: true,
  btn2Text: '대상 미포함 복사',
  btn2Show: true,
}
const dialogStateChangeBind = {
  dialogTit: '모니터링 상태 변경',
  dialogText: '선택된 모니터링을 활성화/비활성화 합니다.',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const dialogAllDeleteBind = {
  dialogTit: '행동 삭제',
  dialogText: '선택된 행동을 삭제합니다.<br>삭제된 데이터는 복구가 불가능합니다.',
  btn1Text: '삭제',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const headers = ref([
  { title: '', key: 'isBookmark' },
  { title: '모니터링명', key: 'monitoringTitle' },
  { title: '대상자수', key: 'targetCnt' },
  { title: '기간', key: 'period'},
  { title: '타입 ', key: 'state' },
  { title: '복사', key: 'btnCopy' },
])
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]

//hook
let copyIdx = null
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        isBookmark:true,
        monitoringTitle: '모니터링 A',
        targetCnt: '23',
        period: '24.01.25 ~ 25.01.25',
        state: '진행중',
        btnCopy:true,
      },
      {
        isBookmark:true,
        monitoringTitle: '협력사_에스피엠텍(B)',
        targetCnt: '23',
        period: '24.01.25 ~ 25.01.25',
        state: '진행중',
        btnCopy:true,
      }
    ]
  }
  else{
    searchWord()
  }
})

//function
function searchWord(){
  let companyIdx = localStorage.getItem('companyIdx')
  const word = inputSearch.value
  let params = {
    keyword: word,
    companyIdx:companyIdx,
  }

  BbsService.getBbsList(params).then(
    (response) => {
      items.value = response.result
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function deleteItems(){
  if(import.meta.env.MODE == 'development') {
    selected.value.forEach(function (item){
      const idx = items.value.findIndex(function(tempItem) {return tempItem.idx === item.idx})
      if (idx > -1) items.value.splice(idx, 1)
    })

    snackText.value = 'success'
    snackbar.value = true
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    if(!companyIdx)
      utils.movePage('/login')

    let deleteArr = []
    selected.value.forEach(function (item){
      deleteArr.push(item.idx)
    })
    let params = {
      companyIdx:companyIdx,
      deleteArr: deleteArr,
    }

    BbsService.deleteBbs(params).then(
      (response) => {
        if(response.success) {
          selected.value.forEach(function (item){
            const idx = items.value.findIndex(function(tempItem) {return tempItem.idx === item.idx})
            if (idx > -1) items.value.splice(idx, 1)
          })

          snackText.value = 'success'
          snackbar.value = true
        }
        else
          alert(response.message)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function onClickCopy(idx){
  dialogCopyModel.value=true
  copyIdx = idx
}

function copyItem(){
  if(import.meta.env.MODE == 'development') {
    snackText.value = 'success'
    snackbar.value = true
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    if(!companyIdx)
      utils.movePage('/login')

    let copyArr = []
    copyArr.push(copyIdx)
    let params = {
      companyIdx:companyIdx,
      copyArr: copyArr,
    }

    BbsService.copyBbs(params).then(
      (response) => {
        if(response.success) {
          snackText.value = 'success'
          snackbar.value = true
          searchWord()
        }
        else
          alert(response.message)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function onClickViewPage(event, row){
  if(row)
    storeRef.viewIdx.value = row.item.idx
  else
    storeRef.viewIdx.value = null
  utils.movePage('monitoringManagementListDetail')
}

function changeMember(){

}

function changeStatus(){

}
</script>


<style lang="scss" scoped>
.tbl-group{
  th:nth-child(2)>div{justify-content:flex-start !important;padding-left:5rem}
  td:nth-child(2){width:45%;}
  td:nth-child(4){width:20%;}
}
</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
