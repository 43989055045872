<template>
  <div class="navi-group">
    <div class="top">
      <div class="tit" @click="$router.push('/safetyLeadership')">
        안전리더십계획
      </div>
      <div class="navi-info-btn">
        <v-menu
          transition="slide-y-transition"
          location="right center"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
            >
            </v-btn>
          </template>
          <v-list class="set-menu-list">
            <v-list-item
              v-for="(item, i) in naviSet"
              :key="i"
            >
              <v-list-item-title>
                <button type="button" @click="move(item.link)">
                  {{ item.title }}
                </button>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-title>즐겨찾기</v-expansion-panel-title>
        <v-expansion-panel-text>
          <ul>
            <li>
              <div class="chk-favorites">
                <v-checkbox
                  :model-value="true"
                ></v-checkbox>
              </div>
              <div class="btn-navi-txt">
                <v-btn @click="$router.push('/safetyLeadershipRun')">안전리더십A</v-btn>
              </div>
              <div class="navi-more-btn">
                <v-menu
                  transition="slide-y-transition"
                  location="right center"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                    >
                    </v-btn>
                  </template>
                  <v-list class="set-menu-list">
                    <v-list-item
                      v-for="(item, i) in naviList"
                      :key="i"
                    >
                      <v-list-item-title>
                        <button type="button" @click="move(item.link)">
                          {{ item.title }}
                        </button>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </li>
            <li>
              <div class="chk-favorites">
                <v-checkbox
                  :model-value="true"
                ></v-checkbox>
              </div>
              <div class="btn-navi-txt">
                <v-btn @click="$router.push('/safetyLeadershipRun')">안전리더십B</v-btn>
              </div>
              <div class="navi-more-btn">
                <v-menu
                  transition="slide-y-transition"
                  location="right center"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                    >
                    </v-btn>
                  </template>
                  <v-list class="set-menu-list">
                    <v-list-item
                      v-for="(item, i) in naviList"
                      :key="i"
                    >
                      <v-list-item-title>
                        <button type="button" @click="move(item.link)">
                          {{ item.title }}
                        </button>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </li>
          </ul>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-title>나의 과제</v-expansion-panel-title>
        <v-expansion-panel-text>
          <ul>
            <li>
              <div class="chk-favorites">
                <v-checkbox
                  :model-value="true"
                ></v-checkbox>
              </div>
              <div class="btn-navi-txt">
                <v-btn @click="$router.push('/safetyLeadershipRun')">안전리더십C</v-btn>
              </div>
              <div class="navi-more-btn">
                <v-menu
                  transition="slide-y-transition"
                  location="right center"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                    >
                    </v-btn>
                  </template>
                  <v-list class="set-menu-list">
                    <v-list-item
                      v-for="(item, i) in naviList"
                      :key="i"
                    >
                      <v-list-item-title>
                        <button type="button" @click="move(item.link)">
                          {{ item.title }}
                        </button>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </li>
            <li>
              <div class="chk-favorites">
                <v-checkbox
                  :model-value="true"
                ></v-checkbox>
              </div>
              <div class="btn-navi-txt">
                <v-btn @click="$router.push('/safetyLeadershipRun')">안전리더십D</v-btn>
              </div>
              <div class="navi-more-btn">
                <v-menu
                  transition="slide-y-transition"
                  location="right center"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                    >
                    </v-btn>
                  </template>
                  <v-list class="set-menu-list">
                    <v-list-item
                      v-for="(item, i) in naviList"
                      :key="i"
                    >
                      <v-list-item-title>
                        <button type="button" @click="move(item.link)">
                          {{ item.title }}
                        </button>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </li>
          </ul>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-title>타인 관찰 과제</v-expansion-panel-title>
        <v-expansion-panel-text>
          <ul>
            <li>
              <div class="chk-favorites">
                <v-checkbox
                  :model-value="true"
                ></v-checkbox>
              </div>
              <div class="btn-navi-txt">
                <v-btn @click="$router.push('/safetyLeadershipRun')">안전리더십C</v-btn>
              </div>
              <div class="navi-more-btn">
                <v-menu
                  transition="slide-y-transition"
                  location="right center"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                    >
                    </v-btn>
                  </template>
                  <v-list class="set-menu-list">
                    <v-list-item
                      v-for="(item, i) in naviList"
                      :key="i"
                    >
                      <v-list-item-title>
                        <button type="button" @click="move(item.link)">
                          {{ item.title }}
                        </button>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </li>
            <li>
              <div class="chk-favorites">
                <v-checkbox
                  :model-value="true"
                ></v-checkbox>
              </div>
              <div class="btn-navi-txt">
                <v-btn @click="$router.push('/safetyLeadershipRun')">안전리더십D</v-btn>
              </div>
              <div class="navi-more-btn">
                <v-menu
                  transition="slide-y-transition"
                  location="right center"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                    >
                    </v-btn>
                  </template>
                  <v-list class="set-menu-list">
                    <v-list-item
                      v-for="(item, i) in naviList"
                      :key="i"
                    >
                      <v-list-item-title>
                        <button type="button" @click="move(item.link)">
                          {{ item.title }}
                        </button>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </li>
          </ul>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>


<script lang="ts" setup>
import { ref } from 'vue'
import router from '@/router';
const naviSet = [
  { title: '행동관리', link:'safetyLeadershipAMList'},
  { title: '안전리더십관리', link:'safetyLeadershipManagement'},
]
const naviList = [
  { title: '수정', link:''},
  { title: '사용중지/사용재계', link:''},
  { title: '삭제', link:'' },
]
const panel = ref([0, 1, 2])
function move(page){
  router.push({ path: page })
}
</script>

<style lang="scss">

.navi-group{
  position:absolute;
  top:0;
  left:0;
  padding:2rem 3rem 3rem;
  width:26rem;
  border:1px solid var(--color-line);
  border-radius:2rem;
  .top{
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding-bottom:1.2rem;
    .tit{
      font-size:1.8rem;
      line-height:2.4rem;
      font-weight:700;
    }
    .navi-info-btn{
      margin-left:.7rem;
      .v-btn--size-default{
        --v-btn-height:2.4rem;
      }
      .v-btn{
        background:url("@/assets/img/ico-set.svg")center no-repeat;
        box-shadow:none;
        min-width:2.4rem;
        padding:0;
        border-radius:100%;
        & > *{border-radius:100%;width:2.4rem;height:2.4rem}
        @supports selector(:focus-visible) {
          .v-btn::after {
            border:0
          }
        }
      }
    }
  }

  .v-expansion-panel{
    border-radius:.6rem;
    border:1px solid var(--color-line);
    .v-expansion-panel__shadow{
      box-shadow:0 .4rem 2rem 0 rgba(0, 0, 0, 0.07);
    }
    .v-expansion-panel-title{
      font-size:1.5rem;
      font-weight:700;
      min-height:4.8rem;
      padding:1rem 1rem 1rem 1.5rem;
    }
    &.v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static){
      min-height:4.8rem;
    }
    .v-expansion-panel-text__wrapper{
      padding:0;
    }
    .mdi-chevron-up::before{
      opacity:.4;
    }
    li{
      position:relative;
      display:flex;
      justify-content: space-between;
      align-items: center;
      padding:1.2rem .8rem;
      .chk-favorites{
        .v-input{
          display: flex;
          .v-selection-control__input{
            width:3rem;
            height:3rem;
          }
          .v-selection-control__wrapper{
            width:3rem;
            height:3rem;
          }
          .v-icon{
            width:2.4rem;
            height:2.4rem;
            &:before{
              content:'';
              width:2.4rem;
              height:2.4rem;
              background-position:center;
              background-repeat:no-repeat;
              background-size:2.4rem auto;
            }
            &.mdi-checkbox-blank-outline::before{
              background-image: url("/src/assets/img/star-off.png");
            }
            &.mdi-checkbox-marked::before{
              background-image: url("/src/assets/img/star-on.png");

            }
          }
        }
        .v-input--density-default{
          --v-input-control-height:2.4rem;
          --v-input-padding-top:0
        }
      }
      .btn-navi-txt{
        display:inline-block;
        flex:1;
        .v-btn--variant-elevated{
          box-shadow:none;
        }
        .v-btn{
          padding:0;
          font-size:1.5rem;
          line-height:3rem;
          color:var(--navi-box-txt);
          height:3rem;
          font-weight:700;
          border-radius:0;
          letter-spacing:0;
          .v-btn__content{
            display:block;
            width:100%;
            text-overflow:ellipsis;
            overflow:hidden;
          }
        }
      }
      .navi-more-btn{
        margin-left:.7rem;
        .v-btn--size-default{
          --v-btn-height:2.4rem;
        }
        .v-btn{
          background:url("@/assets/img/dots-vertical.svg")center no-repeat;
          opacity:.6;
          box-shadow:none;
          min-width:2.4rem;
          padding:0;
          border-radius:100%;
          & > *{border-radius:100%;width:2.4rem;height:2.4rem}
          @supports selector(:focus-visible) {
            .v-btn::after {
              border:0
            }
          }
        }
      }
    }
  }
  .v-expansion-panel + .v-expansion-panel{margin-top:2rem}
  .v-expansion-panels:not(.v-expansion-panels--variant-accordion) > :first-child:not(:last-child):not(.v-expansion-panel--active):not(.v-expansion-panel--before-active){
    border-bottom-left-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important;
  }
  .v-expansion-panels:not(.v-expansion-panels--variant-accordion) > :last-child:not(:first-child):not(.v-expansion-panel--active):not(.v-expansion-panel--after-active){
    border-top-left-radius: 0.6rem  !important;
    border-top-right-radius: 0.6rem  !important;
  }
  .v-expansion-panel:not(:first-child)::after{
    display: none;
  }
}
@media (max-width: 853px){
  .navi-group{
    position:relative;
    padding:2rem 0 0;
    width:100%;
    border:0;
    .v-expansion-panel-title:hover > .v-expansion-panel-title__overlay{
      opacity:0;
    }
    .mdi-chevron-down::before{
      opacity:0.4;
    }
  }
}
</style>
