<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="tit-group ty01">
        <h4 class="tit">작업자 안전행동 관찰</h4>
      </div>
      <div class="tab-group ty01">
        <v-tabs
          v-model="tab"
        >
          <v-tab value="tabCont1">현재 모니터링</v-tab>
          <v-tab value="tabCont2">전체 모니터링</v-tab>
          <v-tab value="tabCont3">부서 모니터링</v-tab>
        </v-tabs>
      </div>
      <div class="content-box bg-none">
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="tabCont1">
            <div class="tit-group02 inner-ty">
              <h2 class="tit">Total: 132</h2>
              <div class="filter-btn-group">
                <v-btn class="reset" :class="filterDiv1">필터초기화</v-btn>
                <v-btn class="filter" @click="filterOpen1" :class="filterDiv1">필터</v-btn>
              </div>
            </div>
            <div class="sort-group ty01" :class="filterDiv1">
              <div class="sort-row">
                <div class="wl">
                  <div class="txt-total">Total: 132</div>
                  <c-InputSearch v-bind="InputSearch"/>
                </div>
              </div>
              <div class="sort-row">
                <div class="wl">
                  <div class="txt01">기간</div>
                  <div class="date-sort">
                    <c-InputDate :enableTimePicker="false" readonly="" />
                    <div class="txt02">~</div>
                    <c-InputDate :enableTimePicker="false" readonly=""/>
                  </div>
                  <div class="date-sort">
                    <c-btn02 :btnText="'일주'" class="ty01"/>
                    <c-btn02 :btnText="'한달'" class="ty01"/>
                  </div>
                  <Select v-bind="select01"/>
                  <Select v-bind="select02"/>
                </div>
              </div>
            </div>
            <div class="tbl-group size-custom4">
              <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page-options="footerOptions"
                return-object
              >
                <template v-slot:item.author="{ item }" >
                  <div class="ell01">
                    {{item.author}}
                  </div>
                  <div class="txt-tag">
                    {{item.authorDept}}
                  </div>
                </template>
                <template v-slot:item.moData="{ item }" >
                  <!--s:모바일용 데이터-->
                  <div class="td-mobile">
                    모니터링 1
                  </div>
                  <div class="td-mobile n2">
                    {{item.author}}
                    <span>
                    {{item.authorDept}}
                    </span>
                  </div>
                  <div class="td-mobile n3">
                    2024-01-25 13:28:40
                  </div>
                  <!--e:모바일용 데이터-->
                </template>
                <template v-slot:item.detailView="{ item }">
                  <div class="flex-c">
                    <BtnIcon class="btn-ico-search" @click="isShowDialog=true"/>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont2">
            <div class="tit-group02 inner-ty">
              <h2 class="tit">Total: 132</h2>
              <div class="filter-btn-group">
                <v-btn class="reset" :class="filterDiv2">필터초기화</v-btn>
                <v-btn class="filter" @click="filterOpen2" :class="filterDiv2">필터</v-btn>
              </div>
            </div>
            <div class="sort-group ty01" :class="filterDiv2">
              <div class="sort-row">
                <div class="wl">
                  <div class="txt-total">Total: 132</div>
                  <c-InputSearch v-bind="InputSearch"/>
                </div>
              </div>
              <div class="sort-row">
                <div class="wl">
                  <div class="txt01">기간</div>
                  <div class="date-sort">
                    <c-InputDate :enableTimePicker="false" readonly="" />
                    <div class="txt02">~</div>
                    <c-InputDate :enableTimePicker="false" readonly=""/>
                  </div>
                  <div class="date-sort">
                    <c-btn02 :btnText="'일주'" class="ty01"/>
                    <c-btn02 :btnText="'한달'" class="ty01"/>
                  </div>
                  <Select v-bind="select01"/>
                  <Select v-bind="select02"/>
                </div>
              </div>
            </div>
            <div class="tbl-group size-custom4">
              <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page-options="footerOptions"
                return-object
              >
                <template v-slot:item.author="{ item }" >

                  <div class="ell01">
                    {{item.author}}
                  </div>
                  <div class="txt-tag">
                    {{item.authorDept}}
                  </div>
                </template>

                <template v-slot:item.moData="{ item }" >
                  <!--s:모바일용 데이터-->
                  <div class="td-mobile">
                    모니터링 1
                  </div>
                  <div class="td-mobile n2">
                    {{item.author}}
                    <span>
                    {{item.authorDept}}
                    </span>
                  </div>
                  <div class="td-mobile n3">
                    2024-01-25 13:28:40
                  </div>
                  <!--e:모바일용 데이터-->
                </template>
                <template v-slot:item.detailView="{ item }">
                  <div class="flex-c">
                    <BtnIcon class="btn-ico-search" @click="isShowDialog=true"/>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont3">
            <div class="tit-group02 inner-ty">
              <h2 class="tit">Total: 132</h2>
              <div class="filter-btn-group">
                <v-btn class="reset" :class="filterDiv3">필터초기화</v-btn>
                <v-btn class="filter" @click="filterOpen3" :class="filterDiv3">필터</v-btn>
              </div>
            </div>
            <div class="sort-group ty01" :class="filterDiv3">
              <div class="sort-row">
                <div class="wl">
                  <div class="txt-total">Total: 132
                  </div>
                  <c-InputSearch v-bind="InputSearch"/>
                </div>
              </div>
              <div class="sort-row">
                <div class="wl">
                  <div class="txt01">기간</div>
                  <div class="date-sort">
                    <c-InputDate :enableTimePicker="false" readonly="" />
                    <div class="txt02">~</div>
                    <c-InputDate :enableTimePicker="false" readonly=""/>
                  </div>
                  <div class="date-sort">
                    <c-btn02 :btnText="'일주'" class="ty01"/>
                    <c-btn02 :btnText="'한달'" class="ty01"/>
                  </div>
                  <Select v-bind="select01"/>
                  <Select v-bind="select02"/>
                </div>
              </div>
            </div>
            <div class="tbl-group size-custom4">
              <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page-options="footerOptions"
                return-object
              >
                <template v-slot:item.author="{ item }" >
                  <div class="ell01">
                    {{item.author}}
                  </div>
                  <div class="txt-tag">
                    {{item.authorDept}}
                  </div>
                </template>
                <template v-slot:item.moData="{ item }" >
                  <!--s:모바일용 데이터-->
                  <div class="td-mobile">
                    모니터링 1
                  </div>
                  <div class="td-mobile n2">
                    {{item.author}}
                    <span>
                    {{item.authorDept}}
                    </span>
                  </div>
                  <div class="td-mobile n3">
                    2024-01-25 13:28:40
                  </div>
                  <!--e:모바일용 데이터-->
                </template>
                <template v-slot:item.detailView="{ item }">
                  <div class="flex-c">
                    <BtnIcon class="btn-ico-search" @click="isShowDialog=true"/>
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>
  <DialogMonitoringDetail
    v-model="isShowDialog"
    :popTitle="'부서'"
    @close="isShowDialog=false"/>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
const isShowDialog = ref(false)
const tab = ref(null)
const InputSearch = {
  type: 'text',
  placeholder: '검색어를 입력하세요. (,로 다중검색)',
}
const select01 = {
  select:  '부서',
  items: ['부서', '부서명']
}
const select02 = {
  select:  '개인',
  items: ['개인', '홍길동']
}
const headers = ref([

  { title: '순번', key: 'number' },
  { title: '모니터링명', key: 'monitoringName' },
  { title: '작성자', key: 'author' },
  { title: '모니터링여부', key: 'monitoring'},
  { title: '안전대화여부 ', key: 'safetyTalk'},
  { title: '작성일시', key: 'creationDate' },
  { title: '모니터링 / 작성자 / 작성일시', key: 'moData' },//모바일
  { title: '상세보기', key: 'detailView' },

])
const items = ref([
  {
    number: '1',
    monitoringName: '모니터링1',
    author: '김동임',
    authorDept: '생산1팀/과장',
    monitoring: 'O',
    safetyTalk: 'X',
    creationDate: '2024-01-25 13:28:40'
  },
])
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]
//필터 메뉴 열고 닫기
const filterDiv1 = ref('off')
const filterDiv2 = ref('off')
const filterDiv3 = ref('off')
function filterOpen1(){
  if(filterDiv1.value == 'off')
    filterDiv1.value = 'on'
  else
    filterDiv1.value = 'off'
}
function filterOpen2(){
  if(filterDiv2.value == 'off')
    filterDiv2.value = 'on'
  else
    filterDiv2.value = 'off'
}
function filterOpen3(){
  if(filterDiv3.value == 'off')
    filterDiv3.value = 'on'
  else
    filterDiv3.value = 'off'
}
</script>


<style lang="scss" scoped>
.container.main.on{
  .v-window{}
}
</style>
