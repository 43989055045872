<template>
  <div class="list-group2 ty01 mg-none">
    <!--list-->
    <div class="list">
      <div class="row1">
        <div class="num">1</div>
        <div class="txt">동행 점검 시 동일한 포인트를 확인하여 위험예지 시각을 통일한다.</div>
      </div>
      <div class="row2">
        <div class="radio-group2">
          <v-radio-group v-model="radios">
            <v-radio label="안전" value="안전" ></v-radio>
            <v-radio label="불안전" value="불안전"></v-radio>
          </v-radio-group>
        </div>
      </div>
    </div>
    <!--list-->
    <div class="list">
      <div class="row1">
        <div class="num">2</div>
        <div class="txt"> 일일미팅(모닝미팅/TBM)을 통해 특별히 안전에 신경써야 할 사항을 공유하고 주시시킨다. 동행 점검 시 동일한 포인트를 확인하여 위험예지 시각을 통일한다.</div>
      </div>
      <div class="row2">
        <div class="radio-group2">
          <v-radio-group v-model="radios2">
            <v-radio label="안전" value="안전" ></v-radio>
            <v-radio label="불안전" value="불안전"></v-radio>
          </v-radio-group>
        </div>
      </div>
    </div>
    <!--list-->
    <div class="list">
      <div class="row1">
        <div class="num">3</div>
        <div class="txt"> 카운터 형식</div>
      </div>
      <div class="row2">
        <div class="row2-inner">
          <div class="inner-col">
            안전
            <InputCount/>
          </div>
          <div class="inner-col">
            불안전
            <InputCount/>
          </div>
        </div>
      </div>
    </div>
    <!--list-->
    <div class="list">
      <div class="row1">
        <div class="num">4</div>
        <div class="txt"> 입력형식</div>
      </div>
      <div class="row2">
         <Textarea :placeholder="'내용을 입력하세요.'"/>
      </div>
    </div>
    <!--list-->
    <div class="list">
      <div class="row1">
        <div class="num">5</div>
        <div class="txt">동행 점검 시 동일한 포인트를 확인하여 위험예지 시각을 통일한다.</div>
      </div>
      <div class="row2">
        <div class="radio-group2">
          <v-radio-group v-model="radios3">
            <v-radio label="안전" value="안전" ></v-radio>
            <v-radio label="불안전" value="불안전"></v-radio>
          </v-radio-group>
        </div>
        <div class="inner">
          <c-input :placeholder="'set-up'"></c-input>
          <c-input :placeholder="'chiler 점검'" disabled="disabled"></c-input>
          <c-input :placeholder="'chiler 교체'"></c-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
const radios = ref('안전')
const radios2 = ref('안전')
const radios3 = ref('불안전')
</script>


<style lang="scss" scoped>
</style>
