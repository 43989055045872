<template>
  <Form @submit="handleForm" :validation-schema="schema">
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">아이디<span class="imp">*</span></div>
          <div class="row">
            <c-Input v-bind="inpId" v-model="memberId" :name="'memberId'" class="size01"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">비밀번호<span class="imp">*</span></div>
          <div class="row">
            <c-Input v-bind="inpPw" v-model="password" :name="'password'" class="size01"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">부서<span class="imp">*</span></div>
          <div class="row">
            <c-btn01
              v-bind="btnPartChange"
              class="sm"
              @click="openPopup('dept')"/>
            <div class="txt02">{{deptName}}</div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">직책<span class="imp">*</span></div>
          <div class="row">
            <c-btn01
              v-bind="btnPartChange"
              class="sm"
              @click="openPopup('pos')"/>
            <div class="txt02">{{posName}}</div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">전화번호</div>
          <div class="row">
            <c-Input v-bind="inpTel" v-model="telNo" :name="'telNo'" class="size01"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">이메일</div>
          <div class="row">
            <c-Input v-bind="inpEmail" v-model="email"  :name="'email'" class="size01"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">
            <c-tooltip v-bind="useTooltip"/>
            사용 여부<span class="imp">*</span>
          </div>
          <div class="row">
            <v-switch v-model="useYn" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">
            <c-tooltip v-bind="adminTooltip"/>
            관리자 여부<span class="imp">*</span>
          </div>
          <div class="row">
            <v-switch v-model="adminYn" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">
            <c-tooltip v-bind="leaderTooltip"/>
            조직장 여부<span class="imp">*</span>
          </div>
          <div class="row">
            <v-switch v-model="headYn" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">교대조 여부<span class="imp">*</span></div>
          <div class="row">
            <v-switch v-model="shiftYn" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">
            <c-tooltip v-bind="pwChangeTooltip"/>
            비밀번호 여부<span class="imp">*</span>
          </div>
          <div class="row">
            <v-switch v-model="passwordYn" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">
            변경 정보<span class="imp">*</span>
          </div>
          <div class="row">
            <c-btnText01 :btnText="historyCnt" @click="showDialog()"/>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-group">
      <div class="wl">
        <c-btnLine01 v-bind="btnPageList" @click.stop="onClickGoList()"/>
      </div>
      <div class="wr">
        <c-btn02 v-bind="btnPageDel" @click="dialogPageDelModel=true"/>
        <c-btn01 v-bind="btnPageModify"/>
      </div>
    </div>
  </div>
  </Form>
  <!--부서-->
  <DialogDept
    v-model="isShowDialog01"
    @close="closePopup"/>
  <!--변경정보-->
  <c-dialog v-model="dialogChangeNumModel"
            v-bind="dialogChangeNumBind"
            @close="dialogChangeNumModel=false"
  />
  <!--삭제-->
  <c-dialog v-model="dialogPageDelModel"
            v-bind="dialogPageDelBind"
            @close="dialogPageDelModel=false"
  />
</template>
<style lang="scss" >
</style>
<script setup lang="ts">
import { ref } from 'vue'
import MemberService from "@/services/member.service";
import utils from "@/common/Utils";
import {useOrganizationalManagementStore} from "@/store/organizationalManagement";
import {storeToRefs} from "pinia";
import {Form} from "vee-validate";
import * as yup from "yup";
import _ from 'lodash';
import LogService from "@/services/log.service";

//store
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)

//validation schema
const schema = yup.object().shape({
  //https://stackoverflow.com/questions/62950190/how-to-set-dynamic-error-messages-in-yup-async-validation
  memberId: yup.string().required("필수 항목입니다."),
  password: yup.string().required("필수 항목입니다."),
  telNo: yup.number().typeError("숫자만 입력 가능합니다.").required("필수 항목입니다."),
  email: yup.string().email('형식이 맞지않습니다.').required("필수 항목입니다."),
});

//bind
const isShowDialog01 = ref(false)
const dialogChangeNumModel = ref(false)
const viewIdx = storeRef.viewIdx.value
const memberId = ref('')
const password = ref('')
const deptIdx = ref('')
const deptName = ref('')
const posIdx = ref('')
const posName = ref('')
const telNo = ref('')
const email = ref('')
const useYn = ref(true)
const adminYn = ref(false)
const headYn = ref(false)
const shiftYn = ref(false)
const passwordYn = ref(true)
const historyCnt = ref('')

//ui
const dialogChangeNumBind = {
  dialogTit: '변경정보 (변경 내용/변경자/날짜 순)',
  dialogText: '생성 / admin / 2024.01.03<br>수정(부서,직책,사용여부) / admin / 2024.01.04',
  btn1Text: '확인',
  btn1Show: true,
}
const dialogPageDelModel = ref(false)
const dialogPageDelBind = {
  dialogTit: '삭제',
  dialogText: '삭제 시 복구할 수 없게 됩니다. 진행하시겠습니까?',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const inpId = {
  placeholder: '아이디를 입력하세요'
}
const inpPw = {
  placeholder: '비밀번호를 입력하세요',
  type:'password'
}
const inpTel = {
  placeholder: '전화번호를 입력하세요',
  type:'tel'
}
const inpEmail = {
  placeholder: '이메일을 입력하세요',
  type:'email'
}
const btnPartChange = {
  btnText: '변경',
  btnShow: true,
  btnType: 'button'
}
const btnChangeNum = {
  btnText: '3번',
}
const useTooltip = {
  TooltipText: '회원을 활성화 혹은 비활성화 하는 기능입니다'
}
const adminTooltip = {
  TooltipText: '회사 관리자의 경우, 관리 권한이 회사 내로 한정됩니다.'
}
const leaderTooltip = {
  TooltipText: '보고서가 자동 발송 되는 권한입니다.'
}
const pwChangeTooltip = {
  TooltipText: '사용자가 직접 비밀번호를 변경할 수 있도록 하려면 On으로 변경해주세요.'
}
const btnPageList = {
  btnText: '목록',
}
const btnPageDel = {
  btnText: '삭제',
}
const btnPageModify = {
  btnText: '등록/수정',
  btnType: 'submit'
}

//hook
onMounted(() => {
  if(viewIdx) {
    let params = {
      idx: viewIdx
    }

    MemberService.getMember(params).then(
      (response) => {
        memberId.value = response.result.memberId
        password.value = response.result.password
        deptIdx.value = response.result.deptIdx
        posIdx.value = response.result.posIdx
        deptName.value = response.result.deptName
        posName.value = response.result.posName
        telNo.value = response.result.telNo
        email.value = response.result.email
        useYn.value = response.result.useYn
        adminYn.value = response.result.adminYn
        headYn.value = response.result.headYn
        shiftYn.value = response.result.shiftYn
        passwordYn.value = response.result.passwordYn
        historyCnt.value = response.result.historyCnt
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
})

//function
function handleForm(){
  if(import.meta.env.MODE == 'development') {
    store.moveTab('tabCont2')
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    if(!companyIdx)
      utils.movePage('/login')

    let params = {
      idx: viewIdx,
      companyIdx:companyIdx,
      memberId: memberId.value,
      password: password.value,
      deptIdx: deptIdx.value,
      posIdx: posIdx.value,
      deptName: deptName.value,
      posName: posName.value,
      telNo: telNo.value,
      email: email.value,
      useYn: useYn.value,
      adminYn: adminYn.value,
      headYn: headYn.value,
      shiftYn: shiftYn.value,
      passwordYn: passwordYn.value,
    }

    MemberService.insertMember(params).then(
      (response) => {
        if(response.success)
          store.moveTab('tabCont2')
        else
          alert(response.message)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function openPopup(type){
  let companyIdx = localStorage.getItem('companyIdx')
  storeRef.popupIdx.value = companyIdx
  storeRef.popupType.value = type
  isShowDialog01.value = true
}

function closePopup(type){
  isShowDialog01.value=false
  if(type !== false) {
    console.log(type)
    let resultName = []
    let resultIdxs = []
    _.forEach(type, function(obj, key) {
      resultName.push(obj.treeName)
      resultIdxs.push(obj.idx)
    });

    if(storeRef.popupType.value == 'dept'){
      deptName.value = resultName.join(',')
      deptIdx.value = resultIdxs.join(',')
    }
    else if(storeRef.popupType.value == 'pos'){
      posName.value = resultName.join(',')
      posIdx.value = resultIdxs.join(',')
    }
  }
}

function onClickGoList(){
  store.moveTab('tabCont2')
}

function showDialog(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    idx: companyIdx,
    tableType: 'member'
  }
  LogService.getLogList(params).then(
    (response) => {
      let logContent = ''
      if(response.result.length > 0) {
        response.result.forEach(function(row) {
          logContent += row.changeLog + '<br>'
        });
      }

      if(logContent != '') {
        historyCnt.value = logContent
        dialogChangeNumModel.value = true
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}
</script>

