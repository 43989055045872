<template>
  <div class="content-wrap am-page-wrap" :class="amPage">
    <div class="navi-area">
      <div class="m-navi-top">
        <c-btn01 :btnText="'모니터링 대시보드'" @click="amListOpen"/>
      </div>
      <NaviBox/>
    </div>
    <div class="page1">
      <div class="page1-inner1">
        <div class="tit-group02">
          <h2 class="tit">Quick KPI</h2>
          <div class="navi-btn">
            <v-btn @click="amListOpen"/>
          </div>
        </div>
        <div class="card-group02">
        <div class="card-col ty01">
          <div class="icon ico1" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">24</div>
            <div class="txt2">빈도1</div>
          </div>
          <div class="line"></div>
          <div class="txt-group">
            <div class="txt1">13</div>
            <div class="txt2">빈도2</div>
          </div>
        </div>
        <div class="card-col ty01">
          <div class="icon ico1" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">24<span>%</span></div>
            <div class="txt2">참여율1</div>
          </div>
          <div class="line"></div>
          <div class="txt-group">
            <div class="txt1">13<span>%</span></div>
            <div class="txt2">참여율1</div>
          </div>
        </div>
        <div class="card-col ty02">
          <div class="icon ico2" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">3</div>
            <div class="txt2">시정조치횟수</div>
          </div>
        </div>
        <div class="card-col ty02">
          <div class="icon ico2" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">3<span>%</span></div>
            <div class="txt2">시정조치율</div>
          </div>
        </div>
      </div>
      </div>
      <div class="page1-inner2">
        <div class="tit-group02">
          <h2 class="tit">타임라인</h2>
        </div>
        <div class="content-box">
          <div class="sort-group">
            <div class="sort-row ty01">
              <div class="wl">
                <div class="txt01">기간</div>
                <div class="date-sort">
                  <c-InputDate :enableTimePicker="false" readonly="" />
                  <div class="txt02">~</div>
                  <c-InputDate :enableTimePicker="false" readonly=""/>
                </div>
                <div class="date-sort">
                  <c-btn02 :btnText="'당일'" class="ty01"/>
                  <c-btn02 :btnText="'분기'" class="ty01"/>
                  <c-btn02 :btnText="'올해'" class="ty01"/>
                </div>
                <Select v-bind="select01"/>
              </div>
              <div class="wr">
                <c-btnText01 :btnText="'전체보기'" class="ty01" @click="$router.push('/actionManagementAll')"/>
              </div>
            </div>
          </div>
          <div class="slider-group">
            <v-slide-group
              v-model="model"
              center-active
              show-arrows
              delimiters
            >
              <v-slide-group-item
                v-for="n in 4"
                :key="n"
                v-slot="{ isSelected, toggle }"
              >
                <v-card
                  @click="toggle"
                >
                  <div class="slider-item">
                    <div class="img-slider">
                      <v-carousel
                      >
                        <v-carousel-item
                          v-for="(item,i) in items"
                          :key="i"
                          :src="item.src"
                          cover
                          @click="isShowDialog2=true"
                        ></v-carousel-item>
                      </v-carousel>
                    </div>
                    <div @click="isShowDialog=true">
                      <div class="txt-group">
                        <div class="txt ell01">김상두 / 염소정제팀 / 관리감독자</div>
                        <div class="txt2">2024.02.12</div>
                      </div>
                      <ul class="txt-group2">
                        <li>
                          <div class="tit">행동</div>
                          <div class="txt ell01">동행 점검 시 동일한 포인트 확인</div>
                        </li>
                        <li>
                          <div class="tit">장소</div>
                          <div class="txt ell01">공장</div>
                        </li>
                        <li>
                          <div class="tit">구체적인상황</div>
                          <div class="txt ell01">구체적인 상황</div>
                        </li>
                        <li>
                          <div class="tit">원인</div>
                          <div class="txt ell01">원인</div>
                        </li>
                        <li>
                          <div class="tit">시정조치내용</div>
                          <div class="txt ell01">시멘트 안전지역 위치필요 시멘트 안전지역 위치필요</div>
                        </li>
                        <li>
                          <div class="tit">시정조치대상자</div>
                          <div class="txt ell01">홍길동</div>
                        </li>
                        <li>
                          <div class="tit">조치기한</div>
                          <div class="txt ell01">2024.05.01</div>
                        </li>
                        <li>
                          <div class="tit">진행상태</div>
                          <div class="txt2 c01">완료</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </v-card>
              </v-slide-group-item>
            </v-slide-group>
          </div>
        </div>
      </div>
      <div class="page1-inner3">
        <div class="tit-group02">
          <h2 class="tit">메뉴</h2>
        </div>
        <div class="m-menu-list">
          <v-btn class="n1" @click="$router.push('/actionManagementAll')">모니터링</v-btn>
          <v-btn class="n2" @click="$router.push('/actionManagementAll')">타임라인</v-btn>
          <v-btn class="n3" @click="$router.push('/actionManagementAll')">보고서</v-btn>
          <v-btn class="n4" @click="$router.push('/actionManagementAll')">시정조치</v-btn>
        </div>
      </div>
      <div class="text-center ma-2">
        <c-btn02
          @click="snackbar = true"
          :btnText="'하단 플로딩 메시지 테스트'"
        >
        </c-btn02>
      </div>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000"> {{ snackText }} </v-snackbar>
  </div>
  <AmDialog01 v-model="isShowDialog" @close="isShowDialog=false"/>
  <DialogImgView v-model="isShowDialog2" @close="isShowDialog2=false"/>
</template>

<script setup lang="ts">
import { ref } from 'vue';

//bind
const isShowDialog = ref(false)
const isShowDialog2 = ref(false)
const model = ref(null)
const snackbar = ref(false)
const snackText = ref('')
const select01 = {
  select:  '본인',
  items: ['본인', 'txt1', 'txt2']
}

const items = [
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/planet-.jpg',
  },
]
//모니터링 메뉴 열고 닫기
const amPage = ref('naviOff')
function amListOpen(){
  if(amPage.value == 'naviOff')
    amPage.value = 'naviOn'
  else
    amPage.value = 'naviOff'
}
</script>
<style lang="scss">
</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
