<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="60rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">{{ popupTitle }}</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>
        <!--content-->
        <v-card-text>
          <div class="dropdown-group">
            <v-list v-model:opened="listOpen">
              <!--depth1 -->
              <v-list-group v-for="(itemD1, seqD1) in filterArray(0)" :key="itemD1.idx">
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" :class="itemD1.selected?'on':''">
                    <c-Input :placeholder="popupTitle+'를 입력해주세요'" v-model="itemD1.treeName" :name="'treerow'" @click.stop.prevent=""/>
                    <div class="btn-group">
                      <c-btn01 :btnText="'삭제'" :btn-show="true" class="sm" @click.stop.prevent="onClickDelRow(itemD1)"/>
                      <c-btn02 :btnText="'추가'" :btn-show="true" class="sm" @click.stop.prevent="onClickAddRow(itemD1)"/>
                      <c-btnLine01 :btnText="'하위추가'" :btn-show="true" class="sm" @click.stop.prevent="onClickAddChild(itemD1)"/>
                    </div>
                  </v-list-item>
                </template>
                <!--depth2 -->
                <v-list-group v-for="(itemD2, seqD2) in filterArray(itemD1.idx)">
                  <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" :class="itemD2.selected?'on':''">
                      <c-Input :placeholder="'부서를 입력해주세요'" v-model="itemD2.treeName" :name="'treerow'" @click.stop.prevent=""/>
                      <div class="btn-group">
                        <c-btn01 :btnText="'삭제'" :btn-show="true" class="sm" @click.stop.prevent="onClickDelRow(itemD2)"/>
                        <c-btn02 :btnText="'추가'" :btn-show="true" class="sm" @click.stop.prevent="onClickAddRow(itemD2)"/>
                        <c-btnLine01 :btnText="'하위추가'" :btn-show="true" class="sm" @click.stop.prevent="onClickAddChild(itemD2)"/>
                      </div>
                    </v-list-item>
                  </template>
                  <!--depth3 -->
                  <v-list-group v-for="(itemD3, seqD3) in filterArray(itemD2.idx)">
                    <template v-slot:activator="{ props }">
                      <v-list-item v-bind="props" :class="itemD3.selected?'on':''">
                        <c-Input :placeholder="'부서를 입력해주세요'" v-model="itemD3.treeName" :name="'treerow'" @click.stop.prevent=""/>
                        <div class="btn-group">
                          <c-btn01 :btnText="'삭제'" :btn-show="true" class="sm" @click.stop.prevent="onClickDelRow(itemD3)"/>
                          <c-btn02 :btnText="'추가'" :btn-show="true" class="sm" @click.stop.prevent="onClickAddRow(itemD3)"/>
                          <c-btnLine01 :btnText="'하위추가'" :btn-show="true" class="sm" @click.stop.prevent="onClickAddChild(itemD3)"/>
                        </div>
                      </v-list-item>
                    </template>
                    <!--depth4-->
                    <v-list-group v-for="(itemD4, seqD4) in filterArray(itemD3.idx)">
                      <template v-slot:activator="{ props }">
                        <v-list-item
                          v-bind="props"
                          :class="itemD4.selected?'on':''"
                        >
                          <c-Input :placeholder="'부서를 입력해주세요'" v-model="itemD4.treeName" :name="'treerow'" @click.stop.prevent=""/>
                          <div class="btn-group">
                            <c-btn01 :btnText="'삭제'" :btn-show="true" class="sm" @click.stop.prevent="onClickDelRow(itemD4)"/>
                            <c-btn02 :btnText="'추가'" :btn-show="true" class="sm" @click.stop.prevent="onClickAddRow(itemD4)"/>
                          </div>
                        </v-list-item>
                      </template>
                    </v-list-group>
                    <!--depth4 -->
                  </v-list-group>
                  <!--depth3 -->
                </v-list-group>
                <!--depth2 -->
              </v-list-group>
              <!--depth1 -->
            </v-list>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 v-bind="btnSave" @click.prevent.stop="handleForm"/>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar" :timeout="1000"> {{ snackText }} </v-snackbar>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import CompanyService from "@/services/company.service";
import utils from "@/common/Utils";
import {useOrganizationalManagementStore} from "@/store/organizationalManagement";
import {storeToRefs} from "pinia";
import _ from 'lodash';
import OrganizationService from "@/services/organization.service";
import * as yup from "yup";

//popup close logic
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    storeRef.popupIdx.value = null,
    emit('close', value)
  }
})

//store
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)

//ui
const btnSave = {
  btnText: '확인',
}

//bind
const snackbar = ref(false)
const snackText = ref('')
const items = ref([])
const popupTitle = ref(storeRef.popupType.value == 'dept'?'부서':'직책')
const listOpen = ref(['open'])

//hook
onBeforeUpdate (()=>{
  items.value = []
})

onUpdated (() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        idx: 1,
        parentIdx: 0,
        treeName: 'A경영지원본부',
        treeLevel: 1,
        selected: false,
      },
      {
        idx: 2,
        parentIdx: 1,
        treeName: 'A경영지원본부2',
        treeLevel: 2,
        selected: false,
      },
      {
        idx: 3,
        parentIdx: 1,
        treeName: 'A경영지원본부3',
        treeLevel: 2,
        selected: false,
      },
    ]
  }
  else {
    if (!storeRef.popupIdx.value)
      return;

    if (storeRef.popupIdx.value) {
      let params = {idx: 0, companyIdx: storeRef.popupIdx.value, type: storeRef.popupType.value,}
      CompanyService.getCompanyTree(params).then(
        (response) => {
          items.value = response.result
          if(response.result.length == 0) {
            let newObj = {parentIdx: 0, treeName: '', treeLevel: 1, companyIdx: storeRef.popupIdx.value, type: storeRef.popupType.value,}
            CompanyService.insertCompanyTree(newObj).then(
              (response) => {
                items.value.push(response.result)
              },
              (error) => {
                alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
                if (error.status == 401 || error.status == 403)
                  utils.movePage('/login')
              }
            )
          }
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 401 || error.status == 403)
            utils.movePage('/login')
        }
      )
    }
  }
})
const filterArray = computed(()=>(param) => {return _.filter(items.value, {parentIdx: param}) })

function onClickDelRow(item){
  const childCnt = _.filter(items.value, {parentIdx: item.idx}).length
  if(childCnt > 0){
    snackText.value = '하위 항목이 존재하여 삭제가 불가능합니다'
    snackbar.value = true
    return
  }

  let newObj = {idx: item.idx}
  CompanyService.deleteCompanyTree(newObj).then(
    (response) => {
      let resultArr = _.reject(items.value, item)
      items.value = resultArr
      /*
      let params = {idx: 0, companyIdx: storeRef.popupIdx.value, type: storeRef.popupType.value,}
      CompanyService.getCompanyTree(params).then(
        (response) => {
          items.value = response.result
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 401 || error.status == 403)
            utils.movePage('/login')
        }
      )
       */
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickAddRow(item){
  let newObj = {parentIdx: item.parentIdx, treeName: '', treeLevel: item.treeLevel, companyIdx: storeRef.popupIdx.value, type: storeRef.popupType.value,}
  CompanyService.insertCompanyTree(newObj).then(
    (response) => {
      let objIndex = _.findIndex(items.value, item)
      let beforeArr = _.slice(items.value, 0, objIndex+1)
      let newObject = response.result
      let postArr = _.slice(items.value, objIndex+1)
      let resultArr = _.concat(beforeArr, newObject, postArr)
      items.value = resultArr
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function onClickAddChild(item){
  let newObj = {parentIdx: item.idx, treeName: '', treeLevel: item.treeLevel+1, companyIdx: storeRef.popupIdx.value, type: storeRef.popupType.value,}
  CompanyService.insertCompanyTree(newObj).then(
    (response) => {
      let objIndex = _.findIndex(items.value, item)
      let beforeArr = _.slice(items.value, 0, objIndex+1)
      let newObject = response.result
      let postArr = _.slice(items.value, objIndex+1)
      let resultArr = _.concat(beforeArr, newObject, postArr)
      items.value = resultArr
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if (error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function handleForm(){
  if(import.meta.env.MODE == 'development') {
    emit('close', '')
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    let params = {
      companyIdx: storeRef.popupIdx.value,
      type: storeRef.popupType.value,
      idx:[],
      treeName:[],
      treeLevel:[],
      parentIdx:[]
    }
    items.value.forEach(function(row) {
      params['idx'].push(row.idx)
      params['treeName'].push(row.treeName)
      params['treeLevel'].push(row.treeLevel)
      params['parentIdx'].push(row.parentIdx)
    });

    CompanyService.insertUpdateCompanyTree(params).then(
      (response) => {
        snackText.value = 'success'
        snackbar.value = true
        emit('close', '')
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}
</script>


<style lang="scss">

</style>
