<template>
  <div class="tit-group">
    <h2 class="tit">조직관리</h2>
  </div>
  <div class="tab-group">
    <v-tabs v-model="tab">
      <v-tab value="tabCont1" @click.stop="onClickTab(1)">회사 관리</v-tab>
      <v-tab value="tabCont2" @click.stop="onClickTab(2)">회원 관리</v-tab>
      <v-tab value="tabCont3" @click.stop="onClickTab(3)">관리자 관리</v-tab>
      <v-tab value="tabCont4" @click.stop="onClickTab(4)">시스템 알림</v-tab>
    </v-tabs>
  </div>
  <div class="content-box bg-none">
    <v-tabs-window v-model="tab">
      <v-tabs-window-item value="tabCont1">
        <c-pageTabCont1/>
      </v-tabs-window-item>
      <v-tabs-window-item value="tabCont2">
        <c-pageTabCont2 v-if="!tabView2"/>
        <c-pageTabCont21 v-if="tabView2"/>
      </v-tabs-window-item>
      <v-tabs-window-item value="tabCont3">
        <c-pageTabCont3 v-if="!tabView3"/>
        <c-pageTabCont31 v-if="tabView3"/>
      </v-tabs-window-item>
      <v-tabs-window-item value="tabCont4">
        <c-pageTabCont4 v-if="!tabView4"/>
        <c-pageTabCont41 v-if="tabView4"/>
      </v-tabs-window-item>
    </v-tabs-window>
  </div>
</template>

<style lang="scss">
</style>
<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { useOrganizationalManagementStore } from "@/store/organizationalManagement";
import { storeToRefs } from 'pinia'

//store
const route = useRoute()
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)
store.moveTab('tabCont1')
const tab = storeRef.tabName
const tabView2 = storeRef.isTabView2
const tabView3 = storeRef.isTabView3
const tabView4 = storeRef.isTabView4

function onClickTab(type){
  store.moveTab('tabCont'+type)
}
</script>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
