<template>
  <div class="img-add-group">
    <div class="btn-img-upload">
      <v-file-input
        label="File input w/ chips"
        chips
        multiple
        accept="image/png, image/jpeg"
      >추가</v-file-input>
    </div>
    <div class="img-add-group">
      <div class="item">
        <v-btn @click="isShowDialog=true">
          <img src="https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg">
        </v-btn>
        <v-btn class="btn-img-del"/>
      </div>
      <div class="item">
        <v-btn @click="isShowDialog=true">
          <img src="https://cdn.vuetifyjs.com/images/carousel/sky.jpg">
        </v-btn>
        <v-btn class="btn-img-del"/>
      </div>
      <div class="item">
        <v-btn @click="isShowDialog=true">
          <img src="https://cdn.vuetifyjs.com/images/carousel/skyd.jpg">
        </v-btn>
        <v-btn class="btn-img-del"/>
      </div>
    </div>
  </div>
  <DialogImgView v-model="isShowDialog" @close="isShowDialog=false"/>
</template>

<script setup lang="ts">
import { ref } from 'vue';
const isShowDialog = ref(false)
</script>


<style lang="scss">
.img-add-group{
  .btn-img-upload{
    .v-input{
      display:block;
      .v-input__prepend{
        margin:0;
        .v-icon{
          display:block;
          width:auto;
          padding:0 2rem;
          height:4rem;
          line-height:4rem;
          text-align:center;
          color:var(--color-white);
          background: var(--color-main);
          border-radius:.5rem;
          opacity:1;
          &:before{
            content:"추가";
            font-size:1.5rem;
          }
        }
      }
      .v-field{
        .v-field__overlay,
        .v-field__loader,
        .v-label,
        .v-field__outline{
          display:none;
        }
        .v-chip{
          font-size:1.2rem
        }
      }
      .v-input__details{
        display:none;
      }
      .v-field__input{
        padding:.8rem 0;
        min-height:auto;
      }
      .v-input__control{
        //display: inline-block;
        display:none;
      }
      button.v-btn{
        display:block;
        width:5rem;
        height:5rem;
        line-height:5rem;
        text-align:center;
        color:var(--color-white);
        background: var(--color-gray4);
      }
    }
  }
  .img-add-group{
    display:flex;
    flex-wrap: wrap;
    .item{
      position:relative;
      width:12rem;
      height:8rem;
      overflow:hidden;
      margin-right:1rem;
      margin-top:1rem;
      background:#DEE2EA url("/src/assets/img/ico-img.svg") center no-repeat;
      *{
        object-fit: contain;
        width:12rem;
        height:8rem;
      }
      .v-btn{
        background:transparent;
        &.btn-img-del{
          display:block;
          position:absolute;
          top:0;
          right:0;
          width:2.4rem;
          height:2.4rem;
          min-width:auto;
          padding:0;
          box-shadow:none;
          background: url("/src/assets/img/ico-img-close.svg") center no-repeat;
          *{
            width:2.4rem;
            height:2.4rem;
          }
        }
      }
    }
  }
}
</style>
