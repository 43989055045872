<template>
  <div class="content-box bg-none sc-page-wrap">
    <div class="top-cont">
      <div class="switch-group">
        <div class="col">
          <div class="tit">안전관찰</div>
          <v-switch :model-value="true" inset hide-details/>
        </div>
        <div class="col ty01">
          <div class="tit">안전리더십</div>
          <v-switch :model-value="true" inset hide-details/>
        </div>
        <div class="col ty02">
          <div class="tit">시정조치</div>
          <v-switch :model-value="true" inset hide-details/>
        </div>
      </div>
      <div>
        <div class="selected-group3">
          <v-select
            v-model="calenderModel"
            :items="CalenderItems"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="content-inner-box">
      <div class="list-group4 scrollbar">
        <div class="box" v-for="n in 7" :key="n" >
          <div class="txt-date">2024.02.12</div>
          <div class="list-inner">
            <div class="header-group">
              <div class="col">제목</div>
              <div class="col">단계</div>
              <div class="col">진행</div>
              <div class="col">기간</div>
            </div>
            <ul>
              <!--
              리스트 클래스(li에 기입):
              sort01 안전관찰
              sort02 안전리더십
              sort03 시정조치
              --
              state01 진행전
              state02 임박
              state03 진행전
              state04 완료
              -->
              <li class="sort01 state01" >
                <v-btn  @click="$router.push('/safetyLeadershipRun')">
                  <span class="t1">안전관찰 A</span>
                  <span class="t2">1회차</span>
                  <span class="t3">진행전</span>
                  <span class="t4">2024-03-11 ~ 2024.03.18</span>
                </v-btn>
              </li>
              <li class="sort01 state02" >
                <v-btn  @click="$router.push('/safetyLeadershipRun')">
                  <span class="t1">평택지사 안전관찰A</span>
                  <span class="t2">1단계</span>
                  <span class="t3">임박</span>
                  <span class="t4">2024-03-11 ~ 2024.03.18</span>
                </v-btn>
              </li>
              <li class="sort02 state03" >
                <v-btn  @click="$router.push('/safetyLeadershipRun')">
                  <span class="t1">안전리더십 A</span>
                  <span class="t2">2단계</span>
                  <span class="t3">지남</span>
                  <span class="t4">2024-03-11 ~ 2024.03.18</span>
                </v-btn>
              </li>
              <li class="sort03 state04">
                <v-btn  @click="$router.push('/safetyLeadershipRun')">
                  <span class="t1">시정조치</span>
                  <span class="t2">2단계</span>
                  <span class="t3">완료</span>
                  <span class="t4">2024-03-11 ~ 2024.03.18</span>
                </v-btn>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
const CalenderItems = ['주별', '월별']
const calenderModel = ref('주별')
</script>


<style lang="scss">

</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
