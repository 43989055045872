<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="sort-group">
        <div class="sort-row">
          <div class="wl">
            <div class="txt-total">Total: {{ totalCnt }}</div>
            <c-InputSearch :placeholder="'검색어를 입력하세요. (,로 다중검색)'" v-model="inputSearch" @search-word="searchWord"/>
          </div>
        </div>
        <div class="sort-row">
          <div class="wl">
            <c-btnLine01 :btnText="'선택다운로드'" @click.stop="excelDownload('check')"/>
            <c-btnLine01 :btnText="'전체다운로드'" @click.stop="excelDownload('all')"/>
          </div>
          <div class="wr">
            <c-btnMenu01 v-bind="btnExcelUpload" @on-click-child="onClickChild"/>
            <c-btn01 :btnText="'개별 등록'" @click="onClickViewPage2"/>
            <input type="file" :ref="el => { excelFile = el }" :accept="'*'" @change="excelUpload" hidden/>
          </div>
        </div>
      </div>
      <!-- v-data-table 가져옴-->
      <div class="tbl-group size-custom2">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          :items-per-page-options="footerOptions"
          return-object
          show-select
          @click:row="onClickViewPage2"
        >
        </v-data-table>
      </div>
      <div class="bottom-btn-group">
        <div class="wl"></div>
        <div class="wr">
          <c-btnLine01 :btnText="'선택삭제'" @click.stop="deleteItems()"/>
        </div>
      </div>
    </div>
  </div>
  <v-snackbar v-model="snackbar" :timeout="1000"> {{ snackText }} </v-snackbar>
</template>
<style lang="scss" >
</style>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useOrganizationalManagementStore } from "@/store/organizationalManagement";
import MemberService from "@/services/member.service";
import utils from "@/common/Utils";
import * as XLSX from 'xlsx'
import _ from 'lodash';

//store
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)

//ui
const btnExcelUpload = {
  btnText: '엑셀 등록',
  setItems: [
    { title: '샘플' },
    { title: '업로드' }
  ],
}

//bind
const excelFile = ref()
const snackbar = ref(false)
const snackText = ref('')
const totalCnt = ref(0)
const inputSearch = ref(null)
const selected = ref([])
const headers = ref([
  { title: '순번', key: 'idx' },
  { title: '아이디', key: 'memberId' },
  { title: '이름', key: 'memberName'},
  { title: '부서 ', key: 'deptName' },
  { title: '직책 ', key: 'posName'},
  { title: '교대조 ', key: 'shiftYn' },
  { title: '등록일 ', key: 'regDate' },
  { title: '사용여부', key: 'useYn' },
])
const items = ref([])
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        idx: 1,
        memberId: 'test1',
        memberName: '김봉',
        deptName: '직책',
        posName: '부서',
        shiftYn: 'Y',
        regDate: '2024-10-10',
      },
      {
        idx: 2,
        memberId: 'test2',
        memberName: '김봉',
        deptName: '직책',
        posName: '부서',
        shiftYn: 'Y',
        regDate: '2024-10-10',
      },
      {
        idx: 3,
        memberId: 'test3',
        memberName: '김봉',
        deptName: '직책',
        posName: '부서',
        shiftYn: 'Y',
        regDate: '2024-10-10',
      },
    ]
  }
  else{
    searchWord('')
  }
})

//function
function searchWord(word){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    keyword: word,
    companyIdx:companyIdx
  }

  MemberService.getMemberList(params).then(
    (response) => {
      items.value = response.result
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function deleteItems(){
  if(import.meta.env.MODE == 'development') {
    selected.value.forEach(function (item){
      const idx = items.value.findIndex(function(tempItem) {return tempItem.idx === item.idx})
      if (idx > -1) items.value.splice(idx, 1)
    })

    snackText.value = 'success'
    snackbar.value = true
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    if(!companyIdx)
      utils.movePage('/login')

    let deleteArr = []
    selected.value.forEach(function (item){
      deleteArr.push(item.idx)
    })
    let params = {
      companyIdx:companyIdx,
      memberArr: deleteArr,
    }

    MemberService.deleteMember(params).then(
      (response) => {
        if(response.success) {
          selected.value.forEach(function (item){
            const idx = items.value.findIndex(function(tempItem) {return tempItem.idx === item.idx})
            if (idx > -1) items.value.splice(idx, 1)
          })

          snackText.value = 'success'
          snackbar.value = true
        }
        else
          alert(response.message)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function onClickViewPage2(event, row){
  if(row)
    storeRef.viewIdx.value = row.item.idx
  else
    storeRef.viewIdx.value = null
  store.moveTabView('tabCont2')
}

function onClickChild(item){
  if(item == '샘플'){
    excelDownload('sample')
  }
  else if(item == '업로드'){
    excelFile.value.click()
  }
}

/**
 for (var i = 0; i < excelData.length; i++) {
  만약 payload에 필요없는 내용이 있다면
   delete excelData[i].칼럼명
  내용이 비어있으면 엑셀파일로 출력 시 빈 칸도 없이 아예 사라짐. 따라서 내용이 없는데 칼럼과 빈 칸이 필요한 경우 추가함
   if (excelData[i].description === null) {
          excelData[i].description = []
          excelData[i].description.push(' ')
        }
}
 */
function excelDownload(type){
  let excelData = JSON.parse(JSON.stringify(items.value))
  if(type == 'check') {
    excelData = JSON.parse(JSON.stringify(selected.value))
  }

  //const dataWS = XLSX.utils.json_to_sheet(renameKeys(mapping, excelData))
  const dataWS = XLSX.utils.json_to_sheet(excelData)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, dataWS, 'Sheet1')
  XLSX.writeFile(wb, 'member_sample.xlsx')
}

function excelUpload(){
  const fileInfo = excelFile.value.files[0];

  let reader = new FileReader();
  reader.readAsArrayBuffer(fileInfo)
  reader.onload = function () {
    const fileData = reader.result;
    const wb = XLSX.read(fileData, { type: "binary" });
    const toJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
    //업로드
    let companyIdx = localStorage.getItem('companyIdx')
    let succeseCnt = 0
    toJson.forEach((item:any, key)=>{
      let params = {
        companyIdx:companyIdx,
        memberId: item.memberId,
        password: item.password,
        deptIdx: item.deptIdx,
        posIdx: item.posIdx,
        deptName: item.deptName,
        posName: item.posName,
        telNo: item.telNo,
        email: item.email,
        useYn: item.useYn,
        adminYn: item.adminYn,
        headYn: item.headYn,
        shiftYn: item.shiftYn,
        passwordYn: item.passwordYn,
      }

      MemberService.insertMember(params).then(
        (response) => {
          if(response.success)
            succeseCnt++
          else
            alert(response.message)

          if(key+1 >= toJson.length){
            snackbar.value = true
            snackText.value = 'uploaded : '+succeseCnt
          }
        },
        (error) => {
          alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
          if (error.status == 401 || error.status == 403)
            utils.movePage('/login')
        }
      )
    })
    excelFile.value.value = ''
  };
}

/** 엑셀 다운로드 시 키값 rename */
const renameKeys = (mapping, objArr) => {
  const renamedObjArr = []
  for (const obj of objArr) {
    const renamedObj = {}
    for (const [before, after] of Object.entries(mapping) as Entries<typeof mapping>) {
      if (obj[before]) {
        renamedObj[after] = obj[before]
      }
    }
    renamedObjArr.push(renamedObj)
  }
  return renamedObjArr
}

/** 엑셀 다운로드 시 rename될 mapping 양식 */
const mapping = {
  idx:'idx',
  memberId:'아이디',
  memberName:'이름',
  deptName:'부서',
  posName:'직책',
  shiftYn:'교대조여부',
  regDate:'등록일'
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];
</script>

