<template>
  <div class="input-search-group">
  <v-sheet class="mx-auto" width="400">
    <v-text-field
      clearable
      v-model="searchData"
      :placeholder="placeholder"></v-text-field>
    <v-menu
      transition="slide-y-transition"
      location="right top"
    >
      <template v-slot:activator="{ props }">
        <v-btn  v-bind="props" class="btn-search">
          <span class="hide-txt">검색</span>
        </v-btn>
      </template>
      <v-list class="srch-menu-list">
        <v-list-item
          v-for="(item, i) in searchList"
          :key="i"
        >
          <v-list-item-title>
            <button type="button" @click="move(item.link)">
              {{ item.title }}
            </button>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-sheet>
  </div>
</template>
<style lang="scss"> //scoped
.srch-menu-list{
  margin-left: 1rem;
}
</style>
<script lang="ts" setup>
import { ref } from 'vue'
import router from '@/router';
const props = defineProps(['placeholder'])
const searchData = ref('')
const searchList = [
  { title: '리스트1', link:''},
  { title: '리스트2', link:''},
  { title: '리스트3', link:''},
]
function move(page){
  router.push({ path: page })
}
</script>
