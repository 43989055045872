<template>
  <div class="tit-group">
    <h2 class="tit">고객사관리</h2>
  </div>
  <div class="content-box">

    <div class="sort-group">
      <div class="sort-row">
        <div class="wl">
          <div class="txt-total" >Total: {{ totalCnt }}</div>
          <c-InputSearch :type="'text'" :placeholder="'검색어를 입력하세요'" v-model="inputSearch" @search-word="searchWord"/>
        </div>
        <div class="wr">
          <c-btn01 :btnText="'신규등록'" @click="showPopup(0)"/>
        </div>
      </div>
    </div>
    <!-- v-data-table 가져옴-->
    <div class="tbl-group size-custom1">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page-options="footerOptions" >
        <template v-slot:item.clientName="{ item }">
          <div class="ell01" @click.stop="onClickGoList(item.idx, item.clientName)">
            {{item.clientName}}
          </div>
        </template>
        <template v-slot:item.regDate="{ item }">
          <div class="ell01" >
            {{ dayjs(item.regDate).format('YYYY-MM-DD') == 'Invalid Date' ? '' : dayjs(item.regDate).format('YYYY-MM-DD') }}
          </div>
        </template>
        <template v-slot:item.modDate="{ item }">
          <div class="ell01" >
            {{ dayjs(item.modDate).format('YYYY-MM-DD') == 'Invalid Date' ? '' : dayjs(item.modDate).format('YYYY-MM-DD') }}
          </div>
        </template>
        <template v-slot:item.btnModify="{ item }">
          <c-btnLine01 :btnText="'수정'" class="sm" @click.stop="showPopup(item.idx)"/>
        </template>
      </v-data-table>
    </div>

    <c-cm-dialog01 v-model="isShowDialog" @close="closePopup"/>
  </div>
</template>
<style lang="scss" scoped>
.tbl-group .v-table .v-table__wrapper > table > tbody > tr > td:nth-child(2){width:30%;}
</style>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import dayjs from "dayjs";
import utils from "@/common/Utils";
import OrganizationService from "@/services/organization.service";
import {useOrganizationalManagementStore} from "@/store/organizationalManagement";
import {storeToRefs} from "pinia";

//store
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)

//ui bind
const isShowDialog = ref(false)
const totalCnt = ref(0)
const inputSearch = ref(null)
const headers = [
  { title: '순번', key: 'idx' },
  { title: '고객사', key: 'clientName'},
  { title: '최대 그룹 수 ', key: 'maxGroup' },
  { title: '최대 사용자 수 ', key: 'maxUser' },
  { title: '등록일 ', key: 'regDate' },
  { title: '수정일 ', key: 'modDate' },
  { title: '수정', key: 'btnModify' },
]
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]
let items = ref([])

//hook
onMounted(() => {
  localStorage.removeItem('clientIdx')
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        idx: 1,
        clientName: '고객사 이름이 들어갑니다.고객사 이름이 들어갑니다.고객사 이름이 들어갑니다.',
        maxGroup: 5,
        maxUser: 500,
        regDate: '2024-10-10',
        modDate: '2024-10-10',
        btnModify: true,
      },
      {
        idx: 2,
        clientName: '고객사 이름test1234',
        maxGroup: 5,
        maxUser: 500,
        regDate: '2024-10-10',
        modDate: '2024-10-10',
        btnModify: true,
      },
      {
        idx: 3,
        clientName: '고객사 이름test1234',
        maxGroup: 5,
        maxUser: 500,
        regDate: '2024-10-10',
        modDate: '2024-10-10',
        btnModify: true,
      },
      {
        idx: 4,
        clientName: '임팩터스',
        maxGroup: 5,
        maxUser: 500,
        regDate: '2024-10-10',
        modDate: '2024-10-10',
        btnModify: true,
      }
    ]
  }
  else{
    searchWord('')
  }
})

//function
function searchWord(word){
  let params = {
    searchKeyword: word,
  }

  OrganizationService.getClientList(params).then(
    (response) => {
      items.value = response.result
      totalCnt.value = response.result.length
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function showPopup(idx){
  storeRef.popupIdx.value = idx
  isShowDialog.value = true
}

function closePopup(type){
  isShowDialog.value = false
  if(type == 'refresh')
    searchWord(inputSearch.value)
}

function onClickGoList(idx, name){
  localStorage.setItem('clientIdx', idx)
  storeRef.user.value.clientName = name
  utils.movePage('/groupManagement')
}

</script>

<route lang="yaml">
meta:
  layout: layoutGnb
</route>
