<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="90rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">평가자 리스트</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <div class="pop-cont02">
          <div class="form-group col-ty">
            <div class="form-row ty05">
              <div class="txt-tit ty04">대상 자동매칭</div>
              <div class="row">
                <c-btn01 :btnText="'상위 자동매칭'" class="sm" @click="dialogAutoMatchingModel=true"/>
                <c-btn01 :btnText="'하위 자동매칭'" class="sm" @click="dialogAutoMatchingModel=true"/>
              </div>
            </div>
          </div>
        </div>
        <v-card-text>
          <div class="tbl-group size-custom10">
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer
              return-object
            >
              <template v-slot:item="{ item }" >
                <tr class="v-data-table__tr">
                  <td>
                    <div class="txt-group">
                      <span class="t1">{{item.Appraiser}}</span>
                      <span class="t2">{{item.AppraiserPart}}</span>
                    </div>
                  </td>
                  <td>
                    <div class="txt-group">
                      <span class="t1">{{item.Target}}</span>
                      <span class="t2">{{item.TargetPart}}</span>
                    </div>
                  </td>
                  <td>
                    <BtnIcon class="btn-ico-set" @click="DialogTargetAdd3Model=true"/>
                  </td>
                  <td>
                    <BtnIcon class="btn-ico-delete"/>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <div class="pop-cont03">
          <c-btnLine01 :btnText="'신규추가'" class="ty02" @click="DialogTargetAdd3Model=true"/>
        </div>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn02 btnText="취소"/>
          <c-btn01 btnText="저장"/>
        </v-card-actions>
      </v-card>
      <!--popup: 자동매칭-->
      <c-dialog v-model="dialogAutoMatchingModel"
                v-bind="dialogAutoMatchingBind"
                @close="dialogAutoMatchingModel=false"
      />
      <!--popup: 참여대상-->
      <DialogTargetAdd3 v-model="DialogTargetAdd3Model"
                        @close="DialogTargetAdd3Model=false"
      />
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Sortable } from 'sortablejs-vue3';
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
const headers = ref([
  { title: '평가자', key: 'Appraiser' },
  { title: '대상자', key: 'Target' },
  { title: '변경', key: 'Change'},
  { title: '삭제 ', key: 'Delete' }
])
const items = ref([
  {
    Appraiser: '김상두',
    AppraiserPart: '염소정제팀 / 관리감독자',
    Target: '이두나',
    TargetPart: '염소정제팀 / 관리감독자',
  },
  {
    Appraiser: '이창호',
    AppraiserPart: '염소정제팀염소정제팀 / 관리감독자관리감독자관리감독자',
    Target: '이두나',
    TargetPart: '염소정제팀 / 관리감독자',
  },
  {
    Appraiser: '김상두',
    AppraiserPart: '염소정제팀 / 관리감독자',
    Target: '이두나',
    TargetPart: '염소정제팀 / 관리감독자',
  },
  {
    Appraiser: '이창호',
    AppraiserPart: '염소정제팀염소정제팀 / 관리감독자관리감독자관리감독자',
    Target: '이두나',
    TargetPart: '염소정제팀 / 관리감독자',
  }
])
const dialogAutoMatchingModel = ref(false)
const DialogTargetAdd3Model = ref(false)
const dialogAutoMatchingBind = {
  dialogTit: '자동매칭',
  dialogText: '설정된 대상자가 모두 초기화되고 다시 설정됩니다.',
  btn1Text: '취소',
  btn1Show: true,
  btn2Text: '확인',
  btn2Show: true,
}
</script>


<style lang="scss" scoped>
</style>
