import api from "./api";
import Utils from "@/common/Utils";

class BbsService {
  getBbs(params) {
    return api
      .post("/bbs/getBbs", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  getBbsList(params) {
    return api
      .post("/bbs/getBbsList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  insertBbs(params) {
    let apiUrl = '/bbs/insertBbs'
    if(params['idx'])
      apiUrl = '/bbs/updateBbs'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  deleteBbs(params) {
    let apiUrl = '/bbs/deleteBbs'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  copyBbs(params) {
    let apiUrl = '/bbs/copyBbs'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getBehavior(params) {
    return api
      .post("/bbs/getBehavior", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getBehaviorList(params) {
    return api
      .post("/bbs/getBehaviorList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertBehavior(params) {
    let apiUrl = '/bbs/insertBehavior'
    if(params['idx'])
      apiUrl = '/bbs/updateBehavior'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  deleteBehavior(params) {
    let apiUrl = '/bbs/deleteBehavior'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getBehaviorCategory(params) {
    return api
      .post("/bbs/getBehaviorCategory", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getBehaviorCategoryList(params) {
    return api
      .post("/bbs/getBehaviorCategoryList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertBehaviorCategory(params) {
    let apiUrl = '/bbs/insertBehaviorCategory'
    if(params['idx'])
      apiUrl = '/bbs/updateBehaviorCategory'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
  deleteBehaviorCategory(params) {
    let apiUrl = '/bbs/deleteBehaviorCategory'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  copyBehavior(params) {
    let apiUrl = '/bbs/copyBehavior'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new BbsService();
