<template>
  <div class="content-wrap">
    <NaviBox/>
    <div class="tit-group02">
      <h2 class="tit">모니터링 상세설정</h2>
    </div>
    <div class="content-box">
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">제목<span class="imp">*</span></div>
          <div class="row">
            <c-Input :placeholder="'제목을 입력하세요'" v-model="monitoringTitle" :name="monitoringTitle" class="size04"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">오픈일<span class="imp">*</span></div>
          <div class="row">
            <c-InputDate v-model="startDate" :name="'startDate'" :enableTimePicker="false" readonly="" />
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty02">
          <div class="txt-tit">종료일<span class="imp">*</span></div>
          <div class="row">
            <c-InputDate v-model="endDate" :name="'endDate'" :enableTimePicker="false" readonly="" />
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">관찰주기</div>
          <div class="row">
            <Select v-bind="periodTypeSelect" v-model="periodType" :name="periodType"/>
            <v-btn class="txt03"  @click="DialogCycleDetailModel=true">상세</v-btn>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty02">
          <div class="txt-tit">주기시작요일</div>
          <div class="row">
            <Select v-bind="periodStartDaySelect"v-model="periodStartDay" :name="periodStartDay"/>
            <v-btn class="txt03">전체주기</v-btn>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">필수관찰횟수</div>
          <div class="row">
            <Select v-bind="periodCntSelect" v-model="periodCnt" :name="periodCnt"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">중복 관찰 허용</div>
          <div class="row">
            <v-switch v-model="duplicateYn" :name="duplicateYn" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">안전대화 필수저장</div>
          <div class="row">
            <v-switch v-model="timelineYn" :name="timelineYn" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">참여대상</div>
          <div class="row">
            <c-btn01 :btnText="'추가'"/>
            <v-btn class="txt03">{{targetCnt}}</v-btn>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">Audit참여대상</div>
          <div class="row">
            <c-btn01 :btnText="'추가'"/>
            <v-btn class="txt03">{{ auditTargetCnt }}</v-btn>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">관찰 장소</div>
          <div class="row">
            <c-btn01 :btnText="'추가/변경'" @click="DialogCategoryStatus=true"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">보고서발송일</div>
          <div class="row">
            <Select v-bind="reportDaySelect" v-model="reportDay" :name="'reportDay'"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">보고서자동발송</div>
          <div class="row">
            <v-switch v-model="reportAutoYn" :name="'reportAutoYn'" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row ty01">
          <div class="txt-tit">변경 이력</div>
          <div class="row">
            <v-btn class="txt03" @click="DialogChangeHistoryModel=true">{{ changeCnt }}</v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box ty01">
      <div class="sort-group">
        <div class="sort-row">
          <div class="wl">
            <div class="txt04">행동</div>
          </div>
          <div class="wr">
            <c-btnLine01 :btnText="'불러오기'" @click="DialogCallActionModel=true"/>
            <c-btn01 :btnText="'추가'"/>
          </div>
        </div>
      </div>
      <div class="sortable-group">
        <div class="sortable-header">
          <div class="col">노출순서</div>
          <div class="col">행동</div>
          <div class="col">삭제</div>
        </div>
        <Sortable
        :list="behaviorItems"
        item-key="id"
        tag="div"
        class="sortable-cont"
      >
        <template #item="{element, index}">
          <div class="draggable" :key="element.behaviorTitle">
            <div class="col" aria-hidden="true">
              <img src="@/assets/img/format-align-justify.svg" alt="">
            </div>
            <div class="col">
              {{ element.behaviorTitle }}
            </div>
            <div class="col">
              <c-btn02 :btnText="'삭제'" class="sm" @click="DialogActionDeleteModel=true"/>
            </div>
          </div>
        </template>
      </Sortable>
      </div>
    </div>
    <div class="bottom-btn-group">
      <div class="wl">
        <c-btnLine01 :btnText="'목록'"/>
      </div>
      <div class="wr">
        <c-btn02 :btnText="'미리보기'" @click="DialogPreviewModel=true"/>
        <c-btn02 :btnText="'삭제'" @click="DialogDeleteModel=true"/>
        <c-btn01 :btnText="'등록/수정'"/>
      </div>
    </div>
  </div>
  <!--popup: 관찰장소-->
  <DialogObservationPlace
    v-model="DialogCategoryStatus"
    :popTitle="'관찰 장소'"
    :popSubTitle="'모니터링 시 선택 할 수 있는 관찰 장소 목록입니다.'"
    @close="DialogCategoryStatus=false"/>
  <!--popup: 주기별 상세날짜보기-->
  <c-dialog v-model="DialogCycleDetailModel"
            v-bind="DialogCycleDetailBind"
            @close="DialogCycleDetailModel=false"
  />
  <!--popup: 변경이력-->
  <c-dialog v-model="DialogChangeHistoryModel"
            v-bind="DialogChangeHistoryBind"
            @close="DialogChangeHistoryModel=false"
  />
  <!--popup: 행동불러오기-->
  <DialogCallAction v-model="DialogCallActionModel"
                    :pop-title="'행동불러오기'"
            @close="DialogCallActionModel=false"
  />
  <!--popup: 미리보기-->
  <DialogPreview v-model="DialogPreviewModel"
                    :pop-title="'미리보기'"
                    @close="DialogPreviewModel=false"
  />
  <!--popup: 행동삭제-->
  <c-dialog v-model="DialogActionDeleteModel"
            v-bind="DialogActionDeleteBind"
            @close="DialogActionDeleteModel=false"
  />
  <!--popup: 삭제-->
  <c-dialog v-model="DialogDeleteModel"
            v-bind="DialogDeleteBind"
            @close="DialogDeleteModel=false"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useBbsStore } from "@/store/bbs";
import {Form} from "vee-validate";
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";
import * as yup from "yup";
import _ from 'lodash';
import { Sortable } from "sortablejs-vue3";

//bind
const checkbox1 = ref(true)
const DialogCategoryStatus = ref(false)
const DialogCycleDetailModel = ref(false)
const DialogChangeHistoryModel = ref(false)
const DialogCallActionModel = ref(false)
const DialogPreviewModel = ref(false)
const DialogActionDeleteModel = ref(false)
const DialogDeleteModel = ref(false)
const monitoringTitle = ref(false)
const startDate = ref(false)
const endDate = ref(false)
const periodType = ref(false)
const periodStartDay = ref(false)
const duplicateYn = ref(false)
const timelineYn = ref(false)
const targetCnt = ref('0')
const auditTargetCnt = ref('0')
const reportAutoYn = ref(false)
const changeCnt = ref('0')
const reportDay = ref(false)
const periodCnt = ref('0')

//ui
const periodTypeSelect = {
  select:  '1주',
  items: ['1주', '2주', '3주', '4주', '5주', '6주', '분기', '반기', '일년']
}
const periodStartDaySelect = {
  select:  '월요일',
  items: ['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일']
}
const periodCntSelect = {
  select:  '2회',
  items: ['1회', '2회', '3회']
}
const reportDaySelect = {
  select:  '5',
  items: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20','21', '22', '23', '24', '25', '26', '27', '28', '29', '30',]
}

const behaviorItems = [
  {
    behaviorTitle: '작업 전 안전체조 및 TBM 활동을 통한 위험예지훈',
  },
  {
    behaviorTitle: '중장비 안전운전 및 충돌 예방조치를 실시한다'
  },
]
const DialogCycleDetailBind = {
  dialogTit: '주기별 상세날짜 보기',
  dialogText: '1회 : 2024.02.01 ~ 2024.02.07<br>2회 : 2024.02.08 ~ 2024.02.15<br>3회 : 2024.02.16~ 2024.02.23',
  btn1Text: '확인',
  btn1Show: true,
}
const DialogChangeHistoryBind = {
  dialogTit: '변경 이력',
  dialogText: '생성 / admin / 2024.01.03 수정(제목) / admin / 2024.01.04<br>대상추가(3명) / admin / 2024.01.04<br>대상제거(1명) / admin / 2024.01.04<br>행동수정 / admin / 2024.01.04',
  btn1Text: '확인',
  btn1Show: true,
}
const DialogActionDeleteBind = {
  dialogTit: '변경 이력',
  dialogText: '삭제하시겠습니까?',
  btn1Text: '확인',
  btn1Show: true,
}
const DialogDeleteBind = {
  dialogTit: '변경 이력',
  dialogText: '삭제하시겠습니까?<br>삭제후에는 복구가 불가능합니다.',
  btn1Text: '확인',
  btn1Show: true,
}
</script>



<style lang="scss" scoped>
.sortable-group{
  .sortable-header ,.sortable-cont .draggable{
    .col:nth-child(1){flex-grow:0;width:15%;}
    .col:nth-child(2){justify-content:flex-start;text-align:left}
    .col:nth-child(3){flex-grow:0;width:15%;}
  }
}

</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
