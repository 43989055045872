<template>
  <div class="content-wrap am-page-wrap" :class="amPage">
    <div class="navi-area">
      <div class="m-navi-top">
        <c-btn01 :btnText="'모니터링 대시보드'" @click="$router.push('/actionManagement')"/>
      </div>
      <NaviBox/>
    </div>
    <div class="page3">
      <div class="tab-group ty02">
        <v-tabs
          v-model="tab"
        >
          <v-tab value="tabCont1">모니터링</v-tab>
          <v-tab value="tabCont2" >보고서</v-tab>
          <v-tab value="tabCont3">활동이력</v-tab>
        </v-tabs>
        <c-btnText01 :btnText="'QR코드 생성'" class="ty04" @click="DialogQrcordModel=true"/>
      </div>
      <div class="content-box bg-none tab-cont-wrap">
        <div class="tit-group02 mo-ty01">
          <div class="navi-btn">
            <v-btn @click="amListOpen"/>
          </div>
        </div>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="tabCont1">
            <monitoringTabCont1/>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont2">
            <monitoringTabCont2/>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont3">
            <monitoringTabCont3/>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
  </div>
  <!--popup: qrcord 생성-->
  <DialogQrcord v-model="DialogQrcordModel"
                 :pop-title="'QR코드'"
                 @close="DialogQrcordModel=false"
  />
  <!--popup: 임시저장 불러오기-->
  <c-dialog v-model="DialogDreftModel"
            v-bind="DialogDreftBind"
            @close="DialogDreftModel=false"
  />
</template>

<script setup lang="ts">

import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useOrganizationalManagementStore } from "@/store/organizationalManagement";
import { storeToRefs } from 'pinia'
const DialogQrcordModel = ref(false)
const DialogDreftModel = ref(true)
const route = useRoute()
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)
store.moveTab('tabCont1')
const tab = storeRef.tabName
const DialogDreftBind = {
  dialogTit: '임시 저장  불러오기',
  dialogText: '최종 제출되지 않고, 임시 저장된 모니터링이 존재합니다. <br>불러올까요? (저장 시점:2024.03.03 06:21:33) ',
  btn1Text: '불러오기',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
//모니터링 메뉴 열고 닫기
const amPage = ref('naviOff')
function amListOpen(){
  if(amPage.value == 'naviOff')
    amPage.value = 'naviOn'
  else
    amPage.value = 'naviOff'
}
</script>



<style lang="scss" scoped>
</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
