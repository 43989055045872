<template>
  <div class="tabCont">
    <div class="p-inner ty01">
      <div class="tit-group02">
        <h2 class="tit">수신 시정조치</h2>
      </div>
      <div class="content-box">
        <div class="tit-group02 inner-ty">
          <h2 class="tit">Total: 132</h2>
          <div class="filter-btn-group">
            <v-btn class="reset" :class="filterDiv1">필터초기화</v-btn>
            <v-btn class="filter" @click="filterOpen1" :class="filterDiv1">필터</v-btn>
          </div>
        </div>
        <div class="sort-group" :class="filterDiv1">
          <div class="sort-row">
            <div class="wl">
              <div class="txt-total">Total: 132</div>
              <c-InputSearch v-bind="InputSearch"/>
              <div class="date-sort">
                <c-InputDate :enableTimePicker="false" readonly="" />
                <div class="txt02">~</div>
                <c-InputDate :enableTimePicker="false" readonly=""/>
              </div>
              <div class="date-sort">
                <c-btn02 :btnText="'일주'" class="ty01"/>
                <c-btn02 :btnText="'한달'" class="ty01"/>
              </div>
              <Select v-bind="select01"/>
            </div>
          </div>
        </div>
        <div class="tbl-group size-custom5">
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page-options="footerOptions"
            return-object
          >
            <template v-slot:item="{ item }">
              <tr class="v-data-table__tr" @click="$router.push('/myBoardDetail')">
                <td>{{item.Number}}</td>
                <td>
                  <div class="ell01">
                    {{item.CorrectiveAction}}
                  </div>
                </td>
                <td>
                  <div :class="getColor(item.ActionDeadline)">
                    {{item.ActionDeadline}}
                  </div>
                </td>
                <td>{{item.Author}}</td>
                <td>{{item.Subject}}</td>

                <td>
                  <div :class="getColor2(item.Progress)">
                    {{item.Progress}}
                  </div>
                </td>
                <td>
                  <div class="dv1">
                    <div class="ico" :class="getColor2(item.Progress)">
                      {{item.Progress}}
                    </div>
                    <div class="ell01">
                      {{item.CorrectiveAction}}
                    </div>
                  </div>
                  <div class="dv2">
                    <div :class="getColor(item.ActionDeadline)">
                      {{item.ActionDeadline}}
                    </div>
                    &nbsp;/ {{item.Author}} / {{item.Subject}}
                  </div>
                </td>
                <td>{{item.RegistrationDate}}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
const headers = ref([
  { title: '순번', key: 'Number' },
  { title: '시정조치', key: 'CorrectiveAction' },
  { title: '조치기한', key: 'ActionDeadline'},
  { title: '작성자 ', key: 'Author' },
  { title: '대상자', key: 'Subject' },
  { title: '진행상황', key: 'Progress' },
  { title: '시정조치 / 조치기한 / 작성자 / 대상자', key: 'moDate' },
  { title: '등록일', key: 'RegistrationDate' },
])
const items = ref([
  {
    Number: '1',
    CorrectiveAction: '시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.',
    ActionDeadline: '2024-01-25',
    Author: '김명기',
    Subject: '마케팅팀',
    Progress: '진행중',
    RegistrationDate: '2024-01-25'
  },
  {
    Number: '2',
    CorrectiveAction: '시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.',
    ActionDeadline: '2024-01-23',
    Author: '김명기',
    Subject: '마케팅팀',
    Progress: '보류',
    RegistrationDate: '2024-01-25'
  },
  {
    Number: '3',
    CorrectiveAction: '시정조치 내용',
    ActionDeadline: '2024-01-22',
    Author: '김명기',
    Subject: '마케팅팀',
    Progress: '완료',
    RegistrationDate: '2024-01-25'
  },
  {
    Number: '4',
    CorrectiveAction: '시정조치 내용',
    ActionDeadline: '2024-01-22',
    Author: '김명기',
    Subject: '마케팅팀',
    Progress: '완료',
    RegistrationDate: '2024-01-25'
  },
  {
    Number: '5',
    CorrectiveAction: '시정조치 내용',
    ActionDeadline: '2024-01-22',
    Author: '김명기',
    Subject: '마케팅팀',
    Progress: '완료',
    RegistrationDate: '2024-01-25'
  },
])
function getColor(deadline) {
  if (deadline == '2024-01-25') return 'txt-red'
}
function getColor2(progress) {
  if (progress == '진행중') return 'txt-blue'
  else if (progress == '보류') return 'txt-red'
}
const InputSearch = {
  type: 'text',
  placeholder: '검색어를 입력하세요.',
}
const select01 = {
  select:  '전체',
  items: ['전체', '진행중', '보류', '완료']
}
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]
//필터 메뉴 열고 닫기
const filterDiv1 = ref('off')
function filterOpen1(){
  if(filterDiv1.value == 'off')
    filterDiv1.value = 'on'
  else
    filterDiv1.value = 'off'
}
</script>


<style lang="scss" >
</style>
