<template>
  <div class="content-wrap">
    <NaviBox02/>
    <div class="tit-group02">
      <h2 class="tit">행동 관리 리스트</h2>
    </div>
    <div class="content-box">
      <div class="sort-group">
        <div class="sort-row">
          <div class="wl">
            <div class="txt-total">Total: 132</div>
            <c-InputSearch :placeholder="'검색어를 입력하세요.'"/>
          </div>
          <div class="wr">
            <Select v-bind="select01"/>
            <c-btn01 :btnText="'신규등록'" @click="$router.push('/safetyLeadershipAMListDetail')"/>
          </div>
        </div>
      </div>
      <!-- v-data-table 가져옴-->
      <div class="tbl-group size-custom9">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          :items-per-page-options="footerOptions"
          return-object
          show-select
        >
          <template v-slot:item.monitoringName="{ item }" >
            <v-btn variant="text" @click="$router.push('/safetyLeadershipAMListDetail')">
              <div class="ell01">
                {{item.monitoringName}}
              </div>
            </v-btn>
          </template>
          <template v-slot:item.btnCopy="{ item }">
            <div class="flex-c">
              <c-btnText01 v-bind="btnCopy" @click="dialogCopyModel=true"/>
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="bottom-btn-group">
        <div class="wl"></div>
        <div class="wr">
          <c-btn02 :btnText="'일괄 삭제'"  @click="dialogAllDeleteModel=true"/>
        </div>
      </div>
    </div>
  </div>
  <c-dialog v-model="dialogCopyModel"
            v-bind="dialogCopyBind"
            @close="dialogCopyModel=false"
  />
  <c-dialog v-model="dialogAllDeleteModel"
            v-bind="dialogAllDeleteBind"
            @close="dialogAllDeleteModel=false"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue';
const dialogCopyModel = ref(false)
const dialogAllDeleteModel = ref(false)
const dialogCopyBind = {
  dialogTit: '행동 복사',
  dialogText: '행동을 복사합니다.',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const dialogAllDeleteBind = {
  dialogTit: '행동 삭제',
  dialogText: '선택된 행동을 삭제합니다.<br>삭제된 데이터는 복구가 불가능합니다.',
  btn1Text: '삭제',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const select01 = {
  select:  '전체',
  items: ['전체', '행동', '상태']
}
//table
const btnCopy = {
  btnText: '복사'
}
const selected = ref([])
const headers = ref([
  { title: '모니터링명', key: 'monitoringName' },
  { title: '타입', key: 'type' },
  { title: '생성일 ', key: 'creationDate' },
  { title: '복사', key: 'btnCopy' },
])
const items = ref([
  {
    monitoringName: '행동 B',
    type: 'godehd',
    creationDate: '24.01.25 01:01:23',
    btnCopy:true,
  },
])
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]
</script>


<style lang="scss" scoped>
</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
