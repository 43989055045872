<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="96rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">상세보기</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>
        <!--content-->
        <v-card-text>
          <p class="fs12">총 0차수가 검색되었습니다.</p>

          <v-divider class="mb-4"></v-divider>
          <div class="tbl-group line-ty tbl-fixed size-custom16">
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer>
              <template v-slot:item.author="{ item }" >
                <div>
                  {{item.author}}
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 :btnText="'확인'"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
const headers = ref([

  { title: '작성자', key: 'author' },
  { title: '모니터링 총계', key: 'monitoringTotal' },
  { title: '안전대화 총계', key: 'safetyTalkTotal' },
  { title: '1차수 5.1~5.6', key: 'st01' },
  { title: '2차수 5.1~5.6', key: 'st02' },
  { title: '3차수 5.1~5.6', key: 'st03' },
  { title: '4차수 5.1~5.6', key: 'st04' },
  { title: '5차수 5.1~5.6', key: 'st05' },
  { title: '6차수 5.1~5.6', key: 'st06' },
  { title: '7차수 5.1~5.6', key: 'st07' },
  { title: '8차수 5.1~5.6', key: 'st08' },
])
const items = ref([
  {
    author: '김상팔 / 부서 / 직책',
    monitoringTotal: '2',
    safetyTalkTotal: '4',
    st01: '10/2',
    st02: '10/2',
    st03: '10/2',
    st04: '10/2',
    st05: '10/2',
    st06: '10/2',
    st07: '10/2',
    st08: '10/2',
  },
  {
    author: '김상팔 / 부서 / 직책',
    monitoringTotal: '2',
    safetyTalkTotal: '4',
    st01: '10/2',
    st02: '10/2',
    st03: '10/2',
    st04: '10/2',
    st05: '10/2',
    st06: '10/2',
    st07: '10/2',
    st08: '10/2',
  },
  {
    author: '김상팔 / 부서 / 직책',
    monitoringTotal: '2',
    safetyTalkTotal: '4',
    st01: '10/2',
    st02: '10/2',
    st03: '10/2',
    st04: '10/2',
    st05: '10/2',
    st06: '10/2',
    st07: '10/2',
    st08: '10/2',
  }
])
</script>


<style lang="scss">
</style>
