<template>
  <div class="tab-group ty01">
    <v-tabs
      v-model="tab"
    >
      <v-tab value="tabCont1">PDF1</v-tab>
      <v-tab value="tabCont2">PDF2</v-tab>
    </v-tabs>
  </div>
  <div class="pop-cont04">
    <v-tabs-window v-model="tab">
      <v-tabs-window-item value="tabCont1">
        <div style="display:flex;justify-content:center;align-items:center;height:89rem;background:#ddd">
          pdf영역 (div 추후 삭제바람)
        </div>
      </v-tabs-window-item>
      <v-tabs-window-item value="tabCont2">
        <div style="display:flex;justify-content:center;align-items:center;height:89rem;background:#ddd">
          pdf영역 (div 추후 삭제바람)
        </div>
      </v-tabs-window-item>
    </v-tabs-window>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Sortable } from 'sortablejs-vue3';
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
import { useRoute } from 'vue-router'
import { useDialogSLR101Store } from "@/store/dialogSLR101";
import { storeToRefs } from 'pinia'
const route = useRoute()
const store = useDialogSLR101Store()
const storeRef = storeToRefs(store)
store.moveTab('tabCont1')
const tab = storeRef.tabName
const isTabView = storeRef.isTabView

// watch는 ref에서 직접 작동합니다
watch(tab, async (newTab, oldTab) => {
  storeRef.isTabView.value = false
})
</script>


<style lang="scss" scoped>
</style>
