<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="tit-group ty01">
        <h4 class="tit">리더 _ 안전리더십 A</h4>
        <div class="btn-group">
          <c-btn02 :btnText="'전체열기'" class="sm"/>
          <c-btn02 :btnText="'전체닫기'" class="sm"/>
        </div>
      </div>
      <div class="dropdown-group3">
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-title>
              1.안전리더십 소개
              <div class="tag-icon-group">
                <div class="tag-icon ty03">
                  집중과제
                </div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="list-group3">
                <div class="box" >
                  <ul>
                    <li>
                      <v-btn  @click="DialogSLR1Model=true">
                        <span class="t1">
                          1-1 2024년도 조직 별 안전보건 전략 수립
                          <div class="tag-icon-group">
                            <div class="tag-icon ty01">
                              <span class="ico-chk" aria-hidden="true"></span>
                              진행완료
                            </div>
                          </div>
                        </span>
                        <span class="t3">* 실행 주기가 반복되는 경우, 주기에 맞춰 완료 여부는 초기화됩니다.</span>
                      </v-btn>
                    </li>
                    <li>
                      <v-btn  @click="DialogSLR1Model=true">
                        <span class="t1">1-2 셀프모니터링</span>
                      </v-btn>
                    </li>
                    <li>
                      <v-btn  @click="DialogSLR1Model=true">
                        <span class="t1">1-3 타인모니터링</span>
                      </v-btn>
                    </li>
                  </ul>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-title>안전대화_칭찬타입</v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="list-group3">
                <div class="box" >
                  <ul>
                    <li  v-for="n in 4" :key="n">
                      <v-btn  @click="DialogSLR1Model=true">
                        <span class="t1">1-1 2024년도 조직 별 안전보건 전략 수립</span>
                      </v-btn>
                    </li>
                  </ul>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>

  <!--일괄 대상자 변경-->
  <DialogSLR1
    v-model="DialogSLR1Model"
    @close="DialogSLR1Model=false"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
const panel = ref([0, 1])
const DialogSLR1Model = ref(false)
</script>


<style lang="scss" >
</style>
