<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="57rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">대상추가</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <v-card-text>
          <div class="form-group col-ty">
            <!--form-row-->
            <div class="form-row">
              <div class="row">
                <div class="col">
                  <c-Input v-bind="InputName" class="size02"/>
                </div>
                <div class="col">
                  <Select v-bind="selectPosition" v-model="selectPos"/>
                </div>
                <div class="col">
                  <Select v-bind="selectPart" v-model="selectDept"/>
                </div>
                <div class="col">
                  <Select v-bind="selectChange" v-model="selectShift"/>
                </div>
                <div class="col">
                  <Select v-bind="selectTarget" v-model="selectTargetYN"/>
                </div>
                <div class="col">
                  <Select v-bind="selectLeader" v-model="selectLeaderYN"/>
                </div>
              </div>
            </div>
          </div>
          <div class="selected-group scrollbar">
            <v-item-group selected-class="bg-active ">
              <v-container>
                <div class="list-group">
                  <div class="list-row"
                    v-for="n in 10"
                    :key="n"
                  >
                    <div class="list-txt">
                      <v-item v-slot="{ selectedClass, toggle }">
                        <v-card
                          :class="['active', selectedClass]"
                          dark
                          @click="toggle"
                        >
                          <div class="txt">
                            현장소장 / 염소정제팀 / 관리감독자
                          </div>
                        </v-card>
                      </v-item>
                    </div>
                  </div>
                </div>
              </v-container>
            </v-item-group>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end">
          <c-btn01 v-bind="btnSave"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})

//bind
const selectPos = ref({name:'직책',value:''})
const selectDept = ref({name:'부서',value:''})
const selectShift = ref({name:'교대조',value:''})
const selectTargetYN = ref({name:'대상여부',value:''})
const selectLeaderYN = ref({name:'조직장여부',value:''})

//ui
const btnSave = {
  btnText: '저장',
}
const InputName = {
  type: 'text',
  placeholder: '이름',
}
const selectPosition = {
  select:  '직책',
  items: [{name:'직책',value:''},{name:'생산팀 A',value:'생산팀 A'},{name:'생산팀 B',value:'생산팀 B'}]
}
const selectPart = {
  select:  '부서',
  items: [{name:'부서',value:''},{name:'부장',value:'부장'},{name:'대리',value:'대리'}]
}
const selectChange = {
  select:  '교대조',
  items: [{name:'교대조',value:''},{name:'교대조A',value:'교대조A'},{name:'교대조B',value:'교대조B'}]
}
const selectTarget = {
  select:  '대상여부',
  items: [{name:'대상여부',value:''},{name:'Y',value:'Y'},{name:'N',value:'N'}]
}
const selectLeader = {
  select:  '조직장여부',
  items: [{name:'조직장여부',value:''},{name:'Y',value:'Y'},{name:'N',value:'N'}]
}
</script>


<style lang="scss">
</style>
