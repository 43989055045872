<template>

  <div class="btn-nemu01">
    <v-menu
      transition="slide-y-transition"
      location="top center"
    >
      <template v-slot:activator="{ props }">
        <div class="btn-menu01" >
          <v-btn variant="outlined" v-bind="props" >
            {{ btnText }}
          </v-btn>
        </div>

      </template>

      <v-list class="btn-menu01-list">
        <div class="inner">
          <v-list-item
            v-for="(item, i) in setItems"
            :key="i"
          >
            <v-list-item-title @click="onClickItem(item.title)">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>

    </v-menu>
  </div>


</template>

<script setup lang="ts">
const props = defineProps(['btnText', 'setItems'])
const emit = defineEmits(['onClickChild'])

//function
function onClickItem(item){
  emit('onClickChild', item)
}
</script>


<style lang="scss" >
.btn-nemu01{
  button.v-btn{
    padding:0 3rem;
    height:5rem;
    line-height:5rem;
    text-align:center;
    color:var(--color-main);
    border:1px solid var(--color-main);
    border-radius:.5rem;
    font-size:1.6rem;
    font-weight:500;
    letter-spacing:0;
    background:var(--color-white);
  }
  &.sm button.v-btn{
    padding:0 2rem;
    height:4rem;
    line-height:4rem;
    font-size:1.5rem;
    font-weight:400;
  }
}
.v-list.btn-menu01-list{
  overflow:visible !important;
  margin-bottom:2rem;
  border:1px solid var(--menu-line);
  &:before{
    display:block;
    content:'';
    position:absolute;
    bottom: -.6rem;
    left:calc(50% - .6rem);
    width:1.2rem;
    height:1.2rem;
    border-radius:.2rem;
    transform:rotate(45deg);
    background:var(--color-white);
    border:1px solid var(--menu-line);
  }
  .inner{
    position:relative;
    display:flex;
    background:var(--color-white);
    border-radius:.4rem;
    .v-list-item{
      padding-inline:2rem;
      min-width:8.5rem;
      text-align:center;
      cursor:pointer;
      &+ .v-list-item:before{
        display:block;
        content:'';
        position:absolute;
        top: calc(50% - 1rem);
        left:0;
        width:.1rem;
        height:2rem;
        background:var(--menu-line);
      }
    }
  }
}
</style>
