<template>
  <div class="container login">
    <h1 class="logo">
      <img src="@/assets/img/logo1.svg" alt="impactus">
    </h1>
    <div class="content">
      <div class="wl">
        <div class="inner">
          <div class="box">
            <div class="title-group">
              <div class="tit">Welcome back</div>
              <div class="txt">임펙터스 계정에 로그인해주세요.</div>
            </div>

            <div class="title-group-mo">
              <img src="@/assets/img/logo2.svg" alt="impactus">
              <div class="txt">현장에서의 많은 고민들,<br>임펙터스 안전관리에 참여하세요.</div>
            </div>
            <Form @submit="handleLogin" :validation-schema="schema">
              <div class="form-group">
                <div class="row">
                  <div class="tit">아이디</div>
                  <InputText v-bind="InputId" :name="'memberId'"/>
                </div>
                <div class="row">
                  <div class="tit">비밀번호</div>
                  <InputText v-bind="InputPw" :name="'password'"/>
                </div>
                <div class="row tar">
                  <linkTy1 v-bind="FindPassword"/>
                </div>
                <div class="row ty01">
                  <buttonTy1 v-bind="LoginBtn"/>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div class="wr">
        <div class="inner">
          <div class="title-group">
            <img src="@/assets/img/logo2.svg" alt="impactus">
            <div class="txt">현장에서의 많은 고민들,<br>임펙터스 안전관리에 참여하세요.</div>
          </div>
        </div>
      </div>
      <div class="foo-txt">All rights reserved. Copyright © 2024 impactus.</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from 'pinia'
import AuthService from "@/services/auth.service";
import utils from "@/common/Utils"
import {useOrganizationalManagementStore} from "@/store/organizationalManagement";

console.log(import.meta.env.MODE)
//store
const authStore = useAuthStore()
const orgStoreRef = storeToRefs(useOrganizationalManagementStore())

//validation schema
const schema = yup.object().shape({
  memberId: yup.string().required("필수 항목입니다."),
  password: yup.string().required("필수 항목입니다."),
});

//ui bind
const memberId = ref('')
const InputId = {
  type: 'text',
  placeholder: '아이디를 입력하세요',
}
const InputPw = {
  type: 'password',
  placeholder: '비밀번호를 입력하세요',
}
const FindPassword = {
  linkText: '비밀번호 찾기',
  linkShow: true
}
const LoginBtn = {
  btnText: '로그인',
  btnShow: true,
  btnType: 'submit'
}

//hook

//function
function handleText(user){
  AuthService.register(authStore.state.user).then(
    user => {
      console.log(user)
      return Promise.resolve(user);
    },
    error => {
      return Promise.reject(error);
    });
}

function handleLogin(user) {
  if(import.meta.env.MODE == 'development')
    utils.movePage('clientManagement')
  else {
    AuthService.login(user).then(
      (response) => {
        //orgStoreRef.clientName.value = response.clientName
        //orgStoreRef.companyName.value = response.companyName
        orgStoreRef.user.value = response
        if(response.roles === 'ADMIN1')
          utils.movePage('clientManagement')
        else if(response.roles === 'ADMIN2')
          utils.movePage('groupManagement')
        else if(response.roles === 'ADMIN3' || response.roles === 'USER')
          utils.movePage('safetyCalender')
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      }
    )
  }
}

</script>

<style lang="scss">
</style>

<route lang="yaml">
meta:
  layout: login
</route>
