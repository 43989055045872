<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="84rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">타인모니터링(피관찰자)</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <v-card-text class="ty01">
          <div class="form-group col-ty">
            <!--form-row-->
            <div class="form-row ty05">
              <div class="txt-tit ty04">타입</div>
              <div class="row">
                <div class="txt">안전리더십모니터링</div>
              </div>
            </div>
            <!--form-row-->
            <div class="form-row ty05">
              <div class="txt-tit ty04">안전리더십</div>
              <div class="row">
                <Select v-bind="select01"/></div>
            </div>
          </div>
          <div class="sort-group ty02">
            <div class="sort-row">
              <div class="wl">
                <div class="txt05">안전리더십1 모니터링 평가대상</div>
              </div>
            </div>
          </div>
          <div class="tbl-group size-custom10">
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer
              return-object
            >
              <template v-slot:item="{ item }" >
                <tr class="v-data-table__tr" :class="getColor(item.Appraiser)">
                  <td>
                    <div class="txt-group">
                      <span class="t1">{{item.Appraiser}}</span>
                      <span class="t2">{{item.AppraiserPart}}</span>
                    </div>
                  </td>
                  <td>
                    <div class="txt-group">
                      <span class="t1">{{item.Target}}</span>
                      <span class="t2">{{item.TargetPart}}</span>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>

        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn02 btnText="취소"/>
          <c-btn01 btnText="저장"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Sortable } from 'sortablejs-vue3';
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})

const headers = ref([
  { title: '평가자', key: 'Appraiser' },
  { title: '대상자', key: 'Target' }
])
const items = ref([
  {
    Appraiser: '김상두',
    AppraiserPart: '염소정제팀 / 관리감독자',
    Target: '이두나',
    TargetPart: '염소정제팀 / 관리감독자',
  },
  {
    Appraiser: '이창호',
    AppraiserPart: '염소정제팀염소정제팀 / 관리감독자관리감독자관리감독자',
    Target: '이두나',
    TargetPart: '염소정제팀 / 관리감독자',
  },
  {
    Appraiser: '김상두',
    AppraiserPart: '염소정제팀 / 관리감독자',
    Target: '이두나',
    TargetPart: '염소정제팀 / 관리감독자',
  },
  {
    Appraiser: '평가대상아님',
    AppraiserPart: '염소정제팀염소정제팀 / 관리감독자관리감독자관리감독자',
    Target: '이두나',
    TargetPart: '염소정제팀 / 관리감독자',
  }
])
function getColor(Appraiser) {
  if (Appraiser == '평가대상아님') return 'txt-mid-line'
}
const select01 = {
  select:  '안전리더십1',
  items: ['안전리더십1', '안전리더십2']
}
</script>


<style lang="scss" scoped>
.sortable-group02{
  .sortable-header ,.sortable-cont .draggable{
    .col:nth-child(1){flex-grow:0;width:5%;}
    .col:nth-child(2){justify-content:flex-start;text-align:left;width:70%;}
    .col:nth-child(3){flex-grow:0;width:15%;}
    .col:nth-child(4){flex-grow:0;width:10%;}
  }
  .sortable-header{
    .col:nth-child(2){align-items:flex-start;}
  }
}
</style>
