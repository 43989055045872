<template>
  <div class="input-search-group">
  <v-sheet class="mx-auto" width="400">
      <v-text-field
        clearable
        v-model="searchData"
        :placeholder="placeholder"
        @keyup.enter="searchWord">
      </v-text-field>
      <v-btn class="btn-search" type="submit" @click="searchWord">
        <span class="hide-txt">검색</span>
      </v-btn>
  </v-sheet>
  </div>
</template>
<style lang="scss"> //scoped

</style>
<script lang="ts" setup>
import {computed} from "vue";
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value','placeholder'])
//parent에서 명시한 search 사용명시
const emit = defineEmits(['searchWord'])
const searchData = defineModel()

function searchWord(){
  console.log(searchData)
  emit('searchWord', searchData.value)
}

</script>
