<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="form-group col-ty">
      <!--form-row-->
      <div class="form-row">
        <div class="txt-tit">
          회사명
        </div>
        <div class="row">
          <div class="txt">{{companyName}}</div>
        </div>
      </div>
      <!--form-row-->
      <div class="form-row">
        <div class="txt-tit">
          부서<span class="imp">*</span>
        </div>
        <div class="row">
          <c-btn01
            :btnText="'추가/변경'"
            class="sm"
            @click.stop="openPopup('dept')"/>
        </div>
      </div>
      <!--form-row-->
      <div class="form-row">
        <div class="txt-tit">
          직책<span class="imp">*</span>
        </div>
        <div class="row">
          <c-btn01
            :btnText="'추가/변경'"
            class="sm"
            @click.stop="openPopup('pos')"/>
        </div>
      </div>
      <!--form-row-->
      <div class="form-row">
        <div class="txt-tit">
          교대조 정보
        </div>
        <div class="row">
          <c-btn01
            :btnText="'추가/변경'"
            class="sm"
            @click="isShowDialog03=true"/>
        </div>
      </div>
      <!--form-row-->
      <div class="form-row">
        <div class="txt-tit">
          등록 회원수
        </div>
        <div class="row">
          <c-btnText01 :btnText="memberCnt" @click.stop="moveTab()"/>
        </div>
      </div>
      <!--form-row-->
      <div class="form-row">
        <div class="txt-tit">
          사용 여부<span class="imp">*</span>
        </div>
        <div class="row">
          <v-switch :model-value="useYn" inset hide-details true-value="Y" false-value="N" @change="changeSwitch('useYn')"></v-switch>
        </div>
      </div>
      <!--form-row-->
      <div class="form-row">
        <div class="txt-tit">
          변경 정보<span class="imp">*</span>
        </div>
        <div class="row">
          <c-btnText01 :btnText="historyCnt" @click="showDialog()"/>
        </div>
      </div>
    </div>
    </div>
    <div class="tit-group">
      <h3 class="tit">모듈 사용관리</h3>
    </div>
    <div class="content-inner-box">
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">Safety Calender</div>
          <div class="row">
            <v-switch :model-value="moduleCalender" inset hide-details true-value="Y" false-value="N" @change="changeSwitch('moduleCalender')"></v-switch>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">행동기반안전관리</div>
          <div class="row">
            <v-switch :model-value="moduleBbs" inset hide-details true-value="Y" false-value="N" @change="changeSwitch('moduleBbs')"></v-switch>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">안전리더십</div>
          <div class="row">
            <v-switch :model-value="moduleLeadership" inset hide-details true-value="Y" false-value="N" @change="changeSwitch('moduleLeadership')"></v-switch>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">통합보고서</div>
          <div class="row">
            <v-switch :model-value="moduleReport" inset hide-details true-value="Y" false-value="N" @change="changeSwitch('moduleReport')"></v-switch>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">My Board</div>
          <div class="row">
            <v-switch :model-value="moduleBoard" inset hide-details true-value="Y" false-value="N" @change="changeSwitch('moduleBoard')"></v-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-group">
      <div class="wl">
        <c-btnLine01 :btnText="'목록'" v-if="false"/>
      </div>
      <div class="wr">
        <c-btn02 :btnText="'삭제'" v-if="false"/>
        <c-btn01 :btnText="'변경'"/>
      </div>
    </div>
  </div>
  <!--부서-->
  <DialogDept02
    v-model="isShowDialog01"
    @close="isShowDialog01=false"/>
  <!--직책-->
  <!-- DialogDept02
    v-model="isShowDialog02"
    :popTitle="'직책'"
    @close="isShowDialog02=false"/-->
  <DialogShift
    v-model="isShowDialog03"
    :popTitle="'교대조'"
    @close="isShowDialog03=false"/>
  <!--변경정보-->
  <c-dialog v-model="dialogChangeNumModel"
            v-bind="dialogChangeNumBind"
            :dialogText="historyDialogText"
            @close="dialogChangeNumModel=false"
  />
  <v-snackbar v-model="snackbar" :timeout="1000" > {{ snackText }} </v-snackbar>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useOrganizationalManagementStore } from "@/store/organizationalManagement";
import { storeToRefs } from 'pinia'
import CompanyService from "@/services/company.service";
import LogService from "@/services/log.service";
import utils from "@/common/Utils";

//store
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)

//bind
const isShowDialog01 = ref(false)
const isShowDialog02 = ref(false)
const isShowDialog03 = ref(false)
const dialogChangeNumModel = ref(false)
const companyName = ref('')
const memberCnt = ref('')
const useYn = ref(false)
const historyCnt = ref('')
const moduleCalender = ref(false)
const moduleBbs = ref(false)
const moduleLeadership = ref(false)
const moduleReport = ref(false)
const moduleBoard = ref(false)
const historyDialogText = ref('')
const snackbar = ref(false)
const snackText = ref('')

//ui
const dialogChangeNumBind = ref({
  dialogTit: '변경정보 (변경 내용/변경자/날짜 순)',
  dialogText: '',
  btn1Show: true,
  btn1Text:'확인'
})

//hook
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    companyName.value = '삼성-평택'
    memberCnt.value = '1'
    historyCnt.value = '1'
    historyDialogText.value = '생성 / admin / 2024.01.03<br>수정(부서,직책,사용여부) / admin / 2024.01.04'
  }
  else{
    getData()
  }
})

//function
function getData(){
  let companyIdx = localStorage.getItem('companyIdx')

  let params = {
    idx: companyIdx,
  }

  CompanyService.getCompany(params).then(
    (response) => {
      companyName.value = response.result.companyName
      memberCnt.value = response.result.memberCnt
      useYn.value = response.result.useYn
      historyCnt.value = response.result.historyCnt
      moduleCalender.value = response.result.moduleCalender
      moduleBbs.value = response.result.moduleBbs
      moduleLeadership.value = response.result.moduleLeadership
      moduleReport.value = response.result.moduleReport
      moduleBoard.value = response.result.moduleBoard
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function moveTab(){
  store.moveTab('tabCont2')
}

function showDialog(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    idx: companyIdx,
    tableType: 'company'
  }
  LogService.getLogList(params).then(
    (response) => {
      let logContent = ''
      if(response.result.length > 0) {
        response.result.forEach(function(row) {
          logContent += row.changeLog + '<br>'
        });
      }

      if(logContent != '') {
        historyDialogText.value = logContent
        dialogChangeNumModel.value = true
      }
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function changeSwitch(id){
  let companyIdx = localStorage.getItem('companyIdx')
  let value = useYn.value
  if(id == 'moduleCalender')
    value = moduleCalender.value
  if(id == 'moduleBbs')
    value = moduleBbs.value
  if(id == 'moduleLeadership')
    value = moduleLeadership.value
  if(id == 'moduleReport')
    value = moduleReport.value
  if(id == 'moduleBoard')
    value = moduleBoard.value
  let params = {
    idx: companyIdx,
    [id]: value
  }
  CompanyService.insertCompany(params).then(
    (response) => {
      snackText.value = '저장되었습니다'
      snackbar.value = true
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function openPopup(type){
  let companyIdx = localStorage.getItem('companyIdx')
  storeRef.popupIdx.value = companyIdx
  storeRef.popupType.value = type
  isShowDialog01.value = true
}
</script>


<style lang="scss" >
</style>
