import api from "./api";
import Utils from "@/common/Utils";

class BoardService {
  getBoard(params) {
    return api
      .post("/board/getBoard", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getBoardList(params) {
    return api
      .post("/board/getBoardList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertBoard(params) {
    let apiUrl = '/board/insertBoard'
    if(params['idx'])
      apiUrl = '/board/updateBoard'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new BoardService();
