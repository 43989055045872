<template>
  <div class="input-txt-group">
    <Field
      :type="type"
      :placeholder="placeholder"
      :value="value"
      :readonly="readonly"
      :disabled="disabled"
      :name="name"
      v-model="model"
    />
    <ErrorMessage :name="name" class="error-feedback" />
  </div>
</template>
<style lang="scss">
.login {
  .input-txt-group input{
    width:100%;
    height:4.5rem;
    font-size:1.4rem;
    padding-left:1.2rem;
    border-radius:.6rem;
    border:1px solid var(--color-inp-border);
  }
  .error-feedback{
    color: var(--txt-red);
    font-size:1.4rem;
  }
}
</style>
<script lang="ts" setup>
import { toRef } from "vue";
import { useField, Field, ErrorMessage } from "vee-validate";

const props = defineProps(['type', 'placeholder', 'value', 'readonly', 'disabled', 'name'])
const model = defineModel()

const { value, handleBlur, errors } = useField(toRef(props, "name"), undefined,{ syncVModel: true });
</script>
