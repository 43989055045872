<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="tit-group ty01">
        <h4 class="tit">작업자 안전행동 관찰</h4>
        <div class="alert-group">
          <v-alert text="AUDIT 활동 중입니다"></v-alert>
        </div>
      </div>
      <div class="tab-group ty01">
        <v-tabs
          v-model="tab"
        >
          <v-tab value="tabCont1">행동관찰</v-tab>
          <v-tab value="tabCont2" >안전대화</v-tab>
        </v-tabs>
      </div>
      <div class="content-box bg-none">
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="tabCont1">
            <div class="form-group col-ty">
              <!--form-row-->
              <div class="form-row ty03">
                <div class="txt-tit ty02">
                  <c-tooltip :TooltipText="'관리자가 관리하는 항목입니다. 추가/변경 시 사내 관리자에게 문의 주시기 바랍니다.'"/>
                  장소
                </div>
                <div class="row">
                  <Select v-bind="select01"/>
                </div>
              </div>
            </div>
            <div class="list-group2 ty01">
              <!--list-->
              <div class="list">
                <div class="row1">
                  <div class="num">1</div>
                  <div class="txt">동행 점검 시 동일한 포인트를 확인하여 위험예지 시각을 통일한다.</div>
                  <div class="right-area">
                    <div class="check-group ty01">
                      <v-checkbox label="해당없음"></v-checkbox>
                    </div>
                    <c-btnText01 :btnText="'안전대화로 이동'" class="ty03"/>
                  </div>
                </div>
                <div class="row2">
                  <div class="radio-group2">
                    <v-radio-group v-model="radios">
                      <v-radio label="안전" value="안전" ></v-radio>
                      <v-radio label="불안전" value="불안전"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>
              <!--list-->
              <div class="list">
                <div class="row1">
                  <div class="num">2</div>
                  <div class="txt"> 일일미팅(모닝미팅/TBM)을 통해 특별히 안전에 신경써야 할 사항을 공유하고 주시시킨다. 동행 점검 시 동일한 포인트를 확인하여 위험예지 시각을 통일한다.</div>
                  <div class="right-area">
                    <div class="check-group ty01">
                      <v-checkbox label="해당없음"></v-checkbox>
                    </div>
                    <c-btnText01 :btnText="'안전대화로 이동'" class="ty03"/>
                  </div>
                </div>
                <div class="row2">
                  <div class="radio-group2">
                    <v-radio-group v-model="radios2">
                      <v-radio label="안전" value="안전" ></v-radio>
                      <v-radio label="불안전" value="불안전"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>
              <!--list-->
              <div class="list">
                <div class="row1">
                  <div class="num">3</div>
                  <div class="txt">동행 점검 시 동일한 포인트를 확인하여 위험예지 시각을 통일한다.</div>
                  <div class="right-area">
                    <div class="check-group ty01">
                      <v-checkbox label="해당없음"></v-checkbox>
                    </div>
                    <c-btnText01 :btnText="'안전대화로 이동'" class="ty03"/>
                  </div>
                </div>
                <div class="row2">
                  <div class="radio-group2">
                    <v-radio-group v-model="radios3">
                      <v-radio label="안전" value="안전" ></v-radio>
                      <v-radio label="불안전" value="불안전"></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="inner">
                    <c-input :placeholder="'set-up'"></c-input>
                    <c-input :placeholder="'chiler 점검'" disabled="disabled"></c-input>
                    <c-input :placeholder="'chiler 교체'"></c-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-btn-group ty02">
              <div class="wl">
              </div>
              <div class="wr">
                <c-btn01 :btnText="'제출'"/>
              </div>
            </div>
          </v-tabs-window-item>
          <v-tabs-window-item value="tabCont2">
            <div class="dropdown-group2">
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel>
                  <v-expansion-panel-title>안전대화1</v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <div class="form-group col-ty">
                      <div class="tit-group">
                        <div class="tit02">기본정보</div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">타입</div>
                        <div class="row">
                          <div class="radio-group">
                            <v-radio-group v-model="radios4">
                              <v-radio color="indigo" label="교정적 안전대화" value="교정적 안전대화" class="r-size01"></v-radio>
                              <v-radio color="indigo" label="지지적 안전대화" value="지지적 안전대화"></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">
                          시정조치여부
                          <c-tooltip :TooltipText="'실제 시정 작업 할당 여부를 선택하는 기능입니다'"/>
                        </div>
                        <div class="row">
                          <div class="radio-group">
                            <v-radio-group v-model="radios5 ">
                              <v-radio color="indigo" label="불필요" value="불필요" class="r-size01"></v-radio>
                              <v-radio color="indigo" label="필요" value="필요"></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit ty03">
                          대상자
                        </div>
                        <div class="row">
                          <div class="radio-group">
                            <v-radio-group v-model="radios6">
                              <v-radio color="indigo" label="별도지정 없음" value="별도지정 없음" class="r-size01"></v-radio>
                              <v-radio color="indigo" label="부서" value="부서" @click="isShowDialog=true"></v-radio>
                              <v-radio color="indigo" label="구성원" value="구성원" @click="isShowDialog02=true"></v-radio>
                            </v-radio-group>
                          </div>
                          <p class="txt04">김상두 / 염소정제팀 / 관리감독자</p>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">
                          조치기한
                        </div>
                        <div class="row">
                          <c-InputDate :enableTimePicker="false" class="size02" readonly=""/>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-ty line-ty">
                      <div class="tit-group">
                        <div class="tit02">상세정보</div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">모니터링행동</div>
                        <div class="row">
                          <Select v-bind="select02"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">장소</div>
                        <div class="row">
                          <Select v-bind="select03"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">구체적상황</div>
                        <div class="row">
                          <c-Input :placeholder="'상황을 누구나 이해할 수 있도록 구체적으로 작성하여 주세요.'"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">원인</div>
                        <div class="row">
                          <c-Input :placeholder="'개인적 판단이 아닌, 대화 과정에서 파악한 원인을 작성 해주세요.'"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">시정조치내용</div>
                        <div class="row">
                          <c-Input :placeholder="'구체적인 시정 조치 내용을 입력 해주세요.'"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit ty03">이미지<br>(최대 5장)</div>
                        <div class="row">
                          <InputImgAdd/>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-title>안전대화_칭찬타입</v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <div class="form-group col-ty">
                      <div class="tit-group">
                        <div class="tit02">기본정보</div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">타입</div>
                        <div class="row">
                          <div class="radio-group">
                            <v-radio-group v-model="radios4">
                              <v-radio color="indigo" label="교정적 안전대화" value="교정적 안전대화" class="r-size01"></v-radio>
                              <v-radio color="indigo" label="지지적 안전대화" value="지지적 안전대화"></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit ty03">
                          대상자
                        </div>
                        <div class="row">
                          <div class="radio-group">
                            <v-radio-group v-model="radios6">
                              <v-radio color="indigo" label="별도지정 없음" value="별도지정 없음" class="r-size01"></v-radio>
                              <v-radio color="indigo" label="부서" value="부서" @click="isShowDialog=true"></v-radio>
                              <v-radio color="indigo" label="구성원" value="구성원" @click="isShowDialog02=true"></v-radio>
                            </v-radio-group>
                          </div>
                          <p class="txt04">김상두 / 염소정제팀 / 관리감독자</p>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">
                          조치기한
                        </div>
                        <div class="row">
                          <c-InputDate :enableTimePicker="false" class="size02" readonly=""/>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-ty line-ty">
                      <div class="tit-group">
                        <div class="tit02">상세정보</div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit">시정조치내용</div>
                        <div class="row">
                          <c-Input :placeholder="'칭찬 사유를 구체적으로 입력 해주세요.'"/>
                        </div>
                      </div>
                      <!--form-row-->
                      <div class="form-row">
                        <div class="txt-tit ty03">이미지<br>(최대 5장)</div>
                        <div class="row">
                          <InputImgAdd/>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div class="bottom-btn-group ty02">
              <div class="wl">
              </div>
              <div class="wr">
                <c-btnLine01 :btnText="'안전대화 추가'"/>
                <c-btn01 :btnText="'제출'"/>
              </div>
            </div>
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </div>
    <!--
    <div class="bottom-btn-group">
      <div class="wl">
      </div>
      <div class="wr">
        <c-btnLine01 :btnText="'안전대화 추가'"/>
        <c-btn01 :btnText="'제출'"/>
      </div>
    </div>
    -->
  </div>
  <DialogDept
    v-model="isShowDialog"
    :popTitle="'부서'"
    @close="isShowDialog=false"/>
  <DialogTargetAdd
    v-model="isShowDialog02"
    @close="isShowDialog02=false"/>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
const isShowDialog = ref(false)
const isShowDialog02 = ref(false)
const tab = ref(null)
const select01 = {
  select:  '공장',
  items: ['공장', '미선택']
}
const select02 = {
  select:  '동행 점검 시 동일한 포인트를 확인하여 위험예지',
  items: ['동행 점검 시 동일한 포인트를 확인하여 위험예지', '일일미팅(모닝미팅/TBM)을 통해 특별히 안전에 신경써야 할 사항을 공유하고 주시시킨다',]
}
const select03 = {
  select:  '공장',
  items: ['공장', '미선택']
}
const radios = ref('안전')
const radios2 = ref('안전')
const radios3 = ref('불안전')
const radios4 = ref('교정적 안전대화')
const radios5 = ref('불필요')
const radios6 = ref('별도지정 없음')
const panel = ref([0])
</script>


<style lang="scss" >

</style>
