<template>
  <div class="mb-page-wrap2">
    <div class="tit-group">
      <h2 class="tit">My Board</h2>
    </div>
    <div class="tit-group02 ty01">
      <h2 class="tit">시정조치 A</h2>
    </div>
    <div class="content-box bg-none">

      <div class="content-inner-box-wrap">
        <div class="content-inner-box">
          <div class="form-wrap">
            <div class="form-group col-ty">
              <div class="tit-group">
                <div class="tit02">상세정보</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">모니터링행동</div>
                <div class="row">행동 안전관찰</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">행동</div>
                <div class="row">동행 점검 시 동일한 포인트를 확인히</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">등록자</div>
                <div class="row">홍길동 /  관리감독팀 / 부서장</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">장소</div>
                <div class="row">장소</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">구체적상황</div>
                <div class="row">공장안</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">원인</div>
                <div class="row">담배 핌</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">시정조치 내용</div>
                <div class="row">담배 피면 안됨</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">등록일</div>
                <div class="row">2024.02.12 06:32</div>
              </div>
              <!--form-row-->
              <div class="form-row">
                <div class="txt-tit">조치기한</div>
                <div class="row">2024.02.12 06:32</div>
              </div>

              <div class="img-slider02">
                <v-carousel
                >
                  <div class="img-wrap ty01">
                    <v-carousel-item
                      v-for="(item,i) in items"
                      :key="i"
                      :src="item.src"
                      cover
                      @click="isShowDialog2=true"
                    ></v-carousel-item>
                  </div>
                </v-carousel>
              </div>
            </div>
            <div class="bottom-btn-group">
              <div class="wl">
                <c-btnLine01 :btnText="'진행중'" class="ty01"/>
                <c-btn02 :btnText="'보류'" class="ty01" @click="isShowDialog4=true"/>
                <c-btn01 :btnText="'완료'" class="ty01"/>
              </div>
              <div class="wr">
                <c-btn02 :btnText="'대상변경'" class="ty01" @click="isShowDialog3=true"/>
              </div>
            </div>
          </div>
        </div>
        <div class="content-inner-box ty02">
          <div class="tit-group mo-ty01">
            <h4 class="tit">히스토리</h4>
          </div>
          <div class="form-wrap">
            <div class="form-wrap-inner">
              <div class="tit-group">
                <h4 class="tit">히스토리</h4>
              </div>
              <div class="history-group scrollbar">
                 <div class="box" v-for="n in 2" :key="n" ><!-- v-for="n in 7" :key="n" -->
                  <div class="txt-group">
                    <div class="txt">2024.02.12</div>
                    <div class="txt2">
                      <div class="tag-icon-group">
                        <div class="tag-icon ty01">완료</div>
                        <!--
                        <div class="tag-icon ty02">대상변경</div>
                        <div class="tag-icon ty03">코멘트</div>
                        <div class="tag-icon ty04">보류</div>
                        -->
                      </div>

                      <span class="t1">김상두 / 염소정제팀 / 관리감독자</span>
                      <div class="inner">
                        <span class="ico-arr" aria-hidden="true"></span>
                        <span class="t2">김상두 / 염소정제팀 / 관리감독자</span>
                      </div>
                    </div>
                  </div>
                  <div class="txt-group2">
                    <div class="txt">시멘트 안전지역 위치필요</div>
                    <div class="img-group">
                      <div class="item">
                        <v-btn @click="isShowDialog2=true">
                          <img src="https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg">
                        </v-btn>
                      </div>
                      <div class="item">
                        <v-btn @click="isShowDialog2=true">
                          <img src="https://cdn.vuetifyjs.com/images/carousel/sky.jpg">
                        </v-btn>
                      </div>
                      <div class="item">
                        <v-btn @click="isShowDialog2=true">
                          <img src="https://cdn.vuetifyjs.com/images/carousel/skyd.jpg">
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <InputComment/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DialogImgView v-model="isShowDialog2" @close="isShowDialog2=false"/>
  <DialogTargetAdd4 v-model="isShowDialog3" @close="isShowDialog3=false"/>
  <DialogReason v-model="isShowDialog4" @close="isShowDialog4=false"/>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const isShowDialog2 = ref(false)
const isShowDialog3 = ref(false)
const isShowDialog4 = ref(false)

const items = [
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/planet-.jpg',
  },
]
</script>



<style lang="scss" scoped>
</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
