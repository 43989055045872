import api from "./api";
import Utils from "@/common/Utils";

class MemberService {
  getMember(params) {
    return api
      .post("/member/getMember", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getMemberList(params) {
    return api
      .post("/member/getMemberList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertMember(params) {
    let apiUrl = '/member/insertMember'
    if(params['idx'])
      apiUrl = '/member/updateMember'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getMemberShiftList(params) {
    return api
      .post("/member/getMemberShiftList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertMemberShift(params) {
    return api
      .post("/member/insertMemberShift", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getMemberTree(params) {
    let apiUrl = '/member/getMemberDeptList'
    if(params['type'] == 'pos')
      apiUrl = '/member/getMemberPosList'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  deleteMember(params) {
    let apiUrl = '/member/deleteMember'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getCompanyAdmin(params) {
    return api
      .post("/member/getCompanyAdmin", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  getCompanyAdminList(params) {
    return api
      .post("/member/getCompanyAdminList", params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }

  insertCompanyAdmin(params) {
    let apiUrl = '/member/insertCompanyAdmin'
    if(params['idx'])
      apiUrl = '/member/updateCompanyAdmin'
    return api
      .post(apiUrl, params)
      .then((response) => {
        if (response.data.success) {
          Utils.log(response.data)
        }

        return response.data;
      });
  }
}

export default new MemberService();
