<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">{{ popTitle }}</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>
        <div class="list-group2">
          <div class="list">
            <div class="row1">
              <div class="num">1</div>
              <div class="txt">동행 점검 시 동일한 포인트를 확인하여 위험예지 시각을 통일한다.</div>
            </div>
            <div class="row2">
              <div class="radio-group2">
                <v-radio-group v-model="radios">
                  <v-radio label="안전" value="안전" ></v-radio>
                  <v-radio label="불안전" value="불안전"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </div>
          <div class="list">
            <div class="row1">
              <div class="num">2</div>
              <div class="txt"> 일일미팅(모닝미팅/TBM)을 통해 특별히 안전에 신경써야 할 사항을 공유하고 주시시킨다.</div>
            </div>
            <div class="row2">
              <div class="radio-group2">
                <v-radio-group v-model="radios2">
                  <v-radio label="안전" value="안전" ></v-radio>
                  <v-radio label="불안전" value="불안전"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </div>
          <div class="list">
            <div class="row1">
              <div class="num">3</div>
              <div class="txt"> 화기작업시 주변 인화성 물질 제거 및 불꽃 비산방지포 설치</div>
            </div>
            <div class="row2">
              <div class="radio-group2">
                <v-radio-group v-model="radios3">
                  <v-radio label="안전" value="안전" ></v-radio>
                  <v-radio label="불안전" value="불안전"></v-radio>
                </v-radio-group>
              </div>
              <div class="inner">
                <c-input :placeholder="'set-up'"></c-input>
                <c-input :placeholder="'chiler 점검'" disabled="disabled"></c-input>
                <c-input :placeholder="'chiler 교체'"></c-input>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end pop-bottom-bg"><!--pop-bottom-bg-->
        <c-btn01 v-bind="btnSave"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value', 'popTitle'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
const btnSave = {
  btnText: '확인',
}
const radios = ref('안전')
const radios2 = ref('안전')
const radios3 = ref('불안전')
</script>


<style lang="scss">

</style>
