<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">회사명</div>
          <div class="row">
            <div class="txt">{{companyName}}</div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">아이디<span class="imp">*</span></div>
          <div class="row">
            <div class="txt" v-if="adminIdx">{{memberId}}</div>
            <c-InputSearch02 v-if="!adminIdx" v-bind="InputSearch02" />
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">
            변경 정보<span class="imp">*</span>
          </div>
          <div class="row">
            <c-btnText01 :btnText="historyCnt" @click="dialogChangeNumModel=true"/>
          </div>
        </div>
      </div>
    </div>
    <div class="tit-group">
      <h3 class="tit">권한관리</h3>
    </div>
    <div class="content-inner-box-wrap">
      <div class="content-inner-box">
        <div class="tit-group">
          <h4 class="tit">조직 관리</h4>
        </div>
        <div class="form-group col-ty">
          <!--form-row-->
          <div class="form-row">
            <div class="txt-tit">회사관리</div>
            <div class="row">
              <Select v-bind="select01" v-model="authCompany" :name="authCompany"/>
            </div>
          </div>
          <!--form-row-->
          <div class="form-row">
            <div class="txt-tit">회원관리</div>
            <div class="row">
              <Select v-bind="select01" v-model="authMember" :name="authMember"/>
            </div>
          </div>
          <!--form-row-->
          <div class="form-row">
            <div class="txt-tit">관리자관리</div>
            <div class="row">
              <Select v-bind="select01" v-model="authAdmin" :name="authAdmin"/>
            </div>
          </div>
          <!--form-row-->
          <div class="form-row">
            <div class="txt-tit">시스템알림</div>
            <div class="row">
              <Select v-bind="select01" v-model="authBoard" :name="authBoard"/>
            </div>
          </div>
        </div>
      </div>
      <div class="content-inner-box">
        <div class="tit-group">
          <h4 class="tit">모둘 관리</h4>
        </div>
        <div class="form-group col-ty">
          <!--form-row-->
          <div class="form-row">
            <div class="txt-tit">BBS모니터링</div>
            <div class="row">
              <Select v-bind="select01" v-model="authBbs" :name="authBbs"/>
            </div>
          </div>
          <!--form-row-->
          <div class="form-row">
            <div class="txt-tit">안전리더십</div>
            <div class="row">
              <Select v-bind="select01" v-model="authLeadership" :name="authLeadership"/>
            </div>
          </div>
          <!--form-row-->
          <div class="form-row">
            <div class="txt-tit">그레이드북</div>
            <div class="row">
              <Select v-bind="select01" v-model="authLeadershipResult" :name="authLeadershipResult"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-group">
      <div class="wl">
        <c-btnLine01 v-bind="btnPageList" @click.stop="onClickGoList()"/>
      </div>
      <div class="wr">
        <c-btn02 v-bind="btnPageDel" @click="dialogPageDelModel=true"/>
        <c-btn01 v-bind="btnPageModify" @click.stop.prevent="handleForm"/>
      </div>
    </div>
  </div>
  <!--변경정보-->
  <c-dialog v-model="dialogChangeNumModel"
            v-bind="dialogChangeNumBind"
            @close="dialogChangeNumModel=false"
  />
  <!--삭제-->
  <c-dialog v-model="dialogPageDelModel"
            v-bind="dialogPageDelBind"
            @close="dialogPageDelModel=false"
  />
</template>
<style lang="scss" >
</style>
<script setup lang="ts">
import { ref } from 'vue'
import MemberService from "@/services/member.service";
import utils from "@/common/Utils";
import {useOrganizationalManagementStore} from "@/store/organizationalManagement";
import {storeToRefs} from "pinia";

//store
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)

//bind
const dialogChangeNumModel = ref(false)
const dialogPageDelModel = ref(false)

const adminIdx = ref(storeRef.viewIdx.value)
const companyName = ref('')
const memberId = ref('')
const newMemberId = ref(null)
const authCompany = ref({name: '없음', value: '0'})
const authMember = ref({name: '없음', value: '0'})
const authAdmin = ref({name: '없음', value: '0'})
const authBoard = ref({name: '없음', value: '0'})
const authBbs = ref({name: '없음', value: '0'})
const authLeadership = ref({name: '없음', value: '0'})
const authLeadershipResult = ref({name: '없음', value: '0'})
const historyCnt = ref('')

//ui
const InputSearch02 = {
  type: 'text',
  placeholder: '검색어를 입력하세요',
}
const dialogChangeNumBind = {
  dialogTit: '변경정보 (변경 내용/변경자/날짜 순)',
  dialogText: '생성 / admin / 2024.01.03<br>수정(부서,직책,사용여부) / admin / 2024.01.04',
  btn1Text: '확인',
  btn1Show: true,
}
const dialogPageDelBind = {
  dialogTit: '삭제',
  dialogText: '관리자 삭제 시 일반사용자로 변경됩니다.  적용하시겠습니까?',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const select01 = {
  items:
    [{name: '없음', value: '0'}, {name: '열람', value: '1'}, {name: '수정', value: '2'}]
}
const btnChangeNum = {
  btnText: '3번',
}
const btnPageList = {
  btnText: '목록',
}
const btnPageDel = {
  btnText: '삭제',
}
const btnPageModify = {
  btnText: '등록/수정',
}

//hook
onMounted(() => {
  let memberIdx = storeRef.viewIdx.value
  if(memberIdx) {
    let params = {
      idx: memberIdx
    }

    MemberService.getCompanyAdmin(params).then(
      (response) => {
        //adminIdx.value = response.result.idx
        companyName.value = response.result.companyName
        memberId.value = response.result.memberId
        authCompany.value = getSelectObj(response.result.authCompany)
        authMember.value = getSelectObj(response.result.authMember)
        authAdmin.value = getSelectObj(response.result.authAdmin)
        authBoard.value = getSelectObj(response.result.authBoard)
        authBbs.value = getSelectObj(response.result.authBbs)
        authLeadership.value = getSelectObj(response.result.authLeadership)
        authLeadershipResult.value = getSelectObj(response.result.authLeadershipResult)
        historyCnt.value = response.result.historyCnt
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
})

//function
function handleForm(){
  if(import.meta.env.MODE == 'development') {
    store.moveTab('tabCont3')
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    if(!companyIdx)
      utils.movePage('/login')

    let params = {
      idx : adminIdx.value,
      authCompany : authCompany.value.value,
      authMember : authMember.value.value,
      authAdmin : authAdmin.value.value,
      authBoard : authBoard.value.value,
      authBbs : authBbs.value.value,
      authLeadership : authLeadership.value.value,
      authLeadershipResult : authLeadershipResult.value.value,
    }

    MemberService.insertCompanyAdmin(params).then(
      (response) => {
        if(response.success)
          store.moveTab('tabCont3')
        else
          alert(response.message)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function openPopup(type){
  let companyIdx = localStorage.getItem('companyIdx')
  storeRef.popupIdx.value = companyIdx
  storeRef.popupType.value = type
  //isShowDialog01.value = true
}

function onClickGoList(){
  store.moveTab('tabCont3')
}

function getSelectObj(key){
  if(key == '0')
    return {name: '없음', value: '0'}
  else if(key == '1')
    return {name: '열람', value: '1'}
  else if(key == '2')
    return {name: '수정', value: '2'}
}
</script>

