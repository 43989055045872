<template>
  <div class="content-wrap am-page-wrap" :class="amPage">
    <div class="navi-area">
      <div class="m-navi-top">
        <c-btn01 :btnText="'모니터링 대시보드'" @click="$router.push('/actionManagement')"/>
      </div>
      <NaviBox/>
    </div>
    <div class="page2">
      <div class="tit-group02">
        <h2 class="tit">타임라인</h2>
        <div class="filter-btn-group">
          <v-btn class="reset" :class="filterDiv">필터초기화</v-btn>
          <v-btn class="filter" @click="filterOpen" :class="filterDiv">필터</v-btn>
          <div class="navi-btn">
            <v-btn @click="amListOpen"/>
          </div>
        </div>
      </div>
      <div class="content-box">
        <div class="sort-group" :class="filterDiv">
          <div class="sort-row ty01">
            <div class="wl">
              <div class="inner-row">
                <div class="txt01">기간</div>
                <div class="date-sort">
                  <c-InputDate :enableTimePicker="false" readonly="" />
                  <div class="txt02">~</div>
                  <c-InputDate :enableTimePicker="false" readonly=""/>
                </div>
                <div class="date-sort">
                  <c-btn02 :btnText="'당일'" class="ty01"/>
                  <c-btn02 :btnText="'분기'" class="ty01"/>
                  <c-btn02 :btnText="'올해'" class="ty01"/>
                </div>
                <div class="date-sort">
                  <div class="txt03">지지적 피드백만 보기</div>
                  <v-switch inset hide-details></v-switch>
                </div>
              </div>
              <div class="inner-row">
                <Select v-bind="select01"/>
                <Select v-bind="select02"/>
                <Select v-bind="select03"/>
                <Select v-bind="select04"/>
                <Select v-bind="select05"/>
                <Select v-bind="select06"/>
              </div>
            </div>
            <div class="wr">
              <c-btnText01 :btnText="'축소하기'" class="ty02" @click="$router.push('/actionManagement')"/>
            </div>
          </div>
        </div>
        <div class="slider-group all">
          <v-slide-group
          >
            <v-slide-group-item
              v-slot="{ isSelected, toggle }"
            >
              <v-card
                @click="toggle"
              >
                <div class="slider-item">
                  <div class="img-slider">
                    <v-carousel
                    >
                      <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item.src"
                        cover
                        @click="isShowDialog2=true"
                      ></v-carousel-item>
                    </v-carousel>
                  </div>
                  <div @click="isShowDialog=true">
                    <div class="txt-group">
                      <div class="txt ell01">김상두 / 염소정제팀12345 / 관리감독자</div>
                      <div class="txt2">2024.02.12</div>
                    </div>
                    <ul class="txt-group2">
                      <li>
                        <div class="tit">행동</div>
                        <div class="txt ell01">동행 점검 시 동일한 포인트 확인</div>
                      </li>
                      <li>
                        <div class="tit">장소</div>
                        <div class="txt ell01">공장</div>
                      </li>
                      <li>
                        <div class="tit">구체적인상황</div>
                        <div class="txt ell01">구체적인 상황</div>
                      </li>
                      <li>
                        <div class="tit">원인</div>
                        <div class="txt ell01">원인</div>
                      </li>
                      <li>
                        <div class="tit">시정조치내용</div>
                        <div class="txt ell01">시멘트 안전지역 위치필요 시멘트 안전지역 위치필요</div>
                      </li>
                      <li>
                        <div class="tit">시정조치대상자</div>
                        <div class="txt ell01">홍길동</div>
                      </li>
                      <li>
                        <div class="tit">조치기한</div>
                        <div class="txt ell01">2024.05.01</div>
                      </li>
                      <li>
                        <div class="tit">진행상태</div>
                        <div class="txt2 c01">완료</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-card>
              <v-card
                @click="toggle"
              >
                <div class="slider-item">
                  <div class="img-slider">
                    <v-carousel
                    >
                      <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item.src"
                        cover
                        @click="isShowDialog2=true"
                      ></v-carousel-item>
                    </v-carousel>
                  </div>
                  <div @click="isShowDialog=true">
                    <div class="txt-group">
                      <div class="txt ell01">김상두 / 염소정제팀 / 관리감독자</div>
                      <div class="txt2">2024.02.12</div>
                    </div>
                    <ul class="txt-group2">
                      <li>
                        <div class="tit">행동</div>
                        <div class="txt ell01">동행 점검 시 동일한 포인트 확인</div>
                      </li>
                      <li>
                        <div class="tit">장소</div>
                        <div class="txt ell01">공장</div>
                      </li>
                      <li>
                        <div class="tit">구체적인상황</div>
                        <div class="txt ell01">구체적인 상황</div>
                      </li>
                      <li>
                        <div class="tit">원인</div>
                        <div class="txt ell01">원인</div>
                      </li>
                      <li>
                        <div class="tit">시정조치내용</div>
                        <div class="txt ell01">시멘트 안전지역 위치필요 시멘트 안전지역 위치필요</div>
                      </li>
                      <li>
                        <div class="tit">시정조치대상자</div>
                        <div class="txt ell01">홍길동</div>
                      </li>
                      <li>
                        <div class="tit">조치기한</div>
                        <div class="txt ell01">2024.05.01</div>
                      </li>
                      <li>
                        <div class="tit">진행상태</div>
                        <div class="txt2 c01">완료</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-card>
              <v-card
                @click="toggle"
              >
                <div class="slider-item">
                  <div class="img-slider">
                    <v-carousel
                    >
                      <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item.src"
                        cover
                        @click="isShowDialog2=true"
                      ></v-carousel-item>
                    </v-carousel>
                  </div>
                  <div @click="isShowDialog=true">
                    <div class="txt-group">
                      <div class="txt ell01">김상두 / 염소정제팀 / 관리감독자</div>
                      <div class="txt2">2024.02.12</div>
                    </div>
                    <ul class="txt-group2">
                      <li>
                        <div class="tit">행동</div>
                        <div class="txt ell01">동행 점검 시 동일한 포인트 확인</div>
                      </li>
                      <li>
                        <div class="tit">장소</div>
                        <div class="txt ell01">공장</div>
                      </li>
                      <li>
                        <div class="tit">구체적인상황</div>
                        <div class="txt ell01">구체적인 상황</div>
                      </li>
                      <li>
                        <div class="tit">원인</div>
                        <div class="txt ell01">원인</div>
                      </li>
                      <li>
                        <div class="tit">시정조치내용</div>
                        <div class="txt ell01">시멘트 안전지역 위치필요 시멘트 안전지역 위치필요</div>
                      </li>
                      <li>
                        <div class="tit">시정조치대상자</div>
                        <div class="txt ell01">홍길동</div>
                      </li>
                      <li>
                        <div class="tit">조치기한</div>
                        <div class="txt ell01">2024.05.01</div>
                      </li>
                      <li>
                        <div class="tit">진행상태</div>
                        <div class="txt2 c01">완료</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-card>
              <v-card
                @click="toggle"
              >
                <div class="slider-item">
                  <div class="img-slider">
                    <v-carousel
                    >
                      <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item.src"
                        cover
                        @click="isShowDialog2=true"
                      ></v-carousel-item>
                    </v-carousel>
                  </div>
                  <div @click="isShowDialog=true">
                    <div class="txt-group">
                      <div class="txt ell01">김상두 / 염소정제팀 / 관리감독자</div>
                      <div class="txt2">2024.02.12</div>
                    </div>
                    <ul class="txt-group2">
                      <li>
                        <div class="tit">행동</div>
                        <div class="txt ell01">동행 점검 시 동일한 포인트 확인</div>
                      </li>
                      <li>
                        <div class="tit">장소</div>
                        <div class="txt ell01">공장</div>
                      </li>
                      <li>
                        <div class="tit">구체적인상황</div>
                        <div class="txt ell01">구체적인 상황</div>
                      </li>
                      <li>
                        <div class="tit">원인</div>
                        <div class="txt ell01">원인</div>
                      </li>
                      <li>
                        <div class="tit">시정조치내용</div>
                        <div class="txt ell01">시멘트 안전지역 위치필요 시멘트 안전지역 위치필요</div>
                      </li>
                      <li>
                        <div class="tit">시정조치대상자</div>
                        <div class="txt ell01">홍길동</div>
                      </li>
                      <li>
                        <div class="tit">조치기한</div>
                        <div class="txt ell01">2024.05.01</div>
                      </li>
                      <li>
                        <div class="tit">진행상태</div>
                        <div class="txt2 c01">완료</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-card>

              <v-card
                class="ty01"
                @click="toggle"
              >
                <div class="slider-item">
                  <div class="img-slider">
                    <v-carousel
                    >
                      <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item.src"
                        cover
                        @click="isShowDialog2=true"
                      ></v-carousel-item>
                    </v-carousel>
                  </div>
                  <div @click="isShowDialog=true">
                    <div class="txt-group">
                      <div class="txt ell01">김상두 / 염소정제팀 / 관리감독자</div>
                      <div class="txt2">2024.02.12</div>
                    </div>
                    <ul class="txt-group2">
                      <li>
                        <div class="tit">칭찬상황</div>
                        <div class="txt ell01">시멘트 보관구역에 잘 치워놨음 </div>
                      </li>
                      <li>
                        <div class="tit">칭찬대상자</div>
                        <div class="txt ell01">홍길동</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </div>
      </div>
    </div>
  </div>
  <AmDialog01 v-model="isShowDialog" @close="isShowDialog=false"/>
  <DialogImgView v-model="isShowDialog2" @close="isShowDialog2=false"/>
</template>

<script setup lang="ts">
import { ref } from 'vue';
const isShowDialog = ref(false)
const isShowDialog2 = ref(false)
const select01 = {
  select:  '본인',
  items: ['본인', 'txt1', 'txt2']
}
const select02 = {
  select:  '부서',
  items: ['본인', 'txt1', 'txt2']
}
const select03 = {
  select:  '직책',
  items: ['직책', 'txt1', 'txt2']
}
const select04 = {
  select:  '모니터링',
  items: ['모니터링', 'txt1', 'txt2']
}
const select05 = {
  select:  '행동',
  items: ['행동', 'txt1', 'txt2']
}
const select06 = {
  select:  '교대조',
  items: ['교대조', 'txt1', 'txt2']
}
const items = [
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/planet-.jpg',
  },
]
//모니터링 메뉴 열고 닫기
const amPage = ref('naviOff')
function amListOpen(){
  if(amPage.value == 'naviOff')
    amPage.value = 'naviOn'
  else
    amPage.value = 'naviOff'
}
//필터 메뉴 열고 닫기
const filterDiv = ref('off')
function filterOpen(){
  if(filterDiv.value == 'off')
    filterDiv.value = 'on'
  else
    filterDiv.value = 'off'
}
</script>


<style lang="scss">
</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
