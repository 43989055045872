import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user')!);
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const useAuthStore = defineStore('authStore', () => {
  const state = initialState

  function login(user:any){
    return AuthService.login(user).then(
      user => {
        state.status.loggedIn = true;
        state.user = user;
        return Promise.resolve(user);
      },
      error => {
        state.status.loggedIn = false;
        state.user = null;
        return Promise.reject(error);
      }
    );
  }

  function logout() {
    AuthService.logout();
    state.status.loggedIn = false;
    state.user = null;
  }

  function refreshToken(accessToken:any) {
    state.status.loggedIn = true;
    state.user = { ...state.user, accessToken: accessToken };
  }

  const isLoggedIn = computed(() => state.status.loggedIn)

  return { state, login, logout, refreshToken, isLoggedIn }
})
