<template>
  <div class="tabCont">
    <div class="content-inner-box page2-inner3">
      <div class="tit-group ty01">
        <h4 class="tit">리더 _ 안전리더십 A</h4>
        <div class="btn-group">
          <c-btn02 :btnText="'다운로드'" class="sm"/>
        </div>
      </div>

      <div class="tit-group02 inner-ty">
        <h2 class="tit"></h2>
        <div class="filter-btn-group">
          <v-btn class="reset" :class="filterDiv1">필터초기화</v-btn>
          <v-btn class="filter" @click="filterOpen1" :class="filterDiv1">필터</v-btn>
        </div>
      </div>
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row" :class="filterDiv1">
          <div class="row">
            <div class="col">
              <Select v-bind="select01"/>
            </div>
            <div class="col">
              <Select v-bind="select02"/>
            </div>
            <div class="col">
              <Select v-bind="select03"/>
            </div>
            <div class="col">
              <Select v-bind="select04"/>
            </div>
          </div>
        </div>

        <div class="form-row scrollbar">
          <div class="row">
            <div class="tbl-group line-ty tbl-fixed c02">
              <v-table>
                <thead>
                <tr>
                  <th rowspan="2">이름</th>
                  <th rowspan="2">합계</th>
                  <th  v-for="n in 30" :key="n" class="t1">자유형식</th>
                </tr>
                <tr>
                  <th  v-for="n in 30" :key="n">안전리더십<br/>A</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="n in 4" :key="n">
                  <td>김동수</td>
                  <td>60</td>
                  <td v-for="n in 30" :key="n">3</td>
                </tr>
                </tbody>
              </v-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
const select01 = {
  select:  '부서(전체)',
  items: ['부서(전체)', '부서1234', '부서test12345']
}
const select02 = {
  select:  '직책(전체)',
  items: ['직책(전체)', '직책12', '직책test12345']
}
const select03 = {
  select:  '참여자(전체)',
  items: ['참여자(전체)', '참여자12', '참여자test12345']
}
const select04 = {
  select:  '안전리더십A',
  items: ['안전리더십A', '안전리더십B', '안전리더십C']
}
//필터 메뉴 열고 닫기
const filterDiv1 = ref('off')
function filterOpen1(){
  if(filterDiv1.value == 'off')
    filterDiv1.value = 'on'
  else
    filterDiv1.value = 'off'
}
</script>


<style lang="scss" >
</style>
