<template>
  <div class="input-id-add">
    <div class="inner">
      <c-Input v-bind="inputIdAdd" v-model="inputIdAdd_" :name="'idList'"/>
      <c-btnLine01 :btnText="'추가'" @click.stop="addAdmin"/>
      <c-btnLine01 :btnText="'불러오기'" class="c02" v-show="isShowLoading" @click="isShowDialog=true"/>
    </div>
    <div class="tag-group">
      <v-chip closable @click:close="deleteAdmin(item)"
              v-for="(item, i) in storeRef.adminList.value"
              :key="i">
        {{ item }}
      </v-chip>
    </div>
  </div>
  <DialogTargetAdd
    v-model="isShowDialog"
    @close="isShowDialog=false"/>
  <v-snackbar v-model="snackbar" :timeout="1000" > {{ snackText }} </v-snackbar>
</template>
<style lang="scss"> //scoped
.input-id-add{
  display:flex;
  flex-direction: column;
  width:100%;
  .inner{
    display:flex;
    width:100%;
    .input-txt-group{
      width:100%;
      & + [class*="btn-"]{margin-left:1rem }
    }
    [class*="btn-"] + [class*="btn-"]{
      margin-left:1rem
    }
  }
  .tag-group{
    margin-top:1rem;
    .v-chip{
      font-size:1.5rem;
      border-radius:.4rem;
      margin-right:.7rem;
      margin-bottom:.7rem;
      &.v-chip--variant-tonal .v-chip__underlay{
        background:var(--chip-bg);
      }
      .v-chip__content{
        letter-spacing:0;
      }
      .mdi-close-circle::before{
        opacity:.7;
      }
    }
  }

}
</style>
<script lang="ts" setup>
import { ref } from 'vue';
import {useOrganizationalManagementStore} from "@/store/organizationalManagement";
import {storeToRefs} from "pinia";
import OrganizationService from "@/services/organization.service";
import MemberService from "@/services/member.service";
import utils from "@/common/Utils";

//store
const store = useOrganizationalManagementStore()
const storeRef = storeToRefs(store)

//props
const props = defineProps(['isShowLoading'])

//bind
const snackbar = ref(false)
const snackText = ref('')
const isShowDialog = ref(false)
const inputIdAdd = {
  type: 'text',
  placeholder: '그룹사용자 ID를 입력하세요',
}
const inputIdAdd_ = ref('')

//function
function addAdmin(){
  if(inputIdAdd_.value){
    let params = {
      memberId: inputIdAdd_.value,
    }
    MemberService.getMember(params).then(
      (response) => {
        if(!response.result.memberId)
          storeRef.adminList.value.add(inputIdAdd_.value);
        else {
          snackText.value = '이미 존재하는 계정입니다'
          snackbar.value = true
        }
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if(error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function deleteAdmin(admin){
  storeRef.adminList.value.delete(admin)
}

</script>
