<template>
  <div class="tabCont page1">
    <div class="p-inner top">
      <div class="tit-group02">
        <h2 class="tit">Quick KPI</h2>
        <p class="txt">해당 값은 수신한 시정조치를 기준으로 표기됩니다.</p>
      </div>
      <div class="card-group02">
        <div class="card-col ty03">
          <div class="icon ico3" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">13</div>
            <div class="txt2">전체 수신 시정조치</div>
          </div>
        </div>
        <div class="card-col ty03">
          <div class="icon ico4" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">100</div>
            <div class="txt2">완료</div>
          </div>
        </div>
        <div class="card-col ty03">
          <div class="icon ico5" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">100</div>
            <div class="txt2">진행중</div>
          </div>
        </div>
        <div class="card-col ty03">
          <div class="icon ico6" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">100</div>
            <div class="txt2">보류</div>
          </div>
        </div>
        <div class="card-col ty03">
          <div class="icon ico7" aria-hidden="true"></div>
          <div class="txt-group">
            <div class="txt1">5</div>
            <div class="txt2">내가 받은 지지적 피드백</div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-inner">
      <div class="tit-group02">
        <h2 class="tit">수신 시정조치</h2>
        <c-btnLine01 :btnText="'더보기'" class="sm" @click="viewPage"/>
      </div>
      <div class="content-box">
        <div class="tbl-group size-custom5">
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            return-object
          >
            <template v-slot:item="{ item }">
              <tr class="v-data-table__tr" @click="$router.push('/myBoardDetail')">
                <td>{{item.Number}}</td>
                <td>
                  <div class="ell01">
                    {{item.CorrectiveAction}}
                  </div>
                </td>
                <td>
                  <div :class="getColor(item.ActionDeadline)">
                    {{item.ActionDeadline}}
                  </div>
                </td>
                <td>{{item.Author}}</td>
                <td>{{item.Subject}}</td>

                <td>
                  <div :class="getColor2(item.Progress)">
                    {{item.Progress}}
                  </div>
                </td>
                <td>
                  <div class="dv1">
                    <div class="ico" :class="getColor2(item.Progress)">
                      {{item.Progress}}
                    </div>
                    <div class="ell01">
                      {{item.CorrectiveAction}}
                    </div>
                  </div>
                  <div class="dv2">
                    <div :class="getColor(item.ActionDeadline)">
                      {{item.ActionDeadline}}
                    </div>
                    &nbsp;/ {{item.Author}} / {{item.Subject}}
                  </div>
                </td>
                <td>{{item.RegistrationDate}}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <div class="p-inner">
      <div class="tit-group02">
        <h2 class="tit">발행 시정조치</h2>
        <c-btnLine01 :btnText="'더보기'" class="sm"/>
      </div>
      <div class="content-box">
        <div class="tbl-group size-custom5">
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            return-object
          >
            <template v-slot:item="{ item }">
              <tr class="v-data-table__tr" @click="$router.push('/myBoardDetail')">
                <td>{{item.Number}}</td>
                <td>
                  <div class="ell01">
                    {{item.CorrectiveAction}}
                  </div>
                </td>
                <td>
                  <div :class="getColor(item.ActionDeadline)">
                    {{item.ActionDeadline}}
                  </div>
                </td>
                <td>{{item.Author}}</td>
                <td>{{item.Subject}}</td>

                <td>
                  <div :class="getColor2(item.Progress)">
                    {{item.Progress}}
                  </div>
                </td>
                <td>
                  <div class="dv1">
                    <div class="ico" :class="getColor2(item.Progress)">
                      {{item.Progress}}
                    </div>
                    <div class="ell01">
                      {{item.CorrectiveAction}}
                    </div>
                  </div>
                  <div class="dv2">
                    <div :class="getColor(item.ActionDeadline)">
                      {{item.ActionDeadline}}
                    </div>
                    &nbsp;/ {{item.Author}} / {{item.Subject}}
                  </div>
                </td>
                <td>{{item.RegistrationDate}}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useOrganizationalManagementStore } from "@/store/organizationalManagement";
const store = useOrganizationalManagementStore()

function viewPage(){
  store.moveTabView('tabCont1')
}

const headers = ref([
  { title: '순번', key: 'Number' },
  { title: '시정조치', key: 'CorrectiveAction' },
  { title: '조치기한', key: 'ActionDeadline'},
  { title: '작성자 ', key: 'Author' },
  { title: '대상자', key: 'Subject' },
  { title: '진행상황', key: 'Progress' },
  { title: '시정조치 / 조치기한 / 작성자 / 대상자', key: 'moDate' },
  { title: '등록일', key: 'RegistrationDate' },
])
const items = ref([
  {
    Number: '1',
    CorrectiveAction: '시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.',
    ActionDeadline: '2024-01-25',
    Author: '김명기',
    Subject: '마케팅팀',
    Progress: '진행중',
    RegistrationDate: '2024-01-25'
  },
  {
    Number: '2',
    CorrectiveAction: '시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.시정조치 내용이 들어갑니다.',
    ActionDeadline: '2024-01-23',
    Author: '김명기',
    Subject: '마케팅팀',
    Progress: '보류',
    RegistrationDate: '2024-01-25'
  },
  {
    Number: '3',
    CorrectiveAction: '시정조치 내용',
    ActionDeadline: '2024-01-22',
    Author: '김명기',
    Subject: '마케팅팀',
    Progress: '완료',
    RegistrationDate: '2024-01-25'
  },
  {
    Number: '4',
    CorrectiveAction: '시정조치 내용',
    ActionDeadline: '2024-01-22',
    Author: '김명기',
    Subject: '마케팅팀',
    Progress: '완료',
    RegistrationDate: '2024-01-25'
  },
  {
    Number: '5',
    CorrectiveAction: '시정조치 내용',
    ActionDeadline: '2024-01-22',
    Author: '김명기',
    Subject: '마케팅팀',
    Progress: '완료',
    RegistrationDate: '2024-01-25'
  },
])
function getColor(deadline) {
  if (deadline == '2024-01-25') return 'txt-red'
}
function getColor2(progress) {
  if (progress == '진행중') return 'txt-blue'
  else if (progress == '보류') return 'txt-red'
}
</script>


<style lang="scss" >
</style>
