<template>
  <div class="pop-cont04">
    <div class="form-group col-ty">
      <div class="editor-ty">
        <!--아래 div 추후삭제 -->
        <div style="height:36.7rem; background:#ddd;display:flex;align-items:center;justify-content: center;">
          텍스트 에디터에서 입력한 내용 노출<br>이미지 , 텍스트, 동영상으로 이루어짐
        </div>
      </div>
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row ty05">
          <div class="txt-tit ty04">첨부파일</div>
          <div class="row">
            <FileUpload/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Sortable } from 'sortablejs-vue3';
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
import { useRoute } from 'vue-router'
import { useDialogSLR101Store } from "@/store/dialogSLR101";
import { storeToRefs } from 'pinia'
const route = useRoute()
const store = useDialogSLR101Store()
const storeRef = storeToRefs(store)
store.moveTab('tabCont1')
const tab = storeRef.tabName
const isTabView = storeRef.isTabView

// watch는 ref에서 직접 작동합니다
watch(tab, async (newTab, oldTab) => {
  storeRef.isTabView.value = false
})
</script>


<style lang="scss" scoped>
</style>
