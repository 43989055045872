<template>
  <div class="select-group">
    <v-select
      v-model="model"
      :items="items"
      item-title="name"
      item-value="value"
      return-object
      :disabled="disabled"
    ></v-select>
  </div>
</template>
<style lang="scss">
.select-group{
  flex-shrink:0;
  .v-form{
    display:flex;
  }
  //폰트 크기
  .v-field{
    font-size:1.6rem;
    border-radius:.4rem;
    background:var(--color-white);
  }
  //기본 배경색
  .v-field--variant-filled .v-field__overlay{
    opacity:0
  }
  //포커스 배경색
  .v-field--variant-filled.v-field--focused .v-field__overlay{
    opacity:0.1;
  }
  .v-field__input{
    min-height:5rem;
    padding-top:1.3rem;
  }
  .v-input--density-default{
    --v-input-control-height:5rem;
    --v-input-padding-top:1.3rem
  }
  .v-input--density-default .v-field--variant-solo,
  .v-input--density-default .v-field--variant-solo-inverted,
  .v-input--density-default .v-field--variant-solo-filled,
  .v-input--density-default .v-field--variant-filled{
    --v-input-control-height:5rem;
  }
  .v-input--density-default .v-field--variant-outlined,
  .v-input--density-default .v-field--single-line,
  .v-input--density-default .v-field--no-label{
    --v-field-padding-bottom: 1.3rem;
  }
  //기본 라인
  .v-field--variant-filled .v-field__outline::before{
    border:1px solid var(--input-line);
    opacity: 0.38;
    border-radius:.4rem;

  }
  //포커스 라인
  .v-field--variant-filled .v-field__outline::after{
    border:1px solid var(--input-line);
    transition:none;
    border-radius:.4rem;
  }
  //에러케이스
  .v-field--error:not(.v-field--disabled) .v-field__outline:before{
    border-color: rgb(var(--v-theme-error));
    opacity:1;
  }
  .v-text-field .v-input__details{
    display:none;
  }
  .v-input--error .v-input__details{
    display:flex;
  }
  //disabled
  .v-field--disabled{
    opacity:1;
    &.v-field{
      background:var(--input-disabled-bg);
    }
    .v-field__field{
      opacity:.5;
    }
    .v-field__append-inner{
      opacity:.5;
    }
  }
}
</style>
<script lang="ts" setup>
const props = defineProps(['items', 'disabled']);
const model = defineModel()
</script>
