<template>
  <div class="tabCont page3-inner1">
    <div class="content-inner-box">
      <div class="form-group row-ty02">
        <!--form-row-->
        <div class="form-row">
          <div class="row">
            <InputSearch03 :placeholder="'부서를 입력하세요.'"></InputSearch03>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">간단리포트</div>
          <div class="row">
            <div class="card-group02 ty01">
              <div class="card-col ty01">
                <div class="icon ico9" aria-hidden="true"></div>
                <div class="txt-group">
                  <div class="txt1">20<span>개</span></div>
                  <div class="txt2">전체 진행 안전리더십</div>
                </div>
              </div>
              <div class="card-col ty01">
                <div class="icon ico8" aria-hidden="true"></div>
                <div class="txt-group">
                  <div class="txt1">90<span>명</span></div>
                  <div class="txt2">전체 대상자</div>
                </div>
              </div>
              <div class="card-col ty01">
                <div class="icon ico10" aria-hidden="true"></div>
                <div class="txt-group">
                  <div class="txt1">50<span>%</span></div>
                  <div class="txt2">전체 과제 진행율</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit sb">
            과제별 세부 진행현황
          </div>
          <div class="row">
            <div class="tbl-group line-ty size-custom13">
              <v-data-table
                :headers="headers"
                :items="items"
                hide-default-footer
                return-object
              >
                <template v-slot:item="{ item }" >
                  <tr class="v-data-table__tr">
                    <td>
                      <div class="txt-group flex-c">{{item.title}}</div>
                    </td>
                    <td>
                      <div class="txt-group flex-c">{{item.period}}</div>
                    </td>
                    <td>
                      <div class="txt-group flex-c">{{item.number}}</div>
                    </td>
                    <td>
                      <div class="txt-group flex-c">{{item.progress}}</div>
                    </td>
                    <td>
                      <div class="txt-group flex-c">{{item.score}}</div>
                    </td>
                    <td>
                      <div class="txt-group">
                        <div class="dv1">
                          {{item.title}}
                          <span>{{item.period}}</span>
                        </div>
                        <div class="dv2">
                          <span>{{item.number}}</span>
                          <span>{{item.progress}}</span>
                          <span>{{item.score}}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="txt-group flex-c">
                        <BtnIcon class="btn-ico-result" @click="DialogSLRResultModel=true"/>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-btn-group ty01">
      </div>
    </div>
  </div>
  <DialogSLRResult v-model="DialogSLRResultModel"
            @close="DialogSLRResultModel=false"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
const DialogSLRResultModel = ref(false)
const headers = ref([
  { title: '안전리더십 세트', key: 'title' },
  { title: '기간', key: 'period'},
  { title: '대상자수 ', key: 'number' },
  { title: '평균 진행률 ', key: 'progress' },
  { title: '평균 결과 점수 ', key: 'score' },
  { title: '안전리더십 세트 / 기간 / 대상자수 / 평균 진행률 / 평균 결과 점수', key: 'moDate' },
  { title: '상세보기 ', key: 'resultView' },
])
const items = ref([
  {
    title: '임원',
    period: '2024-01-25 ~ 2024-01-25',
    number: '5명',
    progress: '30%',
    score: '55점',
  }
])
</script>


<style lang="scss" >
</style>
