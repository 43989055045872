import { defineStore } from 'pinia'
import { ref, computed, reactive } from 'vue'

// `defineStore()`의 반환 값(함수)을 할당할 변수의 이름은 원하는 대로 지정할 수 있지만,
// 스토어 이름을 사용하고 `use`와 `Store`로 묶는 것이 가장 좋습니다.
// 예: `useUserStore`, `useCartStore`, `useProductStore`
// 첫 번째 인자는 앱 전체에서 스토어의 고유 ID입니다.
// ref()는 state 속성이 됨.
// computed()는 getters가 됨.
// function()은 actions가 됨.
export const useBbsStore = defineStore('Bbs', () => {
  //팝업 오픈 시 사용되는 idx
  const popupIdx = ref('')
  //팝업 오픈 시 사용되는 타입
  const popupType = ref('')
  //상세 오픈 시 사용되는 idx
  const viewIdx = ref('')

  return { popupIdx, popupType, viewIdx}
})
