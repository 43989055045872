<template>
  <div class="input-txt-group">
    <v-text-field
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      v-model="inputValue"
    >
    </v-text-field>
  </div>
</template>
<style lang="scss">
</style>
<script lang="ts" setup>
import { ref } from 'vue'
const props = defineProps(['placeholder', 'type' ,'readonly', 'disabled', 'modelValue' ]);
const emits = defineEmits(['update:modelValue'])
const inputValue = ref('')
inputValue.value = props.modelValue
</script>
