<template>
  <div class="input-search-group">
  <v-sheet class="mx-auto" width="400">
    <v-form @submit.prevent>
      <v-text-field
        clearable
        v-model="searchData"
        :placeholder="placeholder"></v-text-field>
      <v-btn class="btn-search" type="button" @click="isShowDialog=true">
        <span class="hide-txt">검색</span>
      </v-btn>
    </v-form>
  </v-sheet>
  <DialogTargetAdd
    v-model="isShowDialog"
    @close="isShowDialog=false"/>
  </div>
</template>

<style lang="scss"> //scoped
</style>
<script lang="ts" setup>
import {ref} from "vue";

const props = defineProps(['placeholder'])
const searchData = ref('')
const isShowDialog = ref(false)
</script>
