<template>
  <div class="tabCont">
    <div class="content-inner-box">
      <div class="tit-group ty01">
        <h4 class="tit">작업자 안전행동 관찰</h4>
        <c-btnLine01 :btnText="'보고서 유효성 검사'" class="sm ty03" @click="isShowDialog3=true"/>
      </div>
      <div class="tit-group02 inner-ty">
        <h2 class="tit"></h2>
        <div class="filter-btn-group">
          <v-btn class="reset" :class="filterDiv1">필터초기화</v-btn>
          <v-btn class="filter" @click="filterOpen1" :class="filterDiv1">필터</v-btn>
        </div>
      </div>
      <div class="sort-group ty01" :class="filterDiv1">
        <div class="sort-row">
          <div class="wl">
            <div class="date-sort">
              <c-InputDate :enableTimePicker="false" readonly="" />
              <div class="txt02">~</div>
              <c-InputDate :enableTimePicker="false" readonly=""/>
            </div>
            <div class="date-sort">
              <c-btn02 :btnText="'당월'" class="ty01"/>
              <c-btn02 :btnText="'분기'" class="ty01"/>
              <c-btn02 :btnText="'올해'" class="ty01"/>
            </div>
          </div>
        </div>
        <div class="sort-row">
          <div class="wl">
            <Select v-bind="select01"/>
            <Select v-bind="select02"/>
            <Select v-bind="select03"/>
            <Select v-bind="select04"/>
            <Select v-bind="select05"/>
          </div>
        </div>
      </div>
    </div>
    <!--row-->
    <div class="tit-group02 ty02 mo-ty02">
      <h2 class="tit">간단 리포트</h2>
    </div>
    <div class="content-inner-box ty01">
      <div class="tit-group ty01">
        <h4 class="tit">Quick KPI</h4>
      </div>
      <div class="card-group02 ty02">
        <!--card-item-->
        <div class="card-col-wrap">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">13</div>
              </div>
              <div class="txt2">참여빈도1</div>
            </div>
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">13</div>
              </div>
              <div class="txt2">참여빈도2</div>
            </div>
          </div>
        </div>
        <!--card-item-->
        <div class="card-col-wrap">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">60<span>%</span></div>
              </div>
              <div class="txt2">빈도달성율</div>
            </div>
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">60<span>%</span></div>
              </div>
              <div class="txt2">참여달성율</div>
            </div>
          </div>
        </div>
        <!--card-item-->
        <div class="card-col-wrap">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">60<span>%</span></div>
              </div>
              <div class="txt2">시정조치완료</div>
            </div>
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">60<span>%</span></div>
              </div>
              <div class="txt2">시정조치전체</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-group02 ty02 c-ty01">
        <!--card-item-->
        <div class="card-col-wrap ty01">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">90<span>%</span></div>
              </div>
              <div class="txt2">안전대화 참여율</div>
            </div>
          </div>
        </div>
        <!--card-item-->
        <div class="card-col-wrap ty01">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">94<span>%</span></div>
              </div>
              <div class="txt2">교정적 피드백 실시율</div>
            </div>
          </div>
        </div>
        <!--card-item-->
        <div class="card-col-wrap ty01">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">87<span>%</span></div>
              </div>
              <div class="txt2">지지적 피드백 실시율</div>
            </div>
          </div>
        </div>
        <!--card-item-->
        <div class="card-col-wrap ty02">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <div class="card-col ty05">
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">60<span>%</span></div>
              </div>
              <div class="txt2">내가 받은 교정적 피드백</div>
            </div>
            <div class="txt-group">
              <div class="inner">
                <div class="txt1">60<span>%</span></div>
              </div>
              <div class="txt2">내가 받은 지지적 피드백</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-group02 ty03">
        <div class="card-col">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <div class="tit">
            당월 활동 우수자
            <c-tooltip :TooltipText="'당월 활동 우수자란?'"/>

          </div>
          <v-carousel
            hide-delimiters
            height="auto"
            :continuous="false"
          >
            <v-carousel-item
              v-for="n in 4"
              :key="n"
            >
              <div class="txt1">김상팔/ 부서 / 직책</div>
              <div class="txt2">
                <span>모니터링 <span>13회</span></span>
                <span>안전대화 <span>2회</span></span>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
        <div class="card-col">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <div class="tit">
            당월 활동 저조자
            <c-tooltip :TooltipText="'당월 활동 저조자란?'"/>

          </div>
          <v-carousel
            hide-delimiters
            height="auto"
            :continuous="false"
          >
            <v-carousel-item
              v-for="n in 1"
              :key="n"
            >
              <div class="txt1">김상팔/ 부서 / 직책</div>
              <div class="txt2">
                <span>모니터링 <span>13회</span></span>
                <span>안전대화 <span>2회</span></span>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
    </div>
    <!--row-->
    <div class="content-inner-box ty01">
      <div class="tit-group ty01">
        <h4 class="tit">참여율</h4>
        <div class="wr">
          <div class="check-group ty02 mo-none">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <Select v-bind="select06"/>
        </div>
      </div>
      <!--그래프 위치-->
      <div class="graph-group">
        <Line :data="data_line" :chart-options="options_line" />
      </div>
    </div>
    <!--row-->
    <div class="content-inner-box ty01">
      <div class="tit-group ty01">
        <h4 class="tit">안전행동률</h4>
        <div class="wr">
          <div class="check-group ty02 mo-none">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <Select v-bind="select06"/>
        </div>
      </div>
      <!--그래프 위치-->
      <div class="graph-group">
        <Line :data="data_line02" :chart-options="options_line" />
      </div>
      <div class="cont-row">
        <div class="tit-group ty01">
          <h4 class="tit02">안전행동률 - 카테고리별 추이</h4>
          <div class="wr">
            <div class="check-group ty02 mo-none">
              <v-checkbox label="노출여부"></v-checkbox>
            </div>
            <Select v-bind="select06"/>
          </div>
        </div>
        <!--그래프 위치-->
        <div class="graph-group">
          <div class="graph-txt">
            <div class="txt">
              <button>카테고리abcd 1</button>
            </div>
            <div class="txt">
              <button>카테고리 2</button>
            </div>
            <div class="txt">
              <button>카테고리 3</button>
            </div>
            <div class="txt">
              <button>카테고리 4</button>
            </div>
            <div class="txt">
              <button>카테고리 제목 들어감 5</button>
            </div>
          </div>
          <div class="graph-cont">
            <Bar :data="data_bar" :chart-options="options_bar" />
          </div>
        </div>
      </div>
      <div class="cont-row">
        <div class="tit-group ty01">
          <h4 class="tit02">
            안전행동률 - 관찰항목별 추이
            <span class="sub-txt">
              관찰항목을 클릭하면, 세부 관찰 항목에 대한 결과를 확인하실 수 있습니다.
            </span>
          </h4>
          <div class="wr">
            <div class="check-group ty02 mo-none">
              <v-checkbox label="노출여부"></v-checkbox>
            </div>
            <Select v-bind="select06"/>
          </div>
        </div>
        <!--그래프 위치-->
        <div class="graph-group">
          <div class="graph-txt">
            <div class="txt">
              <button>관찰항목abcd 1</button>
            </div>
            <div class="txt">
              <button>관찰항목abcd 2</button>
            </div>
            <div class="txt">
              <button>관찰항목abcd test button 1234</button>
            </div>
          </div>
          <div class="graph-cont">
            <Bar :data="data_bar2" :chart-options="options_bar" />
          </div>
        </div>
      </div>
      <div class="cont-row">
        <div class="tit-group ty01">
          <h4 class="tit02">
            안전행동률 - 세부 관찰항목별 추이
          </h4>
          <div class="wr">
            <div class="check-group ty02 mo-none">
              <v-checkbox label="노출여부"></v-checkbox>
            </div>
          </div>
        </div>
        <!--그래프 위치-->
        <div class="graph-group">
          <div class="graph-txt">
            <div class="txt">
              <button>세부항목abcd 1</button>
            </div>
            <div class="txt">
              <button>세부항목abcd 2</button>
            </div>
            <div class="txt">
              <button>세부항목abcd 3</button>
            </div>
            <div class="txt">
              <button>세부항목abcd 4</button>
            </div>
          </div>
          <div class="graph-cont">
            <Bar :data="data_bar3" :chart-options="options_bar" />
          </div>
        </div>
      </div>
    </div>
    <!--row-->
    <div class="content-inner-box ty01">
      <div class="tit-group ty01">
        <h4 class="tit">안전대화 실시율
          <c-tooltip :TooltipText="'안전대화 실시율이란?'"/>
        </h4>
        <div class="wr">
          <div class="check-group ty02 mo-none">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <Select v-bind="select06"/>
        </div>
      </div>
      <!--그래프 위치-->
      <div class="graph-group">
        <Line :data="data_line03" :chart-options="options_line" />
      </div>
      <div class="cont-row mo-none">
        <div class="tit-group ty01">
          <h4 class="tit">안전대화 활동상세</h4>
          <div class="wr">
            <div class="check-group ty02">
              <v-checkbox label="노출여부"></v-checkbox>
            </div>
            <c-btnLine01 :btnText="'다운로드'"/>
          </div>
        </div>
        <!--그래프 위치-->
        <div class="tbl-group size-custom14">
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            return-object
          >
            <template v-slot:item.action="{ item }" >
              <div class="ell01">
                {{item.action}}
              </div>
            </template>
            <template v-slot:item.detailView="{ item }">
              <div class="flex-c">
                <BtnIcon class="btn-ico-search" @click="isShowDialog=true"/>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <!--row-->
    <div class="content-inner-box ty01 mo-none">
      <div class="tit-group ty01">
        <h4 class="tit">개인별 활동상세
          <div class="sub-txt02">
            <div class="col">
              총 참여율 <span>13회</span>
            </div>
            <div class="line"></div>
            <div class="col">
              총 참여횟수 <span>80%</span>
            </div>
          </div>
        </h4>
        <div class="wr">
          <div class="check-group ty02">
            <v-checkbox label="노출여부"></v-checkbox>
          </div>
          <c-btnLine01 :btnText="'다운로드'"/>
        </div>
      </div>
      <!--그래프 위치-->
      <div class="tbl-group size-custom15">
        <v-data-table
          :headers="headers2"
          :items="items2"
          hide-default-footer
          return-object
        >
          <template v-slot:item.author="{ item }" >
            <div class="ell01">
              {{item.author}}
            </div>
          </template>
          <template v-slot:item.detailView="{ item }">
            <div class="flex-c">
              <BtnIcon class="btn-ico-search" @click="isShowDialog2=true"/>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
  <MtDialog01 v-model="isShowDialog" @close="isShowDialog=false"/>
  <MtDialog02 v-model="isShowDialog2" @close="isShowDialog2=false"/>
  <MtDialog03 v-model="isShowDialog3" @close="isShowDialog3=false"/>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Line, Bar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  ChartOptions,
  Chart
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)
const isShowDialog = ref(false)
const isShowDialog2 = ref(false)
const isShowDialog3 = ref(false)
const select01 = {
  select:  '개인',
  items: ['개인', '홍길동1']
}
const select02 = {
  select:  '부서',
  items: ['부서', '부서1']
}
const select03 = {
  select:  '직책',
  items: ['직책', '직책1']
}
const select04 = {
  select:  '교대조',
  items: ['교대조', '교대조1']
}
const select05 = {
  select:  '모니터링(전체)',
  items: ['모니터링(전체)', '모니터링1']
}
const select06 = {
  select:  '주',
  items: ['주', '월']
}

const data_line = {
  labels: ['2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01'],
  datasets: [
    {
      label: '참여율           ',
      borderColor: '#3C50E0',
      backgroundColor: '#3C50E0',
      borderWidth:2,
      pointBorderWidth:3,
      pointHoverBorderWidth:5,
      data: [10, 22, 12, 80, 40, 60, 10],
    },
    {
      label: 'audit 참여율',
      borderColor: '#A155B9',
      backgroundColor: '#A155B9',
      borderWidth:2,
      pointBorderWidth:3,
      pointHoverBorderWidth:5,
      data: [40, 60, 35, 20, 5, 20, 40]
    }
  ]
}
const data_line02 = {
  labels: ['2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01'],
  datasets: [
    {
      label: '안전행동률           ',
      borderColor: '#3C50E0',
      backgroundColor: '#3C50E0',
      borderWidth:2,
      pointBorderWidth:3,
      pointHoverBorderWidth:5,
      data: [10, 22, 12, 80, 40, 60, 10],
    },
    {
      label: 'audit 안전행동률',
      borderColor: '#A155B9',
      backgroundColor: '#A155B9',
      borderWidth:2,
      pointBorderWidth:3,
      pointHoverBorderWidth:5,
      data: [40, 60, 35, 20, 5, 20, 40]
    }
  ]
}
const data_line03 = {
  labels: ['2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01', '2024.02.01'],
  datasets: [
    {
      label: '안전대화 실시율',
      borderColor: '#3C50E0',
      backgroundColor: '#3C50E0',
      borderWidth:2,
      pointBorderWidth:3,
      pointHoverBorderWidth:5,
      data: [10, 22, 12, 80, 40, 60, 10],
    }
  ]
}
//const options_line: ChartOptions<"line"> = {
const options_line = ref({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      title: {
        display: true,
        text: ''
      }
    },
    y: {
      title: {
        display: true,
        text: ''
      },
      min: 0,
      max: 100,
      ticks: {
        // forces step size to be 50 units
        stepSize: 20
      }
    }
  },
  plugins: {
    legend: {
      usePointStyle: true,
      position: "bottom"
    },
  }
})

const data_bar = {
  labels: [
    '카테고리abcd 1',
    '카테고리 2',
    '카테고리 3',
    '카테고리 4',
    '카테고리 제목 들어감 5',
  ],
  datasets: [
    {
      label: 'Data One',
      backgroundColor: '#3C50E0',
      data: [40, 20, 60, 80, 10]
    }
  ]
}
const data_bar2 = {
  labels: [
    '관찰항목abcd 1',
    '관찰항목abcd 2',
    '관찰항목abcd test button 1234',
  ],
  datasets: [
    {
      label: 'Data One',
      backgroundColor: '#3C50E0',
      data: [40, 20, 60]
    }
  ]
}
const data_bar3 = {
  labels: [
    '세부항목abcd 1',
    '세부항목abcd 2',
    '세부항목abcd 3',
    '세부항목abcd 4',
  ],
  datasets: [
    {
      label: 'Data One',
      backgroundColor: '#3C50E0',
      data: [40, 20, 60, 80]
    }
  ]
}
const options_bar = {
  indexAxis: 'y',
  barThickness:20,
  responsive: true,
  maintainAspectRatio: false,//뷰포트가 달라지면 원래의 비율을 유지한 채 늘어난다.반응형인 경우 아래 옵션을 추가하면 비율이 아닌 부모의 크기에 맞춰 늘어나고 줄어든다.
  scales: {
    x: {
      min: 0,
      max: 100,
      ticks: {
        // forces step size to be 50 units
        stepSize: 20
      }
    },
    y:{
      display:false
    }
  },
  plugins: {
    legend: {
      display:false,
      usePointStyle: true,
      position: "bottom"
    },
  }
}

const headers = ref([

  { title: '행동', key: 'action' },
  { title: '안전대화', key: 'safetyTalk' },
  { title: '칭찬대화', key: 'cheerTalk' },
  { title: '상세내용', key: 'detailView' },
])
const items = ref([
  {
    action: '항목1',
    safetyTalk: '2',
    cheerTalk: '4'
  },
  {
    action: '항목1',
    safetyTalk: '2',
    cheerTalk: '4'
  },
  {
    action: '항목1',
    safetyTalk: '2',
    cheerTalk: '4'
  },
])
const headers2 = ref([

  { title: '작성자', key: 'author' },
  { title: '모니터링', key: 'monitoring' },
  { title: '안전대화', key: 'safetyTalk' },
  { title: '최근제출일', key: 'submit' },
  { title: '상세내용', key: 'detailView' },
])
const items2 = ref([
  {
    author: '김상팔 / 부서 / 직책',
    monitoring: '2',
    safetyTalk: '4',
    submit: '2024-01-24 13:38:40'
  },
  {
    author: '김상팔 / 부서 / 직책',
    monitoring: '2',
    safetyTalk: '4',
    submit: '2024-01-24 13:38:40'
  },
  {
    author: '김상팔 / 부서 / 직책',
    monitoring: '2',
    safetyTalk: '4',
    submit: '2024-01-24 13:38:40'
  },
])
//필터 메뉴 열고 닫기
const filterDiv1 = ref('off')
function filterOpen1(){
  if(filterDiv1.value == 'off')
    filterDiv1.value = 'on'
  else
    filterDiv1.value = 'off'
}

</script>


<style lang="scss" >
</style>
