<template>
  <!--Form @submit="handleForm" :validation-schema="schema"-->
  <div class="content-wrap">
    <NaviBox/>
    <div class="tit-group02">
      <h2 class="tit">행동 관리 상세설정</h2>
    </div>
    <div class="content-box">
      <div class="form-group col-ty">
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">코드<span class="imp">*</span></div>
          <div class="row">
            <div class="txt">{{behaviorCode}}</div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">행동명<span class="imp">*</span></div>
          <div class="row">
            <c-Input :placeholder="'행동명을 입력하세요'" v-model="behaviorTitle" :name="behaviorTitle" class="size04"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">구분<span class="imp">*</span></div>
          <div class="row">
            <Select v-bind="sortTypeSelect" v-model="sortType" :name="sortType" />
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">카테고리<span class="imp">*</span></div>
          <div class="row">
            <Select v-bind="categoryIdxSelect" v-model="categoryIdx" :name="categoryIdx"/>
            <div class="col-dv">
              <c-btn01 :btnText="'추가/변경'" :btnType="'button'" @click="DialogCategoryStatus=true"/>
            </div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">관찰항목<span class="imp">*</span></div>
          <div class="row">
            <c-Input :placeholder="'관찰항목을 입력하세요'" class="size04" v-model="behaviorContent" :name="behaviorContent"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit ty01">
            세부관찰항목
            <c-tooltip :TooltipText="'사용자가 불안전 선택 시에만 노출되는 추가 선택 항목입니다.'"/>
          </div>
          <div class="row">
            <Select v-bind="commentCntSelect" v-model="commentCnt" />
            <div class="col-dv">
              <div class="check-group">
                <v-checkbox v-model="multiYn" label="다중 선택 가능" true-value="Y" false-value="N"></v-checkbox>
              </div>
            </div>
            <div class="row-dv" v-for="(item, i) in behaviorComments" :key="i">
              <c-Input :placeholder="'코멘트를 입력하세요'" class="size04" v-model="item.commentTitle" :name="'behaviorComment'"/>
            </div>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit ty01">
            태그
            <c-tooltip :TooltipText="'태그를 등록 해두면, 관리 페이지에서 추후 태그로도 검색이 가능합니다.'"/>
          </div>
          <div class="row">
            <c-Input :placeholder="'#태그1, #태그2'" class="size04" v-model="tag" :name="tag"/>
          </div>
        </div>
        <!--form-row-->
        <div class="form-row">
          <div class="txt-tit">
            필수여부
          </div>
          <div class="row">
            <v-switch v-model="essentialYn" :name="essentialYn" inset hide-details true-value="Y" false-value="N"/>
          </div>
        </div>

      </div>
    </div>
    <div class="bottom-btn-group">
      <div class="wl">
        <c-btnLine01 :btnText="'목록'" @click.stop="onClickGoList()"/>
      </div>
      <div class="wr">
        <c-btn02 :btnText="'삭제'" @click="dialogPageDelModel=true"/>
        <c-btn01 :btnText="'등록/수정'" :btnType="'submit'" @click="handleForm"/>
      </div>
    </div>
  </div>
  <!--/Form-->
  <DialogCategory
    v-model="DialogCategoryStatus"
    :popTitle="'카테고리'"
    @close="closePopupCB"/>
  <!--삭제-->
  <c-dialog v-model="dialogPageDelModel"
            v-bind="dialogPageDelBind"
            @close="dialogPageDelModel=false"/>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useBbsStore } from "@/store/bbs";
import {Form} from "vee-validate";
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";
import * as yup from "yup";
import _ from 'lodash';

//store
const store = useBbsStore()
const storeRef = storeToRefs(store)

//validation schema
const schema = yup.object().shape({
  behaviorTitle: yup.string().required("필수 항목입니다."),
});

//bind
const viewIdx = storeRef.viewIdx.value
const DialogCategoryStatus = ref(false)
const dialogPageDelModel = ref(false)
const behaviorTitle = ref('')
const sortType = ref({name: '행동', value: '1'})
const categoryIdx = ref({name: '선택', value: '0'})
const behaviorContent = ref('')
const commentCnt = ref({name: '1', value: '1'})
const behaviorComments = ref([{commentTitle:'', idx:'', seq:1}])
const multiYn = ref('Y')
const tag = ref('')
const essentialYn = ref('')
const behaviorCode = ref('')

//ui
const sortTypeSelect = ref({items: [{name: '행동', value: '1'}, {name: '상태', value: '2'}]})
const categoryIdxSelect = ref({items: [{name: '선택', value: '0'}]})
const commentCntSelect = ref({items: [{name: '1', value: '1'}, {name: '2', value: '2'}, {name: '3', value: '3'}, {name: '4', value: '4'}, {name: '5', value: '5'}]})

const dialogPageDelBind = {
  dialogTit: '삭제',
  dialogText: '삭제 시 복구할 수 없게 됩니다. 진행하시겠습니까?',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}

//hook
onMounted(() => {
  if(viewIdx) {
    let params = {
      idx: viewIdx
    }

    BbsService.getBehavior(params).then(
      (response) => {
        behaviorCode.value = response.result.idx
        behaviorTitle.value = response.result.behaviorTitle
        if(response.result.sortType === '1')
          sortType.value = {name: '행동', value: '1'}
        else if(response.result.sortType === '1')
          sortType.value = {name: '상태', value: '2'}
        categoryIdx.value = response.result.categoryIdx
        behaviorContent.value = response.result.behaviorContent
        tag.value = response.result.tag
        essentialYn.value = response.result.essentialYn

        commentArr = new Array();
        _.forEach(response.result.behaviorCommentArr, function(val, key) {
          let tempObj = {commentTitle:val['commentTitle'], idx:val['idx'], seq:key+1}
          commentArr.push(tempObj)
        })
        //watch에서 처리
        commentCnt.value = {name: commentArr.length+'', value: commentArr.length+''}
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }

  getCategoryList()
})

//watch
let commentArr = []  //변경 코멘트
watch(commentCnt, (newValue) => {
  let resultArr = []
  for(let i = 1; i <= Number(newValue['value']); i++){
    let tempObj = {name:'', value:'', seq:i}
    if(commentArr.length >= i)
      tempObj = commentArr[i-1]
    resultArr.push(tempObj)
  }
  behaviorComments.value = resultArr
})

//function
function handleForm(){
  if(import.meta.env.MODE == 'development') {
    onClickGoList()
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    //코멘트리스트
    let commentArr = []
    behaviorComments.value.forEach(function (item){
      commentArr.push(item.commentTitle)
    })

    let params = {
      idx:viewIdx,
      companyIdx: companyIdx,
      behaviorTitle: behaviorTitle.value,
      sortType: sortType.value['value'],
      categoryIdx: categoryIdx.value['value'],
      behaviorContent: behaviorContent.value,
      commentCnt: commentCnt.value['value'],
      behaviorComments:commentArr,
      tag: tag.value,
      essentialYn: essentialYn.value,
      behaviorType: 'monitoring'
    }

    BbsService.insertBehavior(params).then(
      (response) => {
        if(response.success)
          utils.movePage('actionManagementList')
        else
          alert(response.message)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}
function onClickGoList(){
  utils.movePage('actionManagementList')
}

function closePopupCB(type){
  DialogCategoryStatus.value=false
  if(type === 'refresh')
    getCategoryList()
}

function getCategoryList(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    companyIdx: companyIdx,
  }

  BbsService.getBehaviorCategoryList(params).then(
    (response) => {
      let resultArr = {items:[]}
      _.forEach(response.result, function(val, key) {
        let tempObj = {name:val['categoryTitle'], value:val['idx']}
        resultArr.items.push(tempObj)
      })
      categoryIdxSelect.value = resultArr
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

</script>


<style lang="scss">
</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
