<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="60rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">상세보기</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <v-divider class="mb-4"></v-divider>
        <!--content-->
        <v-card-text>
          <div class="cont-group">
            <div class="top">
              <div class="txt-update">update : 2024.02.12</div>
              <div class="txt-group">
                <div class="txt">김상두 / 염소정제팀 / 관리감독자</div>
                <div class="txt2">2024.02.12</div>
              </div>
              <div class="btn-group">
                <c-btnLine01 :btnText="'진행중'" class="sm"/>
                <c-btn02 :btnText="'보류'" class="sm"/>
                <c-btn01 :btnText="'완료'" class="sm"/>
              </div>
            </div>
            <ul class="list-group">
              <li>
                <div class="tit">행동</div>
                <div class="txt ell01">동행 점검 시 동일한 포인트 확인</div>
              </li>
              <li>
                <div class="tit">장소</div>
                <div class="txt ell01">공장</div>
              </li>
              <li>
                <div class="tit">구체적인상황</div>
                <div class="txt ell01">구체적인 상황</div>
              </li>
              <li>
                <div class="tit">원인</div>
                <div class="txt ell01">원인</div>
              </li>
              <li>
                <div class="tit">시정조치내용</div>
                <div class="txt ell01">시멘트 안전지역 위치필요 시멘트 안전지역 위치필요</div>
              </li>
              <li>
                <div class="tit">시정조치대상자</div>
                <div class="txt ell01">홍길동</div>
              </li>
              <li>
                <div class="tit">조치기한</div>
                <div class="txt ell01">2024.05.01</div>
              </li>
              <li>
                <div class="tit">진행상태</div>
                <div class="txt2 c01">완료</div>
              </li>
            </ul>
            <div class="img-group">
              <div class="item">
                <v-btn @click="isShowDialog2=true">
                  <img src="https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg">
                </v-btn>
              </div>
              <div class="item">
                <v-btn @click="isShowDialog2=true">
                  <img src="https://cdn.vuetifyjs.com/images/carousel/sky.jpg">
                </v-btn>
              </div>
              <div class="item">
                <v-btn @click="isShowDialog2=true">
                  <img src="https://cdn.vuetifyjs.com/images/carousel/skyd.jpg">
                </v-btn>
              </div>
              <div class="item">
                <v-btn @click="isShowDialog2=true">
                <img src="https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg">
                </v-btn>
              </div>
              <div class="item">
                <v-btn @click="isShowDialog2=true">
                  <img src="https://cdn.vuetifyjs.com/images/carousel/sky.jpg">
                </v-btn>
              </div>
              <div class="item">
                <v-btn @click="isShowDialog2=true">
                  <img src="https://cdn.vuetifyjs.com/images/carousel/skyd.jpg">
                </v-btn>
              </div>
            </div>
          </div>
          <InputComment/>
          <div class="comment-group">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-title>댓글(3개)</v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div class="box"
                       v-for="n in 7"
                       :key="n">
                    <v-btn
                      icon="mdi-close"
                      variant="text"
                      class="btn-del"
                    ></v-btn>
                    <div class="txt-group">
                      <div class="txt">김상두 / 염소정제팀 / 관리감독자</div>
                      <div class="txt2">2024.02.12</div>
                    </div>
                    <div class="txt-group2">
                      <div class="txt">시멘트 안전지역 위치필요</div>
                      <div class="img-group">
                        <div class="item">
                          <v-btn @click="isShowDialog2=true">
                            <img src="https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg">
                          </v-btn>
                        </div>
                        <div class="item">
                          <v-btn @click="isShowDialog2=true">
                            <img src="https://cdn.vuetifyjs.com/images/carousel/sky.jpg">
                          </v-btn>
                        </div>
                        <div class="item">
                          <v-btn @click="isShowDialog2=true">
                            <img src="https://cdn.vuetifyjs.com/images/carousel/skyd.jpg">
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 :btnText="'저장'"/>
        </v-card-actions>
      </v-card>
      <DialogImgView v-model="isShowDialog2" @close="isShowDialog2=false"/>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
const isShowDialog2 = ref(false)
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
const managerTooltip = {
  TooltipText: '관리자는 해당그룹에 신규 유저로 생성됩니다.'
}
/*
const props = defineProps(['isShowDialog'])
console.log(props.isShowDialog)
const isShow = ref(props.isShowDialog)

 */
</script>


<style lang="scss">
</style>
