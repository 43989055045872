<template v-slot:default="{ isActive }">
  <v-dialog
    v-model="dialog"
    width="60rem"
    class="layer-popup"
    scrollable
  >
    <v-card>
      <!--content-->
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="pop-title">{{ popTitle }}</div>
        <v-btn
          icon="mdi-close"
          variant="text"
          @click.stop="dialog = false"
        ></v-btn>
      </v-card-title>
      <!--content-->
      <v-card-text>
        <div class="dropdown-group ty01">
          <div class="form-group col-ty">
            <div class="form-row02"
                 v-for="(item, i) in items"
                 :key="i">
              <c-Input :placeholder="'교대조'" v-model="item.shiftName" :name="'shiftName'" class="size03"/>
              <c-btn02 :btnText="'삭제'" @click.stop="deleteRow(i)"/>
              <c-btn01 :btnText="'추가'" :btnType="'button'" class="" v-if="i==0" @click.stop="addRow()"/>
            </div>
          </div>
        </div>
      </v-card-text>
      <!--버튼-->
      <v-card-actions class="d-flex justify-end pop-bottom-bg"><!--pop-bottom-bg-->
        <c-btn01 v-bind="btnSave" @click="handleForm"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import {ref, computed} from 'vue'
import CompanyService from "@/services/company.service";
import utils from "@/common/Utils";

//popup close event
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value', 'popTitle'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})

//bind
const items = ref([])

//ui
const btnSave = {
  btnText: '확인',
  btnType: 'submit'
}

//hook
onBeforeUpdate (()=>{
  items.value = []
})

onUpdated(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        shiftName: '1',
        idx: '1',
      },
      {
        shiftName: '2',
        idx: '2',
      }
    ]
  }
  else{
    getData()
  }
})

//function
function getData(){
  let companyIdx = localStorage.getItem('companyIdx')
  let params = {
    companyIdx: companyIdx,
  }

  CompanyService.getCompanyShiftList(params).then(
    (response) => {
      items.value = response.result
      if(response.result.length == 0)
        addRow()
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function addRow(){
  let row = {
    shiftName: '',
    idx: '',
  }
  items.value.push(row)
}

function deleteRow(index){
  items.value.splice(index, 1)
}

function handleForm(){
  if(import.meta.env.MODE == 'development') {
    emit('close', '')
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    let shiftArr = []
    items.value.forEach(function (item){
      shiftArr.push(item.shiftName)
    })
    let params = {
      companyIdx: companyIdx,
      shiftList: shiftArr
    }

    CompanyService.insertCompanyShift(params).then(
      (response) => {
        emit('close', 'refresh')
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

</script>


<style lang="scss">

</style>
