/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
//import { createRouter, createWebHistory } from 'vue-router/auto'
//import { setupLayouts } from 'virtual:generated-layouts'
import { createMemoryHistory, createWebHistory, createRouter } from 'vue-router'
import Utils from "@/common/Utils";
import layoutLogin from "@/layouts/layoutLogin.vue";
import layoutGnb from "@/layouts/layoutGnb.vue";
import layoutGnbLnb from "@/layouts/layoutGnbLnb.vue";
import login from "@/pages/login.vue";
import customerManagement from "@/pages/customerManagement.vue";
import groupManagement from "@/pages/groupManagement.vue";
import organizationalManagement from "@/pages/organizationalManagement.vue";
import languageSetting from "@/pages/languageSetting.vue";
import actionManagement from "@/pages/actionManagement.vue";
import alarm from "@/pages/alarm.vue";
import safetyCalender2 from "@/pages/safetyCalender2.vue";
import safetyCalender from "@/pages/safetyCalender.vue";
import myBoardDetail from "@/pages/myBoardDetail.vue";
import myBoard from "@/pages/myBoard.vue";
import safetyLeadershipRun from "@/pages/safetyLeadershipRun.vue";
import safetyLeadershipManagementDetail from "@/pages/safetyLeadershipManagementDetail.vue";
import safetyLeadershipManagement from "@/pages/safetyLeadershipManagement.vue";
import safetyLeadershipAMListDetail from "@/pages/safetyLeadershipAMListDetail.vue";
import safetyLeadershipAMList from "@/pages/safetyLeadershipAMList.vue";
import safetyLeadership from "@/pages/safetyLeadership.vue";
import monitoringManagementListDetail from "@/pages/monitoringManagementListDetail.vue";
import monitoringManagementList from "@/pages/monitoringManagementList.vue";
import monitoring from "@/pages/monitoring.vue";
import actionManagementListDetail from "@/pages/actionManagementListDetail.vue";
import actionManagementList from "@/pages/actionManagementList.vue";
import actionManagementAll from "@/pages/actionManagementAll.vue";
import totalReport from "@/pages/totalReport.vue";
import utils from "@/common/Utils";

/*
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  extendRoutes: setupLayouts,
})
*/
const routes = [
  { path: '/', component: layoutLogin,
    children:[
      {path:'', component:login}
    ]
  },
  { path: '/login', component: layoutLogin,
    children:[
      {path:'', component:login}
    ]
  },
  { path: '/clientManagement', component: layoutGnb,
    children:[
      {path:'', component:customerManagement}
    ]
  },
  { path: '/groupManagement', component: layoutGnb,
    children:[
      {path:'', component:groupManagement}
    ]
  },
  { path: '/organizationalManagement', component: layoutGnbLnb,
    children:[
      {path:'', component:organizationalManagement}
    ]
  },
  { path: '/languageSetting', component: layoutGnbLnb,
    children:[
      {path:'', component:languageSetting}
    ]
  },
  { path: '/actionManagement', component: layoutGnbLnb,
    children:[
      {path:'', component:actionManagement}
    ]
  },
  { path: '/actionManagementAll', component: layoutGnbLnb,
    children:[
      {path:'', component:actionManagementAll}
    ]
  },
  { path: '/actionManagementList', component: layoutGnbLnb,
    children:[
      {path:'', component:actionManagementList}
    ]
  },
  { path: '/actionManagementListDetail', component: layoutGnbLnb,
    children:[
      {path:'', component:actionManagementListDetail}
    ]
  },
  { path: '/monitoring', component: layoutGnbLnb,
    children:[
      {path:'', component:monitoring}
    ]
  },
  { path: '/monitoringManagementList', component: layoutGnbLnb,
    children:[
      {path:'', component:monitoringManagementList}
    ]
  },
  { path: '/monitoringManagementListDetail', component: layoutGnbLnb,
    children:[
      {path:'', component:monitoringManagementListDetail}
    ]
  },
  { path: '/safetyLeadership', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadership}
    ]
  },
  { path: '/safetyLeadershipAMList', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadershipAMList}
    ]
  },
  { path: '/safetyLeadershipAMListDetail', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadershipAMListDetail}
    ]
  },
  { path: '/safetyLeadershipManagement', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadershipManagement}
    ]
  },
  { path: '/safetyLeadershipManagementDetail', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadershipManagementDetail}
    ]
  },
  { path: '/safetyLeadershipRun', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyLeadershipRun}
    ]
  },
  { path: '/myBoard', component: layoutGnbLnb,
    children:[
      {path:'', component:myBoard}
    ]
  },
  { path: '/myBoardDetail', component: layoutGnbLnb,
    children:[
      {path:'', component:myBoardDetail}
    ]
  },
  { path: '/safetyCalender', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyCalender}
    ]
  },
  { path: '/safetyCalender2', component: layoutGnbLnb,
    children:[
      {path:'', component:safetyCalender2}
    ]
  },
  { path: '/totalReport', component: layoutGnbLnb,
    children:[
      {path:'', component:totalReport}
    ]
  },
  { path: '/alarm', component: layoutGnbLnb,
    children:[
      {path:'', component:()=> import('@/pages/alarm.vue')}
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  Utils.log('router.beforeEach:'+to.path)
  if(!(import.meta.env.MODE == 'development')) {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const user = JSON.parse(localStorage.getItem('user')!);

    if (authRequired && !user) {
      next('/login');
    } else {
      if(to.path == '/' || to.path == 'login'){
        if(user.roles === 'ADMIN1')
          next('clientManagement')
        else if(user.roles === 'ADMIN2')
          next('groupManagement')
        else if(user.roles === 'ADMIN3')
          next('safetyCalender')
      }
      else
        next();
    }
  }
  else {
    next();
  }
});

export default router

