<template>
  <div class="content-wrap">
    <NaviBox/>
    <div class="tit-group02">
      <h2 class="tit">행동 관리 리스트</h2>
    </div>
    <div class="content-box">
      <div class="sort-group">
        <div class="sort-row">
          <div class="wl">
            <div class="txt-total">Total: {{ totalCnt }}</div>
            <c-InputSearch :placeholder="'검색어를 입력하세요.'" v-model="inputSearch" @search-word="searchWord"/>
          </div>
          <div class="wr">
            <Select v-bind="sortSelect" v-model="sortType"/>
            <c-btn01 :btnText="'신규등록'" @click="onClickViewPage"/>
          </div>
        </div>
      </div>
      <!-- v-data-table 가져옴-->
      <div class="tbl-group size-custom7">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          :items-per-page-options="footerOptions"
          return-object
          show-select
          @click:row="onClickViewPage"
        >
          <template v-slot:item.btnCopy="{ item }">
            <div class="flex-c">
              <c-btnText01 v-bind="btnCopy" @click.prevent.stop="onClickCopy(item.idx)"/>
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="bottom-btn-group">
        <div class="wl"></div>
        <div class="wr">
          <c-btn02 :btnText="'일괄 삭제'" @click="dialogAllDeleteModel=true"/>
        </div>
      </div>
    </div>
  </div>
  <c-dialog v-model="dialogCopyModel"
            v-bind="dialogCopyBind"
            @close="dialogCopyModel=false"
            @confirm="copyItem()"
  />
  <c-dialog v-model="dialogAllDeleteModel"
            v-bind="dialogAllDeleteBind"
            @close="dialogAllDeleteModel=false"
            @confirm="deleteItems()"
  />
  <v-snackbar v-model="snackbar" :timeout="1000"> {{ snackText }} </v-snackbar>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useBbsStore } from "@/store/bbs";
import BbsService from "@/services/bbs.service";
import utils from "@/common/Utils";

//store
const store = useBbsStore()
const storeRef = storeToRefs(store)

//bind
const snackbar = ref(false)
const snackText = ref('')
const totalCnt = ref(0)
const inputSearch = ref(null)
const dialogCopyModel = ref(false)
const dialogAllDeleteModel = ref(false)
const selected = ref([])
const sortType = ref({name: '전체', value: '0'})
const items = ref([])

//ui
const headers = ref([
  { title: '행동', key: 'behaviorTitle' },
  { title: '구분', key: 'sortType' },
  { title: '카테고리', key: 'categoryTitle'},
  { title: '관찰항목 ', key: 'behaviorContent' },
  { title: '상세 관찰 항목', key: 'commentCnt' },
  { title: '필수여부 ', key: 'essentialYn' },
  { title: '생성일 ', key: 'regDateFormat' },
  { title: '복사', key: 'btnCopy' },
])
const footerOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]
const dialogCopyBind = {
  dialogTit: '행동 복사',
  dialogText: '행동을 복사합니다.',
  btn1Text: '확인',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const dialogAllDeleteBind = {
  dialogTit: '행동 삭제',
  dialogText: '선택된 행동을 삭제합니다.<br>삭제된 데이터는 복구가 불가능합니다.',
  btn1Text: '삭제',
  btn1Show: true,
  btn2Text: '취소',
  btn2Show: true,
}
const sortSelect = {
  select:  '전체',
  items: [{name: '전체', value: '0'}, {name: '행동', value: '1'}, {name: '상태', value: '2'}]
}
const btnCopy = {
  btnText: '복사'
}

//hook
let copyIdx = null
onMounted(() => {
  if(import.meta.env.MODE == 'development'){
    items.value = [
      {
        behaviorTitle: '행동 B',
        sortType: '상태',
        categoryIdx: '카테고리2',
        behaviorContent: '관찰항목2',
        commentCnt: 3,
        essentialYn: 'N',
        regDateFormat: '2024.10.10',
        btnCopy:true,
      },
      {
        behaviorTitle: '행동 B',
        sortType: '상태',
        categoryIdx: '카테고리2',
        behaviorContent: '관찰항목2',
        commentCnt: 3,
        essentialYn: 'N',
        regDateFormat: '2024.10.10',
        btnCopy:true,
      }
    ]
  }
  else{
    searchWord()
  }
})

//watch
watch(sortType, (newValue) => {
  searchWord()
})

//function
function searchWord(){
  let companyIdx = localStorage.getItem('companyIdx')
  const sort = sortType.value['value']
  const word = inputSearch.value
  let params = {
    keyword: word,
    companyIdx:companyIdx,
    sortType:sort
  }

  BbsService.getBehaviorList(params).then(
    (response) => {
      items.value = response.result
    },
    (error) => {
      alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
      if(error.status == 401 || error.status == 403)
        utils.movePage('/login')
    }
  )
}

function deleteItems(){
  if(import.meta.env.MODE == 'development') {
    selected.value.forEach(function (item){
      const idx = items.value.findIndex(function(tempItem) {return tempItem.idx === item.idx})
      if (idx > -1) items.value.splice(idx, 1)
    })

    snackText.value = 'success'
    snackbar.value = true
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    if(!companyIdx)
      utils.movePage('/login')

    let deleteArr = []
    selected.value.forEach(function (item){
      deleteArr.push(item.idx)
    })
    let params = {
      companyIdx:companyIdx,
      deleteArr: deleteArr,
    }

    BbsService.deleteBehavior(params).then(
      (response) => {
        if(response.success) {
          selected.value.forEach(function (item){
            const idx = items.value.findIndex(function(tempItem) {return tempItem.idx === item.idx})
            if (idx > -1) items.value.splice(idx, 1)
          })

          snackText.value = 'success'
          snackbar.value = true
        }
        else
          alert(response.message)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function onClickCopy(idx){
  dialogCopyModel.value=true
  copyIdx = idx
}

function copyItem(){
  if(import.meta.env.MODE == 'development') {
    snackText.value = 'success'
    snackbar.value = true
  }
  else{
    let companyIdx = localStorage.getItem('companyIdx')
    if(!companyIdx)
      utils.movePage('/login')

    let copyArr = []
    copyArr.push(copyIdx)
    let params = {
      companyIdx:companyIdx,
      copyArr: copyArr,
    }

    BbsService.copyBehavior(params).then(
      (response) => {
        if(response.success) {
          snackText.value = 'success'
          snackbar.value = true
          searchWord()
        }
        else
          alert(response.message)
      },
      (error) => {
        alert((error.response && error.response.data && error.response.data.message) || error.message || error.toString())
        if (error.status == 401 || error.status == 403)
          utils.movePage('/login')
      }
    )
  }
}

function onClickViewPage(event, row){
  if(row)
    storeRef.viewIdx.value = row.item.idx
  else
    storeRef.viewIdx.value = null
  utils.movePage('actionManagementListDetail')
}
</script>


<style lang="scss" scoped>
</style>

<route lang="yaml">
meta:
  layout: layoutGnbLnb
</route>
