<template v-slot:default="{ isActive }">
    <v-dialog
      v-model="dialog"
      width="90rem"
      class="layer-popup"
      scrollable
    >
      <v-card>
        <!--content-->
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="pop-title">참여 대상</div>
          <v-btn
            icon="mdi-close"
            variant="text"
            @click.stop="dialog = false"
          ></v-btn>
        </v-card-title>

        <!--content-->
        <v-card-text class="target-pop-group">
          <div class="dv-ty01">
            <div class="inner scrollbar">
              <div class="form-group col-ty">
                <div class="text-title">대상추가</div>
                <!--form-row-->
                <div class="form-row">
                  <div class="row">
                    <div class="col">
                      <c-Input v-bind="InputName" class="size02"/>
                    </div>
                    <div class="col">
                      <Select v-bind="selectPosition"/>
                    </div>
                    <div class="col">
                      <Select v-bind="selectPart"/>
                    </div>
                    <div class="col">
                      <Select v-bind="selectChange"/>
                    </div>
                    <div class="col">
                      <Select v-bind="selectTarget"/>
                    </div>
                    <div class="col">
                      <Select v-bind="selectLeader"/>
                    </div>
                  </div>
                </div>
                <div class="text-total">1명 선택</div>
              </div>
              <div class="selected-group2">
                <v-item-group selected-class="bg-active" multiple>
                  <v-container>
                    <div class="list-group">
                      <div class="list-row"
                           v-for="n in 15"
                           :key="n"
                      >
                        <div class="list-txt">
                          <v-item v-slot="{ selectedClass, toggle }">
                            <v-card
                              :class="['active', selectedClass]"
                              dark
                              @click="toggle"
                            >
                              <div class="txt">
                                현장소장 / 염소정제팀 / 관리감독자
                              </div>
                            </v-card>
                          </v-item>
                        </div>
                      </div>
                    </div>
                  </v-container>
                </v-item-group>
              </div>
            </div>
            <div class="bottom-group">
              <c-btnLine01 :btnText="'선택추가'" class="sm"/>
              <c-btnLine01 :btnText="'전체추가'" class="sm"/>
            </div>
          </div>
          <div class="dv-ty02">
            <div class="inner scrollbar">
              <div class="form-group col-ty">
                <div class="text-title">대상</div>
                <div class="text-total">1명 선택</div>
              </div>
              <div class="selected-group2">
                <v-item-group selected-class="bg-active" multiple>
                  <v-container>
                    <div class="list-group">
                      <div class="list-row"
                           v-for="n in 30"
                           :key="n"
                      >
                        <div class="list-txt">
                          <v-item v-slot="{ selectedClass, toggle }">
                            <v-card
                              :class="['active', selectedClass]"
                              dark
                              @click="toggle"
                            >
                              <div class="txt">
                                현장소장 / 염소정제팀 / 관리감독자
                              </div>
                              <div class="txt2">
                                모니터링B 외 2
                              </div>
                            </v-card>
                          </v-item>
                        </div>
                      </div>
                    </div>
                  </v-container>
                </v-item-group>
              </div>
              <div class="bottom-group">
                <c-btn02 :btnText="'선택제거'" class="sm"/>
                <c-btn02 :btnText="'전체제거'" class="sm"/>
              </div>
            </div>
          </div>
        </v-card-text>
        <!--버튼-->
        <v-card-actions class=" d-flex justify-end pop-bottom-bg">
          <c-btn01 v-bind="btnSave"/>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
//parent에서 생성한 v-model로 value값이 자동생성됨. 생성된 value를 props로 반환
const props = defineProps(['value'])
//parent에서 명시한 close 사용명시
const emit = defineEmits(['close'])
//계산된 변수 dialog생성 get은 props의 value, set은 emit함수 실행
const dialog = computed({
  get() {
    return props.value
  },
  set(value) {
    emit('close', value)
  }
})
const btnSave = {
  btnText: '저장',
}
const InputName = {
  type: 'text',
  placeholder: '이름',
}
const selectPosition = {
  select:  '직책',
  items: ['직책', '생산팀 A', '생산팀 B']
}
const selectPart = {
  select:  '부서',
  items: ['직책', '부장', '대리']
}
const selectChange = {
  select:  '교대조',
  items: ['교대조', '교대조A', '교대조B']
}
const selectTarget = {
  select:  '대상여부',
  items: ['대상여부', 'Y', 'N']
}
const selectLeader = {
  select:  '조직장여부',
  items: ['조직장여부', 'Y', 'N']
}
</script>


<style lang="scss">

</style>
